import React, { useEffect, useState } from "react";
import styled from "styled-components"
import { URL_Back, URL_Dev } from "../Variables";
import { Helmet } from "react-helmet-async";
import Pages from "../../../Model/Pages";
import { WhatIs, Title } from "../../../css/commun";
import { Link } from 'react-router-dom';
import { AutreServices } from "./Seo/SubSeo";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";

const Cm = (props) => {
  const title = props.location.pathname
  const splitState = title.split('/')
  const { t } = props
  const [pages, setPages] = useState([]);
  const [active, setActive] = useState(1)
  const [presence, setPresence] = useState(300);
  const [visibilite, setVisibilite] = useState(580);
  const [performance, setPerformance] = useState(1190)
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'

  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setPages(result)
        },
      )
  }, [])

  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.description, element.date, element.excerpt.rendered)))
  let titre = page[0] !== undefined ? page[0].title.replace("&#8217;", "'") : "...Loading"

  return (<>
    <Helmet>
      <title>{titre}</title>
      <meta name="description" content={page[0] !== undefined && page[0].description} />
      <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
    </Helmet>
    <div className="container-fluid" >
      <Title>
        <h1>{titre}</h1>
        <p>
          <img className={langue === "ar" && "img-accueil-ar"} src="/images/accueil.png" alt="accueil" />
          <Link className="retour" to="/">{t('Home_link')}</Link> / <span>{titre}</span>
        </p>
      </Title>
      <WhatIs className={langue === "ar" && "alignRightMobile"}>
        <h2  className={langue === "ar" && "alignRightMobile"}>
          {t('Professional SEO')}
        </h2>
        <p className="text">
          {t('Cm.Professional_SEO_details')}
        </p>
        <a className="link" href="#section">
          <button className={langue === "ar" ? "btn button button-ar joindre" : "btn button joindre"}><span className="pseudo-text">{t('Our formulas')}</span></button>
        </a>
      </WhatIs>
      <NotreEquipe>
        <h2 className={langue === "ar" && "alignRightMobile"}>{t('Cm.Our team elaborates')} </h2>
        <p>{t('Cm.To increase your visibility')} </p>
        <div className="row gy-3">
          <div className="col-md-3">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src="/images/cm/social-media.svg" alt="" />
                <h3>{t('Cm.Community Management')}</h3>
              </div>
              <ul className="flip-card-back">
                <h3>{t('Cm.Community Management')}</h3>
                <li>- {t('Cm.Community building')}</li>
                <li>- {t('Cm.Engaging publications')} </li>
                <li>- {t('Cm.Moderation of comments')}</li>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src="/images/cm/Group 39379.svg" alt="Group 39379" />
                <h3>{t('Cm.Web marketing SEO, SEA')}</h3>
              </div>
              <ul className="flip-card-back">
                <h3>{t('Cm.Web marketing SEO, SEA')}</h3>
                <li>- {t('Cm.Community building')}</li>
                <li>- {t('Cm.Engaging publications')} </li>
                <li>- {t('Cm.Moderation of comments')}</li>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src="/images/cm/megaphone.svg" alt="" />
                <h3>{t('Cm.Online advertising')}</h3>
              </div>
              <ul className="flip-card-back">
                <h3>{t('Cm.Online advertising')}</h3>
                <li>- {t('Cm.Community building')}</li>
                <li>- {t('Cm.Engaging publications')} </li>
                <li>- {t('Cm.Moderation of comments')}</li>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src="/images/cm/Group 38967.svg" alt="" />
                <h3>{t('Cm.Performance reports')}</h3>
              </div>
              <ul className="flip-card-back">
                <h3>{t('Cm.Performance reports')}</h3>
                <li>- {t('Cm.Community building')}</li>
                <li>- {t('Cm.Engaging publications')} </li>
                <li>- {t('Cm.Moderation of comments')}</li>
              </ul>
            </div>
          </div>
        </div>
      </NotreEquipe>
      <Pret>
        <div className="row">
          <div className="col-md-8">
            <h2 className={langue === "ar" && "alignRightMobile"}>{t('Cm.Are you ready')}</h2>
          </div>
          <div className="col-md-4">
            <a className="link" href="https://outlook.office365.com/owa/calendar/OXTONDIGITAL@oxton-digital.com/bookings/s/mfXS_SJ4IUCObfB42vKGHw2" target="_blank">
              <button className={langue === "ar"? "btn button button-ar joindre" : "btn button joindre"}><span className="pseudo-text">{t('Subscribe')}</span></button>
            </a>
          </div>
        </div>
      </Pret>
      <Pourquoi>
        <h2 className={langue === "ar" && "alignRightMobile"}>{t('Cm.WHY WORK')}</h2>
        <div className="row">
          <div className="col-md-8">
            <ul  className={langue === "ar" && "ul-ar"}>
              <li>{t('Cm.Because today')}</li>
              <li>{t('Cm.Because it makes it possible')}</li>
              <li>{t('Cm.Because being ready')}</li>
              <p>{t('Cm.There are many tricks')}</p>
              <li>{t('Cm.Animation of a Facebook')}</li>
              <li>{t('Cm.Asserting your expertise')}</li>
              <li>{t('Cm.Animation of teenage')}</li>
              <li>{t('Cm.Highlighting your skills')}</li>
              <li>{t('Cm.Animate your Instagram')}</li>
              <li>{t('Cm.Creating your visual')}</li>
              <li>{t('Cm.Video creation')}</li>
              <li>{t('Cm.Implementation')}</li>
              <li>{t('Referencing')} & {t('SEO')}</li>
              <li>{t('Cm.Influencer')}</li>
            </ul>
          </div>
          <div className="col-md-4">
            <img src="/images/cm/Group 39389.png" alt="Group 39389" />
          </div>
        </div>
      </Pourquoi>
      <AutreServices>
        <h2 className={langue === "ar" && "alignRightMobile"}>{t('Other services')}</h2>
        <div className="row gy-3">
          <div className="col-md-4">
            <Link className="link" to='/prestation-de-service'>
              <div className="card">
                <div className="card-header">
                  <h3>{t('Subcontracting')}</h3>
                  <hr />
                </div>
                <p className="card-body">{t('Cm.You have technical')}</p>
              </div>
            </Link>
          </div>
          <div className="col-md-4">
            <Link className="link" to='/community-management'>
              <div className="card">
                <div className="card-header">
                  <h3>{t('CM')}</h3>
                  <hr />
                </div>
                <p className="card-body">{t('Cm.You want to spread')}</p>
              </div>
            </Link>
          </div>
          <div className="col-md-4">
            <Link className="link" to='/services-de-consultation'>
              <div className="card">
                <div className="card-header">
                  <h3>{t('Consulting_title')}</h3>
                  <hr />
                </div>
                <p className="card-body">{t('Cm.Need expert advice')}</p>
              </div>
            </Link>
          </div>
        </div>
      </AutreServices>
      <StratégieSocialMedia id='section'>
        <h2 className={langue === "ar" && "alignRightMobile"}>{t('Strategy')} {t('social media')}</h2>
        <p>{t('Cm.Opt for one of the packages')}</p>

        <div className="ListButton">
          <button className={active === 1 ? "btn btn-light active" : "btn btn-light"} onClick={() => { setActive(1); setPresence(300); setVisibilite(580); setPerformance(1190) }}>{t('WITHOUT COMMITMENT')}</button>
          <button className={active === 2 ? "btn btn-light active" : "btn btn-light"} onClick={() => { setActive(2); setPresence(260); setVisibilite(540); setPerformance(1099) }}>{t('SUBSCRIPTION')} 6 {t('MONTH')}</button>
          <button className={active === 3 ? "btn btn-light active" : "btn btn-light"} onClick={() => { setActive(3); setPresence(230); setVisibilite(499); setPerformance(999) }}>{t('SUBSCRIPTION')} 12 {t('MONTH')}</button>
        </div>

        <div className="row gy-3">
          <div className="col-md-4">
            <div className="card">
              <ul className={langue === "ar" && "ul-ar"}>
                <div>
                  <span className="euroextendedbold">{t('Presence Pack')}</span><br />
                  <span className="number"> {presence} <sup>€</sup></span> / {t('Month')}
                </div>
                <li><h3>{t('Starting')}</h3></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Kick-off_meeting')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Definition of objectives')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Update of the page')}</span></li>
                <hr />
                <li><h3>{t('Strategy')}</h3></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Shared working document')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Quarterly strategic review')}</span></li>
                <hr />
                <li><h3>{t('Social media')}</h3></li>
                <li>{t('Publication on')} 1 {t('social media')}</li>
                <li>1 {t('publication per week')}</li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Content writing')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Creation of visuals')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Photos with rights of use')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Choice of hashtags')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Integration of publications')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Programming & publishing')}</span></li>
              </ul>
              <div>
                <p>{t('Set_up')}<span>300 €</span></p>
                {/* <button className="btn button"><span className="pseudo-text">En Profitez</span></button> */}
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card">
              <ul className={langue === "ar" && "ul-ar"}>
                <div>
                  <span className="euroextendedbold">{t('Visibility Pack')}</span><br />
                  <span className="number"> {visibilite} <sup>€</sup></span> / {t('Month')}
                </div>
                <li><h3>{t('Starting')}</h3></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Kick-off_meeting')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Definition of objectives')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Update of the page')}</span></li>
                <hr />
                <li><h3>{t('Strategy')}</h3></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Shared working document')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Quarterly strategic review')}</span></li>
                <hr />
                <li><h3>{t('Social media')}</h3> </li>
                <li>{t('Publication on')} 2 {t('social media')}</li>
                <li>2 {t('publication per week')}</li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Content writing')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Creation of visuals')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Photos with rights of use')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Choice of hashtags')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Integration of publications')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Programming & publishing')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Organization of a contest')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Animation and moderation')} 4{t('h')}/{t('Month')}</span></li>
              </ul>
              <div>
                <p>{t('Set_up')}<span>300 €</span></p>
                {/* <button className="btn button"><span className="pseudo-text">En Profitez</span></button> */}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <ul className={langue === "ar" && "ul-ar"}>
                <div>
                  <span className="euroextendedbold">{t('Performance Pack')}</span><br />
                  <span className="number"> {performance} <sup>€</sup></span> / {t('Month')}
                </div>
                <li><h3>{t('Starting')}</h3></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Kick-off_meeting')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Definition of objectives')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Update of the page')}</span></li>
                <hr />
                <li><h3>{t('Strategy')}</h3></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Shared working document')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Quarterly strategic review')}</span></li>
                <hr />
                <li><h3>{t('Social media')}</h3> </li>
                <li>{t('Publication on')} 4 {t('social media')}</li>
                <li>4 {t('publication per week')}</li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Content writing')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Creation of visuals')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Photos with rights of use')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Choice of hashtags')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Integration of publications')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Programming & publishing')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Organization of a contest')}</span></li>
                <li><img src="/images/seo/7-Check.svg" alt="Check" /><span>{t('Cm.Animation and moderation')} 6{t('h')}/{t('Month')}</span></li>
              </ul>
              <div>
                <p>{t('Set_up')}<span>300 €</span></p>
              </div>
            </div>
          </div>
        </div>
      </StratégieSocialMedia>
    </div>
  </>)
}

const NotreEquipe = styled.div`
  padding: 60px 15%;
  background-color: #EEF7FB;
  @media (max-width: 991px) {
    padding: 30px 10% 50px;    
  }
  >h2{
    text-align: center;
    padding-bottom:30px;
  }
  >p{
    text-align: center;
    padding-bottom:30px;
  }
  >.row .col-md-3 {
    @media (max-width: 1420px) {
      width: 50%;
    }
    @media (max-width: 700px) {
      width: 100%;
    }

    &:hover .flip-card-inner { 
        transform: rotateY(180deg);
    }

    >.flip-card-inner {
      position: relative;
      height: 100%;
      width: 100%;
      transition: transform 0.8s;
      transform-style: preserve-3d;
      >.flip-card-front{
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        height: 100%;
        width: 100%;
        padding: 72px 20px 2px;
        background-color: white;
        border-radius: 15px;
        >h3{
          padding-top: 20px;
          padding-bottom: 20px;
          font-size: 18px !important;
        }
        >img{
          position: relative;
          display: block;
        }
      }
      >.flip-card-back{
        position:absolute;
        inset: 0;
        height: 100%;
        width: 100%;
        padding: 20px;
        line-height: 28px;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transform: rotateY(180deg);
        list-style: none;
        background-color: #20ACE3;
        color: white;
        border-radius: 15px;
        >h3{
          color: white;
        }
      }
    }
  }
`;

const Pret = styled.div`
  padding: 30px 15%;
  background-image: linear-gradient(to bottom right, #367ba7, #94cce5);
  @media (max-width: 991px) {
    padding: 30px 10%;    
  }
  >.row{ 
    >.col-md-8 h2{
      color: white;
    }
    >.col-md-4{
      display: flex;
      justify-content: right;
      align-items: center;

      >.link .joindre{
        background-color: white;
        color: #20ACE3;
        padding: 10px 60px !important;
        font-weight: 600;
      }
    }
  }
`;

const Pourquoi = styled.div`
  padding: 60px 15%;
  @media (max-width: 991px) {
    padding: 60px 10%;    
  }
  >h2{
    text-align: center;
    padding-bottom: 30px;
  }
  >.row {
    >.col-md-8 {
      @media (max-width: 991px) {
        width: 100%;
      }
      >ul {
        margin-right: 30px;
        list-style-image: url('/images/cm/arrow right 17406f.svg');
        >p{
          margin-left: -20px;
          color: #20ACE3;
          font-family: EurostileExtendedBold;
        }
        >li{
          padding-left: 5px;
          padding-bottom: 16px;
          line-height: 28px;
        }
      }
      >.ul-ar{
        list-style-image: url('/images/icons/arrow left 17406f.svg');
        padding-right: 0;
      }
    }
    
    >.col-md-4 img{
      width: 100%;
      @media (max-width: 991px) {
        display: none;
      }
    }
  }
  
`;

const StratégieSocialMedia = styled.div`
  padding: 60px 15%;
  @media (max-width: 991px) {
    padding: 60px 10%;    
  }
  >h2{
    text-align: center;
    padding-bottom: 10px;
  }

  >p{
    text-align: center;
    padding-bottom: 30px;
  }

  >.ListButton{
    background-color: #20ACE3;
    margin-bottom: 40px ;
    border-radius: 15px;
    @media (max-width: 577px) {
      display: flex;
      flex-direction: column;
    }
    >.btn-light{
      width: 33.33%;
      color: white;
      background-color: transparent;
      border: 0;
      @media (max-width: 577px) {
      width: 100%;
      }
    }
    >.active{
      background-color: white;
      color: #20ACE3;
      font-weight: 600;
      border: 4px solid #20ACE3;
    }
    >.btn-light.active:focus{
      box-shadow: none;
    }
    >.btn-light:focus-visible{
      outline: none;
      border: transparent;
    }
    >.btn-light:focus{
      box-shadow: none;
      border: 4px solid #20ACE3;
    }
    .btn-light:active{
      border: 4px solid #20ACE3;
    }

  }

  >.row {
    justify-content: center;
    .col-md-4 {
      @media (max-width: 1051px) {
      width: 50%;
    }
    @media (max-width: 663px) {
      width: 100%;
    }
    justify-content: center;
    
    .card{
      box-shadow: 0px 0px 6px rgba(32, 172, 227, .16);
      padding: 30px;
      height: 100%;
      border-radius: 15px;
      >ul{
        list-style: none;
        padding-left: 0;
        >li{
          line-height: 28px;
          font-size: 14px;
          >h3{
            font-style: italic;
            font-weight: 600;
            font-size: 14px !important;
            margin-bottom: 4px;
            line-height: 24px !important;
          }
          >.blue{
            color :#20ACE3;
          }
          >img{
            padding-right: 8px ;
          }
        }
        >div{
          background-color: #EEF7FB;
          padding-top: 20px;
          margin-bottom: 20px;
          border-radius: 15px;
          font-weight: 600;
          color :#20ACE3;
          text-align: center;
          >.euroextendedbold{
            color: #17406F;
            font-family: EurostileExtendedBold;
          }
          .number{
            font-size: 42px;
            >sup{
              font-size: 16px;
              position: relative;
              top: -25px;
              margin-left: -12px;
            }
          }
        }
      }
      >.ul-ar{
        padding-right: 0;
      }
      >ul >li >img{
        padding-right: 0;
        padding-left: 8px;
      }
      >div{
        display: flex;
        flex-direction: column;
        align-items: center;
        place-content: flex-end;
        height: 100%;

        >p{
          padding: 10px ;
          text-align: center;
          border-radius: 15px;
          background-color: #EEF7FB;
          font-weight: bold;
          >span{
            color: #20ACE3;
          }
        } 
        >.button{
          padding: 10px 20px !important;
          color: white;
          width: 150px;
          border-radius:10px !important;
        }
      }
    }
  }
}
`;

export default withTranslation()(Cm)