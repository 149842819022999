import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { URL_Back, URL_Dev } from "../Pages/Variables";
import { Link } from 'react-router-dom';
import Pages from "../../Model/Pages";

const AllPagesWP = () => {

  const [items, setItems] = useState([]);

  useEffect(() => {

    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setItems(result)
        },
      )
  }, [])

  let newpages = [];
  items.map(element =>
    newpages.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.date, element.excerpt.rendered))
  )



  return (
    <>
      <div style={{ padding: "50px 15%" }}>

        {newpages.map((item, key) => <div key={key}>
          <Link to={{ pathname: `/${item.slug}`, state: item }} className="text-black text-decoration-none link" key={item.id} >

            <div style={{ padding: "10px" }} dangerouslySetInnerHTML={{ __html: item.title }} />
          </Link>
        </div>)}

      </div>
    </>
  )
}

export default AllPagesWP;