import React, { useEffect, useState } from "react";
import Portfolio from "../../../Model/Portfolio";
import { Breadcrumb, Title } from "../../../css/commun";
import { URL_Back, URL_Dev } from "../Variables";
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";
import './DetailPortfolio.css'
import CarouselDetails from "./CarouselDetails";
import arrow from '../../../photos/Vector (10).svg'
import arrow2 from '../../../photos/Vector (9).svg'
import arrow3 from '../../../photos/green-arrow.svg'
import img from '../../../photos/consulting/Group 38868.png'
import img1 from '../../../photos/blue.svg'
import img2 from '../../../photos/green.svg'
import prestation from '../../../photos/prestation.svg'
import accomp from '../../../photos/accomp.svg'
import croissance from '../../../photos/croissance.svg'
import TestimonialCarousel from "../TestimonialCarousel";
import SubContactForm from "../Forms/SubContactForm";
import default1 from "../../../photos/default.png"
import default2 from "../../../photos/default2.png"
import default3 from "../../../photos/default3.png"
import { ListPortfolioCarousel } from "./ListPortfolioCarousel";
import { Link } from 'react-router-dom';
import MyButton from "../Services/MyButton";

const DetailPortfolio = (props) => {
  const { state } = props.location
  const title = props.location.pathname
  const splitState = title.split('/')
  const { t } = props
  const [portfolios, setPortfolios] = useState([]);
  const [pages, setPages] = useState([]);
  const [id, setId] = useState("");
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'


  const extractHashtags = (content) => {
    const container = document.createElement('div');
    container.innerHTML = content;
    const hashtagSpans = container.querySelectorAll('.hashtag span');
    const spanTexts = Array.from(hashtagSpans).map(span => span.textContent);
    const svgs = container.querySelectorAll('svg');
    const svgElements = Array.from(svgs).map((svg, index) => {
      return <div key={index} dangerouslySetInnerHTML={{ __html: svg.outerHTML }} />;
    });
    return [...spanTexts, ...svgElements];
  };


  const extractLink = (content) => {
    const container = document.createElement('div');
    container.innerHTML = content;
    const link = container.querySelector('.hashtag a');
    return link ? link.getAttribute('href') : null;
  };
  const extractSubtitle = (content) => {
    const container = document.createElement('div');
    container.innerHTML = content;
    const title = container.querySelector('.hashtag h5');
    return title ? title.textContent : null; 
  };
  function extractListItems(content, listIndex = 1) {
    const div = document.createElement('div');
    div.innerHTML = content;
    const ulElements = div.querySelectorAll('ul');

    if (listIndex > ulElements.length || listIndex < 1) {
      console.error("Invalid list index.");
      return [];
    }

    const ul = ulElements[listIndex - 1];
    return Array.from(ul.querySelectorAll('li')).map(li => li.textContent.trim());
  }

  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])

  const scrollIntop = (id) => {
    window.scrollTo(0, 0);
    localStorage.setItem('idPortfolio', id);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    setId(localStorage.getItem('idPortfolio'))
    if (props.location.state != undefined) {
      setId(props.location.state.id)
    }
    console.log(state)
    localStorage.removeItem('idPortfolio');
    fetch(`${URL_Back}/wp-json/wp/v2/portfolio?_embed&per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          var portfolioLange = []
          currentLanguage === "fr"
            ? (result.map(element => { element.excerpt.rendered === "<p>French</p>\n" && portfolioLange.push(element) }))
            : currentLanguage === "en"
              ? (result.map(element => { element.excerpt.rendered === "<p>English</p>\n" && portfolioLange.push(element) }))
              : currentLanguage === "ar"
              && (result.map(element => { element.excerpt.rendered === "<p>العربية</p>\n" && portfolioLange.push(element) }))
          setPortfolios(portfolioLange)
        }
      )

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setPages(result)
        },
      )
  }, [])

  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description, element.date, element.excerpt.rendered)))

  let item = [];
  portfolios.map(element => {
    splitState[1].length === 2 ?
      element._embedded['wp:featuredmedia'] ?
        element.slug.replace(/[^a-zA-Z0-9]/g, "") === decodeHtmlCharCodes(splitState[3]).replace(/[^a-zA-Z0-9]/g, "") &&
        item.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element._embedded['wp:featuredmedia'][0].source_url, element.excerpt.rendered)) :
        element.slug.replace(/[^a-zA-Z0-9]/g, "") === decodeHtmlCharCodes(splitState[3]).replace(/[^a-zA-Z0-9]/g, "") &&
        item.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element.excerpt.rendered)) :
      element._embedded['wp:featuredmedia'] ?
        element.slug.replace(/[^a-zA-Z0-9]/g, "") === decodeHtmlCharCodes(splitState[2]).replace(/[^a-zA-Z0-9]/g, "") &&
        item.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element._embedded['wp:featuredmedia'][0].source_url, element.excerpt.rendered)) :
        element.slug.replace(/[^a-zA-Z0-9]/g, "") === decodeHtmlCharCodes(splitState[2]).replace(/[^a-zA-Z0-9]/g, "") &&
        item.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element.excerpt.rendered))
  })

  let portfolio = [];
  portfolios.map(element => element._embedded['wp:featuredmedia']
    ? item[0] !== undefined && console.log() && item[0] !== undefined
      ? item[0].id !== portfolio.id && item[0].id <= portfolio.id && portfolio.length < 4 &&
      portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element._embedded['wp:featuredmedia'][0].source_url, element.excerpt.rendered))
      : state !== undefined && element.id !== state.id && element.id <= state.id && portfolio.length < 4 &&
      portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element._embedded['wp:featuredmedia'][0].source_url, element.excerpt.rendered))
    : item[0] !== undefined && console.log() &&
      item[0] !== undefined ? item[0].id !== portfolio.id && item[0].id <= portfolio.id && portfolio.length < 4 &&
    portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element.excerpt.rendered))
      : state !== undefined && element.id !== state.id && element.id <= state.id && portfolio.length < 4 &&
      portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element.excerpt.rendered))
  )

  portfolios.map(element =>
    element._embedded['wp:featuredmedia']
      ? portfolio.length < 4
      && portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element._embedded['wp:featuredmedia'][0].source_url, element.excerpt.rendered)) :
      portfolio.length < 4
      && portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element.excerpt.rendered))
  )

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "/assets/js/getTag.js";
    script.type = "text/javascript";
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  function decodeHtmlCharCodes(str) {
    const txt = document.createElement("textarea");
    txt.innerHTML = str;
    return txt.value;
  }

  let titre = item[0] !== undefined ? item[0].title.replace("&#8217;", "'") : "...Loading"
  document.title = titre;

  const extractImages = (content) => {
    const imgTags = content.match(/<img[^>]+src="([^">]+)"/g);

    let images = [];
    if (imgTags) {
      images = imgTags.map((imgTag, index) => {
        const srcMatch = imgTag.match(/src="([^">]+)"/);
        return srcMatch ? { id: index + 1, src: srcMatch[1] } : null;
      })
        .filter(img => img && img.src !== '/images/portfolio/XMLID_105_.svg')
        .slice(0, 3);
    }

    while (images.length < 3) {
      const newId = images.length + 1;

      if (!images.some(img => img.src === default1)) {
        images.push({ id: newId, src: default1 });
      } else {

        images.push({ id: newId, src: default1 });
      }
    }

    return images;
  };


  const excludeImage = '/images/portfolio/XMLID_105_.svg';

  const extractFourthImage = (content) => {
    const imgTags = content.match(/<img[^>]+src="([^">]+)"/g);

    if (imgTags) {
      const filteredImgTags = imgTags.filter(tag => !tag.includes(excludeImage));
      if (filteredImgTags.length >= 4) {
        const srcMatch = filteredImgTags[3].match(/src="([^">]+)"/);
        return srcMatch ? { id: 4, src: srcMatch[1] } : null;
      }
    }

    return { id: 4, src: default2 };
  };

  const extractFifthImage = (content) => {
    const imgTags = content.match(/<img[^>]+src="([^">]+)"/g);

    if (imgTags) {
      const filteredImgTags = imgTags.filter(tag => !tag.includes(excludeImage));
      if (filteredImgTags.length >= 5) {
        const srcMatch = filteredImgTags[4].match(/src="([^">]+)"/);
        return srcMatch ? { id: 5, src: srcMatch[1] } : null;
      }
    }

    return { id: 5, src: default3 };
  };

  const content = state !== undefined ? state.content : item[0] ? item[0].content : '';
  const images = extractImages(content);
  const duplicatedImages = [...images, ...images, ...images];

  const truncateContent = (content) => {
    content = content.replace(/<img[^>]*>/g, '');
    const match = content.match(/<p[^>]*>(.*?)<\/p>/);
    return match ? match[0] : content;
  };
  function getParagraphByNumber(content, paragraphNumber) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const paragraphs = Array.from(doc.querySelectorAll('p'))
      .filter(p => !p.closest('.hashtag') && !p.closest('figure'));
    paragraphs.forEach((p, index) => console.log(`Paragraphe ${index + 1}:`, p.innerHTML));
    if (paragraphs.length < paragraphNumber) {
      console.warn(`Le paragraphe numéro ${paragraphNumber} est introuvable.`);
      return '';
    }
    return paragraphs[paragraphNumber - 1].innerHTML;
  }



  console.log('para:', getParagraphByNumber(content, 2))
  const fourthImage = extractFourthImage(content);
  const fifthImage = extractFifthImage(content);
  console.log('Content:', state?.content || item?.[0]?.content);
  console.log(content)

  const result = extractListItems(content, 1);
  const result2 = extractListItems(content, 2);
  const result3 = extractListItems(content, 3);
  const buttons = extractHashtags(content);
  const projectLink = extractLink(content);
  
  return (
    <>
      <Helmet>
        <title>Oxton Digital | Nos réalisations : {titre}</title>
        <meta name="description" content={page[0] !== undefined && page[0].description} />
        <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
      </Helmet>
      <div className="container-fluid detail-portfolio-container" >
        <div className="container">
          <Breadcrumb>
            <p>
              <Link className="retour" to="/">{t('Home_link')}</Link> / <Link className="retour" to={langue === "fr" ? "/fr/nos-references" : langue === "en" ? "/en/portfolio" : "/ar/مَلَفّ"}>{t('Portfolio_subtitle')}</Link> / <span>{state !== undefined ? decodeHtmlCharCodes(state.title) : item[0] !== undefined ? titre : "....Loading"}</span>
            </p>
          </Breadcrumb>
          <Title>
            <div className="detail-titles d-flex">
            <h1>{state !== undefined ? decodeHtmlCharCodes(state.title) : item[0] !== undefined ? titre : "....Loading"}</h1>
            <h5> {extractSubtitle(content)} </h5>
            </div>
            <div className="title-p" dangerouslySetInnerHTML={{
              __html: state && state.content
                ? getParagraphByNumber(state.content, 1)
                : (item && item[0] && item[0].content
                  ? getParagraphByNumber(item[0].content, 1)
                  : '....Loading')
            }} />
          </Title>
          <div className=" button-group-details ">
            {buttons && buttons.length > 0 ? (
              buttons.map((label, index) => (
                <button key={index} className="custom-button-details">
                  {label}
                </button>
              ))
            ) : (
              null
            )}
          </div>
        </div>

        <div className="carousel-detail-container">
          <CarouselDetails items={duplicatedImages} />
        </div>


        <div className="container defi-container mt-5">
          {(state?.content && getParagraphByNumber(state.content, 2)) ||
            (item?.[0]?.content && getParagraphByNumber(item[0].content, 2)) ? (
            <>
              <div className="respect-container">
                <div className="respect-icon">
                  <img src={img} alt="Réactivité" />
                </div>
                <div className="respect-content">
                  <h3>Le défi</h3>
                  <div
                    className="title-p"
                    dangerouslySetInnerHTML={{
                      __html: state?.content
                        ? getParagraphByNumber(state.content, 2)
                        : getParagraphByNumber(item[0].content, 2),
                    }}
                  />
                </div>
              </div>
              <div className="problems-section">
                <h2>Problèmes</h2>
                <div className="problems-row">
                  {result.map((problem, index) => (
                    <div key={index} className="problem-item">
                      {problem && (
                        <img src={arrow} alt="icon" className="problem-icon" />
                      )}
                      <div className="problem-text">{problem}</div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : null}
          <div className="fourth-image">
            {fourthImage?.src && (
              <img src={fourthImage.src} className="img-fluid" alt="Image" />
            )}
          </div>
        </div>



        <div className="container mt-5 solution-container">
          {(state?.content && getParagraphByNumber(state.content, 3)) ||
            (item?.[0]?.content && getParagraphByNumber(item[0].content, 3)) ? (
            <>
              <div className="respect-container">
                <div className="respect-icon">
                  <img src={img2} alt="Réactivité" />
                </div>
                <div className="respect-content">
                  <h3 className="results-title">Résultats</h3>
                  <div
                    className="title-p"
                    dangerouslySetInnerHTML={{
                      __html: state?.content
                        ? getParagraphByNumber(state.content, 3)
                        : getParagraphByNumber(item[0].content, 3),
                    }}
                  />
                </div>
              </div>
              <div className="actions-section">

                <div className="actions-row">
                  {result2?.map((problem, index) => (
                    <div key={index} className="action-item">
                      {problem && (
                        <img src={arrow3} alt="icon" className="action-icon" />
                      )}
                      <div className="action-text">{problem}</div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : null}
        </div>


        <div className="container mt-5 solution-container">
          {result3 && result3.length > 0 ? (
            <>
              <div className="respect-container">
                <div className="respect-icon">
                  <img src={img1} alt="Réactivité" />
                </div>
                <div className="respect-content">
                  <h3 className="solution-title">Nos actions</h3>
                  <p className="title-p">Pour relever ce défi, nous avons déployé les actions suivantes :</p>
                </div>
              </div>
              <div className="actions-section">
                <div className="actions-row">
                  {result3.map((problem, index) => (
                    <div key={index} className="action-item">
                      {problem && (
                        <img src={arrow2} alt="icon" className="action-icon" />
                      )}
                      <div className="action-text">{problem}</div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : null}


          <div className="fifth-image">
            {fifthImage?.src && (
              <a href={projectLink} target="_blank" rel="noopener noreferrer" className="image-link">
                <img src={fifthImage.src} className="img-fluid" alt="Image" />
                {projectLink && (
                  <div className="link-popup">{projectLink}</div>
                )}
              </a>
            )}
          </div>


          <div className="container characteristics-container">
            <div className="characteristic">
              <img src={accomp} />
              <div className="ch-nb-title">
                <div className="ch-title">D’ACCOMPAGNEMENT</div>
                <span className="ch-nb">6 mois</span>
              </div>
            </div>
            <div className="characteristic">
              <img src={croissance} />
              <div className="ch-nb-title">
                <div className="ch-title">CROISSANCE</div>
                <span className="ch-nb">670%</span>
              </div>
            </div>
            <div className="characteristic">
              <img src={prestation} />
              <div className="ch-nb-title">
                <div className="ch-title">PRESTATIONS RÉALISÉES</div>
                <span className="ch-nb">6+</span>
              </div>
            </div>
          </div>
          <div className="voix-detail-page">
            <div className="voix">
              <h2 className="">Voix de nos clients</h2>
              <p className="title-p">Découvrez ce que les clients pensent de nous. Promis, ce sont des vrais clients contents,
                allez voir sur Google !</p>
              <TestimonialCarousel />
            </div>
          </div>

          <div className="detail-page-portfolio">
            <div className="respect-container">
              <div className="respect-content">
                <h3>Découvrir d’autres études de cas client</h3>
              </div>
            </div>
            <ListPortfolioCarousel portfolio={portfolio} langue={langue} />
          </div>

          <div className="detail-page-meet">
          <div className="respect-container">
              <div className="respect-content">
              <h3>Vous avez un projet en tête ?</h3>
              </div>
            </div>
          
            <div className="detail-meet-rec">
             
              
              <div className="meet-rec-title-btn">
              <p className="title-p">Contactez-nous dès aujourd’hui pour une consultation gratuite et découvrez comment nous pouvons vous aider à atteindre vos objectifs !</p>
              <MyButton/>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}





export default withTranslation()(DetailPortfolio)
