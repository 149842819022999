import styled from "styled-components";

export const Description = styled.div`
color: white;
text-align: center;
display: flex;
flex-direction: column;
align-content: baseline;
justify-content: end;
>div{
  padding: 80px 15% 50px 15%;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 991px) {
  padding: 80px 10% 40px 10%;}
}

>.lBowling{
  background-image: url("/images/limogesBowling/Rectangle 5074.png");
}

>.sBowling{
  background-image: url("/images/spaceBowling/Rectangle 5074.png");
}

>.pBowling{
  background-image: url("/images/planetBowling/Mask Group 635.png");
}

>.eBowling{
  background-image: url("/images/extraLaserBowling/fj.png");
}

@media (max-width: 991px) {
  >div p{
  text-align: left;
}
}
>.mainImage{
  margin: 0px 15%;
  position: relative;
  top:90px;
  width:80%;
  max-width: 940px;
  align-self: center;
  height: auto;
  margin-bottom: -20px;
  @media (max-width: 850px) {
    top:80px;
  }
  @media (max-width: 550px) {
    top:60px;
  }
  @media (max-width:490px) {
    width: 80%;
  }
}

>div .technologies{
  align-self: center;
  padding-top: 30px;
  font-weight: bold;
  span{
    padding-right: 30px;
  }
  img{
    padding-right: 10px;
  }
}
`;

export const Contacter = styled.div`
  margin:0px 15% 0px 15% ;
  @media (max-width: 768px) {
    margin:0px 10% 0px 10% ;
  }
  @media (max-width: 500px) {
    margin:0px 10% ;
  }
  color:black;
  text-align: center;
  position: relative;
  >.planetH{
      color: white;
    }
  >.planetB{
      color: #072754 !important;
      background-color: #FFD500 !important;
    }
  >.bowlingH{
    color:rgb(43, 46, 113) !important;
  }
  >h2{
    position: absolute;
    padding: 0 5%;
    top:56px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center !important;
    @media (max-width:391px) {
      top:100px;
      padding: 0 15%;
    }
    @media (max-width:429px) and (min-width:392px) {
      top:100px;
      padding: 0 17%;
    }
    @media (max-width:474px) and (min-width:430px) {
      top:100px;
      padding: 0 20%;
    }
    @media (max-width:546px) and (min-width:474px) {
      top:110px;
      padding: 0 20%;
    }
    @media (max-width:600px) and (min-width:547px) {
      top:68px;
      font-size: 21px !important;
    }
    @media (max-width:623px) and (min-width:601px) {
      top:80px;
      font-size: 22px !important;
    }
    @media (max-width:671px) and (min-width:624px)  {
      top:85px;
      font-size: 24px;
    }
    @media (max-width:811px) and (min-width:671px)  {
      top:88px;
      font-size: 24px;
    }
    @media (max-width:1590px) and (min-width:811px){
      top:65px
    }
    @media (max-width:2560px) and (min-width:1591px){
      top:76px
    }
  }
  >.web{
    width: 100%;
    height: 230px;
    @media (max-width: 811px) {
      display: none;
    }
    @media (max-width:2560px){
      height: 250px;
    }
    @media (max-width:1657px) {
      height: 300px;
    }
  }
  .tablet{
    display: none;
    width: 100%;
    @media (max-width: 811px) and (min-width:547px){
      display: block;
    }
  }
  .mobile{
    display: none;
    width: 100%;
    @media (max-width: 546px) {
      display: block;
      height: 400px;
    }
    @media (max-width: 500px) {
      display: block;
      height: 350px;
    }
  }
  >.link .contactez{
    background-color: #B61111;
    border: 0;
    padding: 10px 40px !important;
    color: white;
    font-weight: 600;
    position: relative;
    top:-160px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    margin-bottom: 0;
    @media (max-width:1656px){
      top:-170px;
    }
    @media (max-width:992px){
      top:-165px;
    }
    @media (max-width:779px){
      top:-162px;
    }
    @media (max-width:769px){
      top:-182px;
    }
    @media (max-width:726px){
      top:-172px;
    }
    @media (max-width:701px){
      top:-165px;
    }
    @media (max-width:670px){
      top:-160px;
    } 
    @media (max-width:640px){
      top:-150px;
    }
    @media (max-width:620px){
      top:-147px;
    }
    @media (max-width:600px){
      top:-150px;
    }
    @media (max-width:580px){
      top:-140px;
    }
    @media (max-width:500px){
      top:-135px;
    }
    @media (max-width:489px){
      top:-115px;
    }
    @media(max-width:474px){
      top:-120px;
    }      
  }
`;

export const Client = styled.div`
  overflow: hidden;
  @keyframes slide {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-195%);
    }
  }
  @keyframes slideAr {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(195%);
    }
  }
  >div {
    ul{
      display: flex;
      justify-content: space-between;
      align-items: center;
      list-style-type: none;
      white-space: nowrap;
      transition: all 1s ease;
      width: 100%;
      animation: slide 20s linear infinite;

      &:hover {
        -webkit-animation-play-state:paused;
        -moz-animation-play-state:paused;
        -o-animation-play-state:paused;
        animation-play-state: paused;
      }
      >li{
        padding-right: 150px;
        width: 400px;
      }
      >li{
        font-family:EurostileExtendedBold;
        img{
        height: 50px;
        }
      }

    }
    >.ul-anim-ar{
      animation: slideAr 20s linear infinite;
    }
  }
`;
