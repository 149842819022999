export default class Maps {
  constructor(id, title, content, excerpt, date) {
      this.id = id;
      this.title = title;
      this.content = content;
      this.excerpt = excerpt;
      this.date = date;
  }

  setId(id) {
      this.id.set(id);
  }
  getId(id) {
      return this.age.get(id);
  }

  setTitle(title) {
      this.title.set(title);
  }
  getTitle(title) {
      return this.title.get(title);
  }

  setContent(content) {
      this.content.set(content);
  }
  getContent(content) {
      return this.content.get(content);
  }

  setExcerpt(excerpt) {
      this.excerpt.set(excerpt);
  }
  getExcerptt(excerpt) {
      return this.excerpt.get(excerpt);
  }


  setDate(date) {
      this.date.set(date);
  }
  getDate(date) {
      return this.date.get(date);
  }

}