import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { URL_Back, URL_Dev } from "../Pages/Variables";
import { Link, Redirect } from 'react-router-dom';
import Pages from "../../Model/Pages";
import { Title } from "../../css/commun";
import styled from "styled-components";

const OnePagesWP = (props) => {

  const title = props.location.pathname
  const splitState = title.split('/')

  const [items, setItems] = useState([]);

  useEffect(() => {

    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setItems(result)
        },
      )
  }, [])

  let newpages = [];
  items.map(element =>
    element.slug == splitState[1] &&
    newpages.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.date, element.excerpt.rendered))
  )

  let parent = [];
  newpages.length != 0 && (items.map(element =>
    newpages !== undefined && newpages[0].parent == element.id &&
    parent.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.date, element.excerpt.rendered))
  ))

  return (
    <>
      <Helmet>
        <title>{newpages[0] !== undefined && newpages[0].title}</title>
        <link rel="canonical" href="https://oxton-digital.com/404-not-found" />
      </Helmet>
      <div className="container-fluid">
        {newpages.length != 0 ? newpages.map((item, key) => <div key={key}>
          <Title>
            <h1 dangerouslySetInnerHTML={{ __html: item.title }} />
            <p>
              <img className={langue === "ar" && "img-accueil-ar"} src="/images/accueil.png" alt="accueil" />
              <Link className="retour" to="/">{t('Home_link')}</Link>
              /
              {item.parent !== 0 && <Link className="retour" to={`/${parent[0].slug}`}> {parent !== undefined && parent[0].title} /</Link>}

              <span> {item.title}</span>
            </p>
          </Title>
          <Content dangerouslySetInnerHTML={{ __html: item.content }} />
        </div>
        ) : <Redirect to="404-not-found" />}

      </div>
    </>
  )
}

const Content = styled.div`
  margin: 50px 15%;
  @media (max-width: 991px) {
    margin: 50px 10%;
  }
`;

export default OnePagesWP