import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Portfolio from "../../../Model/Portfolio";
import styled from "styled-components";
import { Breadcrumb, Title } from "../../../css/commun";
import { URL_Back, URL_Dev } from "../Variables";
import { ListPortfolio } from "./ListPortfolio";
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";
import { TailSpin } from 'react-loader-spinner';
import './Portfolio.css'
const Portfolios = (props) => {
  const title = props.location.pathname
  const splitState = title.split('/')
  const staticTitles = {
    en: "Nos Réalisations",
    fr: "Nos Réalisations"
  };
  const { t } = props;
  const [items, setItems] = useState([]);
  const [portfolioCategori, setPortfolioCategori] = useState(0);
  const [number, setNumber] = useState(12)
  const [pages, setPages] = useState([]);
  const [langue, setLangue] = useState("fr");
  const [loading, setLoading] = useState('true')
  const currentLanguage = cookies.get('i18next') || 'fr'
  const [titre, setTitre] = useState(staticTitles[currentLanguage]);
  const [lastChecked, setLastChecked] = useState(null);


  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'

    fetch(`${URL_Back}/wp-json/wp/v2/portfolio?_embed&per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          var portfolioLange = []
          currentLanguage === "fr"
            ? (result.map(element => { element.excerpt.rendered === "<p>French</p>\n" && portfolioLange.push(element) }))
            : currentLanguage === "en"
              ? (result.map(element => { element.excerpt.rendered === "<p>English</p>\n" && portfolioLange.push(element) }))
              : currentLanguage === "ar"
              && (result.map(element => { element.excerpt.rendered === "<p>العربية</p>\n" && portfolioLange.push(element) }))
          setItems(portfolioLange)
          setLoading(false)
        },
      )
  }, [currentLanguage])

  const scrollToEnd = () => {
    setNumber(number + 3)
  }

  window.onscroll = function () {
    if (window.innerHeight + document.documentElement.scrollTop > (document.documentElement.offsetHeight - 300)) {
      scrollToEnd()
    }
  }
  const cacheTitle = (result) => {
    const page = result.find(element =>
      decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1])
    );

    if (page) {
      const title = page.title.rendered.replace("&#8217;", "'");
      if (title !== titre) {
        setTitre(title);
      }
      setLastChecked(new Date().getTime()); 
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    const oneMonth = 30 * 24 * 60 * 60 * 1000;
    const lastCheckedTimestamp = lastChecked || 0;

    if (new Date().getTime() - lastCheckedTimestamp >= oneMonth) {
      fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
        .then(res => res.json())
        .then((result) => {
          setPages(result);
          cacheTitle(result);
        });
    }
  }, [lastChecked]);


  console.log(items)
  let portfolio = [];
  (portfolioCategori === 0) ?
    items.map(

      element => element._embedded['wp:featuredmedia']

        ? portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element._embedded['wp:featuredmedia'][0].source_url, element.excerpt.rendered))
        : portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element.excerpt.rendered))
    ) : items.map(element => element._embedded['wp:featuredmedia']
      ? element.categoriesportfolio.map(val => (val === portfolioCategori && portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element._embedded['wp:featuredmedia'][0].source_url, element.excerpt.rendered))))
      : element.categoriesportfolio.map(val => (val === portfolioCategori && portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element.excerpt.rendered)))
      )
    )
  console.log(portfolio)
  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description, element.date, element.excerpt.rendered)))

  // let titre = page[0] !== undefined ? page[0].title.replace("&#8217;", "'") : "...Loading"

  return (
    <>
      <Helmet>
        <title>Oxton Digital | Nos réalisations</title>
        <meta name="description" content={page[0] !== undefined && page[0].description} />
        <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
      </Helmet>
      <div className="container" >
      <Breadcrumb>
        <p>
          <Link className="retour" to="/">{t('Home_link')}</Link> / <span>{titre}</span>
        </p>
      </Breadcrumb>
        <Table>
          <Title>
            <h1>{titre}</h1>
            <p className="title-p">Découvrez ci-dessous une sélection d’études de cas détaillées, parmi les 100 projets réalisés par Oxton-digital. Vous pouvez également découvrir les dernières réalisations (sites, apps, vidéos, identités visuelles, etc.) ou les projets par secteur.</p>

          </Title>
          <div className="listBotton">
            <button type="button" className={portfolioCategori === 0 ? "btn  active" : "btn "} onClick={() => (setPortfolioCategori(0), setNumber(6))}>{t('Portfolio.All')}</button>
            {/* <div className="d-none container">
              <h3 className='text-white'>{t('Portfolio.references')}</h3>
              <p className='text-white'>{t('Portfolio.custom_solutions')}</p>
            </div> */}
            <button type="button" className={portfolioCategori === 5 ? "btn  active" : "btn "} onClick={() => (setPortfolioCategori(5), setNumber(6))}>{t('Portfolio.Web_development')}</button>
            <div className="d-none container">
              {/* <h3 className='text-white'>{t('Portfolio.examples')}</h3> */}
              {/* <p className='text-white'>{t('Portfolio.beautiful')}</p> */}
            </div>
            <button type="button" className={portfolioCategori === 6 ? "btn btn-mobile active" : "btn btn-mobile "} onClick={() => (setPortfolioCategori(6), setNumber(6))}>{t('Mobile')}</button>
            <div className="d-none container">
              {/* <h3 className='text-white'>{t('Portfolio.mobile')}</h3> */}
              {/* <p className='text-white'>{t('Portfolio.record')}</p> */}
            </div>
            <button type="button" className={portfolioCategori === 7 ? "btn btn-design active" : "btn btn-design "} onClick={() => (setPortfolioCategori(7), setNumber(6))}>{t('Portfolio.UX_UI_Design')}</button>
            <div className="d-none container">
              {/* <h3 className='text-white'>{t('Portfolio.examples_design')}</h3> */}
              {/* <p className='text-white'>{t('Portfolio.graphic')}</p> */}
            </div>
            <button type="button" className={portfolioCategori === 9 ? "btn btn-borne  active" : "btn btn-borne"} onClick={() => (setPortfolioCategori(9), setNumber(6))}>{t('Kiosk')}</button>
            <div className="d-none container">
              {/* <h3 className='text-white'>{t('Portfolio.examples_kiosks')}</h3> */}
              {/* <p className='text-white'>{t('Portfolio.dedicated')}</p> */}
            </div>
          </div>
          {loading
            ? <div className="loading">
              <TailSpin color="#17406F" />
            </div>
            : <>
              <ListPortfolio portfolio={portfolio} number={number} langue={langue} />
              {(portfolioCategori === 0 && number < items.length) ? (
                <div className="loading" onClick={() => setNumber(number + 9)}>
                  <TailSpin color="#17406F" />
                </div>
              ) : number < portfolio.length && (
                <div className="loading" onClick={() => setNumber(number + 3)}>
                  <TailSpin color="#17406F" />
                </div>
              )}

            </>}
          {/* <p className="container différence text-white py-3">{t('Portfolio.descriptionseo')}</p> */}
        </Table>
      </div >
    </>)
}


const Table = styled.div`
// h1 {
// color:#20ACE3 !important;
//     font-family: 'RalewayRegular';
//     font-weight: 600;
//     font-size: 42px !important;

// }
 p{
 font-size:18px;
 margin-bottom:0px !important ;
 }
 
  .listBotton{
    padding:30px 0px ;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px; 
    @media (max-width: 1400px){
      padding: 30px 0px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;
    }
      @media (max-width: 1199px){
       grid-template-columns: repeat(3, 1fr);
      }
       @media(max-width: 767px){
       grid-template-columns: repeat(2, 1fr);
       }
   
    @media (max-width: 575px) {
        padding: 20px 0px 0px 0px;
        margin: 0 20px;
        margin-bottom: 25px;
}
    >.btn{
      border: 1px solid #17406F;
    font-family: InterRegular;
    font-size: 18px;
    color: black;
    margin-right: 0px;
    margin-bottom: 40px;
    width: 235px;
    height: auto;
    border-radius: 6px !important;
    @media (max-width:1400px) {
    margin-bottom: 18px;
        width: 266px;
    }
      @media (max-width:991px) {
        margin-bottom: 18px;
      }
      @media (max-width:500px) and (min-width: 431px){
        border-radius: 4px !important;
        width: 45%;
      }
       @media (max-width:576px){
        border: 1px solid rgb(23, 64, 111);
        font-family: InterRegular;
        font-size: 14px;
        width: 168px;
        height: auto;
        border-radius: 6px !important;
       }
      &:hover{
          background-color: #17406F;
          color: #fff;
          box-shadow: none !important;
        }
      }
    .active{
    background-color: #17406F;
    color: #fff;
        box-shadow: none !important;
    }
    .active + div {
      display:block!important;
      position:absolute;
      margin-top: 80px;
      @media (max-width:991px) {
        display:none!important;
      }
    }
  }
  >.loading{
    display: flex;
    justify-content: center;
  } 
  >.table{
    
    display:flex ;
    flex-direction:row ;
    justify-content:center ;

    @media (max-width: 1018px){
      justify-content: center;
    }
      @media (max-width: 767px){
      flex-direction: column;
      }
    >.link{
      text-align: center;
      padding: 0 11px 22px 11px;
      border: 0;
      width: 25%;
      flex: 0 0 50%;
      min-width:17rem;
      @media (max-width:1555px) {
        width: 33%;
      }
      @media (min-width: 815px) and (max-width: 1187px) {
        width: 50%
      }
      @media (max-width: 814px) {
        width: 100%
      }
      >.card{
        border-radius: 6px;
        border: 1px solid #17406F;
        background-color: #fff;
        padding: 20px;
        @media (min-width: 814px) {
          height: 100%;
         }
        @media (max-width: 576px) {
           padding:15px;
          } 
      &:hover{
        background-color:#20ACE3;
        box-shadow: 0px 5px 11px 0px #20ACE366;
        border: 1px solid #20ACE3;
       svg path{
       fill:white;
       }
        .card-body h3{
        color:white;
        }
}
          
        >.image{
          padding: 0;
          height: 290px;
          @media (max-width:2100px) {
           
          }
          @media (max-width:814px) {
            height: 350px;
          }
          @media (max-width:550px) {
            height: 300px;
          }
          @media (max-width:440px) {
            height: 250px;
          }
        }
        >.image >img{
          padding:0;
          width:100%;
          border-radius:5px;
          height: 100%;
          object-fit: cover;
          border-top:20px;
        } 
        >.card-body{
          padding-top:20px;
          height: auto;
          padding-bottom: 0;
          >.card-header {
        
            background-color: transparent;
            border: 0;
            padding: 0.2rem 0;
             
              }
            h3{
            color: #17406F;
   

    font-family: RalewayRegular;
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 20px !important;
    line-height: 24px !important;
    text-align: left;
      @media(max-width:576px){
           font-size: 15px !important;
          line-height: 20px !important;
      }
    
    }
          }
          >p{
            position: relative;
            >.url{
            font-weight: 600;
            }
            >span{
              color: #17406F;
            }
          }
        }
      }
    }
  }
  >.table{
    
  }
  >img{
    padding-top: 30px;
  }

  >.arrow{
    cursor: pointer;
    position: relative;
    left: 50%;
  }
`;

export default withTranslation()(Portfolios)
