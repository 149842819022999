import React from 'react'
import styled from "styled-components";

function Popup(props) {
  return  (props.trigger) ? (
    <Popups>
      <div className='popup-inner text-center'>
        <svg onClick={()=> props.setTrigger(false)} className='close-btn' xmlns="http://www.w3.org/2000/svg" width="26.002" height="26.001" viewBox="0 0 26.002 26.001">
          <g id="_02_User" data-name="02 User" transform="translate(0.006 0.003)">
            <path id="Path_92024" data-name="Path 92024" d="M1.264,26a1.27,1.27,0,0,1-.9-2.167L23.828.369a1.27,1.27,0,1,1,1.8,1.8L2.162,25.626a1.266,1.266,0,0,1-.9.372Z" transform="translate(0 0)" fill="#043e70" />
            <path id="Path_92025" data-name="Path 92025" d="M24.728,26a1.266,1.266,0,0,1-.9-.372L.369,2.164a1.27,1.27,0,1,1,1.8-1.8L25.626,23.831a1.27,1.27,0,0,1-.9,2.167Z" transform="translate(-0.002)" fill="#043e70" />
          </g>
        </svg>
        <img src='/images/Contact/Group 38952.svg' alt='Group 38952' />
        {props.children}
      </div>
    </Popups>
  ) : "";
}

const Popups = styled.div`
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);

  display:flex;
  justify-content: center;
  align-items: center;

  z-index: 9999;

  >.popup-inner{
    position: relative;
    margin: 0 20px;
    padding: 32px;
    width: 100%;
    max-width: 400px;
    background-color: #FFF;
    border-radius: 15px;
    top: -400px;
    animation: drop 0.5s ease forwards;
    display: flex;
    flex-direction: column;
    align-items: center;
    >p{
      color:#17406F;
      padding-top: 30px;
      font-size: 20px;
      font-weight: 600;
    }
    >img{
      width: 90px;
    }


    @keyframes drop {
      0%{opacity:0}
      70%{transform: translateY(170%)}
      100%{transform: translateY(150%); opacity:1;}
    }

    >.close-btn{
      position: absolute;
      height: 22px !important;
      padding-top:0 !important;
      margin-top:0 !important;
      top: 16px;
      right: 16px;
      cursor: pointer;
    }
  }


`;

export default Popup