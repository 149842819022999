import React from 'react';
import ReactDOM from 'react-dom/client'; 
import App from './components/App';
import { HelmetProvider } from 'react-helmet-async';

const rootElement = document.getElementById("root");

const root = ReactDOM.createRoot(rootElement);  
root.render(
  <HelmetProvider>
    <App />
  </HelmetProvider>
);
