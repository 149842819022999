export default class Offre {
    constructor(id, title, content, date, excerpt, slug, x_categories) {
        this.id = id;
        this.title = title;
        this.content = content;
        this.date = date;
        this.excerpt = excerpt;
        this.slug = slug;
        this.x_categories = x_categories;
    }

    setTitle(title) {
        this.title.set(title);
    }
    getTitle(title) {
        return this.title.get(title);
    }
    setId(id) {
        this.id.set(id);
    }
    getId(id) {
        return this.age.get(id);
    }
    setContent(content) {
        this.content.set(content);
    }
    getContent(content) {
        return this.content.get(content);
    }
    setDate(date) {
        this.date.set(date);
    }
    getDate(date) {
        return this.date.get(date);
    }
    setExcerpt(excerpt) {
        this.excerpt.set(excerpt);
    }
    getExcerpt(excerpt) {
        return this.excerpt.get(excerpt);
    }
    setSlug(slug) {
        this.slug.set(slug);
    }

    getSlug(slug) {
        return this.slug.get(slug);
    }
    setX_categories(x_categories) {
        this.x_categories.set(x_categories);
    }
    getX_categories(sx_categorieslug) {
        return this.slug.get(x_categories);
    }


}