import React, { useEffect, useState } from "react";
import "./StatutServices.css";
import im1 from '../../../photos/Group 39074.svg'
import im2 from '../../../photos/maintenance 1.svg'
import im3 from '../../../photos/Sync.svg'

const StatutServices = () => {
  const [servers, setServers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const token = '6b2d4a05a988e90c251cf146e1fb5689b44f0d0a9d0d4ebb5778abe65401e235';
    const url = `https://api.monitoring360.io/v1/servers?token=${token}`;

    fetch(url, {
      headers: {
        'Accept': 'application/json',
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Erreur: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setServers(data.servers);
      })
      .catch(error => {
        setError(error.message);
      });
  }, []);

  console.log(servers)

  return (
    <div className="service-status container">
      <h2>Statut Des Services</h2>
      <p className="title-p p-statut">Informations En Temps Réel Sur Les Performances Et La Disponibilité Du Système.</p>
      <div className="status-cards">
        {servers.map((service, index) => (
          <div className="status-card" key={index}>
            <h3>{service.name}</h3>
            <span
              className={`status-label ${service.status === 'up' ? 'green' : 'blue'}`}>
              <img
                src={service.status === 'up' ? im1 : im2}
                alt="icon"
              />
              {service.status === 'up' ? 'Tous les systèmes opérationnels' : 'Événement(s) en cours'}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatutServices;
