import React, { useState, useEffect } from "react";
import { Breadcrumb, Title } from "../../../css/commun";
import { URL_Back, URL_Dev } from "../Variables";
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";
import './ServicesMobile.css'
import use from "../../../photos/mobile/use.svg"
import augmentation from "../../../photos/mobile/Augmentation.svg"
import engagement from "../../../photos/mobile/Engagement.svg"
import notification from "../../../photos/mobile/notification.svg"
import revolution from "../../../photos/mobile/revolution.svg"
import expertise from "../../../photos/mobile/expertise.svg"
import reference from "../../../photos/mobile/reference.svg"
import budget from "../../../photos/mobile/budget.svg"
import portfolio from "../../../photos/mobile/portfolio.svg"
import comm from "../../../photos/mobile/communication.svg"
import mobile1 from "../../../photos/mobile/mobile1.png"
import mobile2 from "../../../photos/mobile/mobile2.png"
import mobile3 from "../../../photos/mobile/mobile3.png"
import FrequentQuestions from "./FrequentQuestions";
import { Link } from 'react-router-dom';

const ServicesMobile = (props) => {
  const title = props.location.pathname
  const splitState = title.split('/');
  const staticTitles = {
    en: "Développement Mobile",
    fr: "Développement Mobile"
  };
  const { t } = props
  const [pages, setPages] = useState([]);
  const [langue, setLangue] = useState("fr");
   const currentLanguage = cookies.get('i18next') || 'fr'
  const [titre, setTitre] = useState(staticTitles[currentLanguage]);
  const [lastChecked, setLastChecked] = useState(null);

 

  const questions = [
    {
      question: "Combien coûte le développement d'une application mobile ?",
      answer: "Le coût du développement varie en fonction de la complexité de l'application, des fonctionnalités souhaitées et de la plateforme (iOS, Android ou les deux). Chez Oxton Digital, nous travaillons avec vous pour établir un budget qui correspond à vos besoins et à vos objectifs.",
    },
    {
      question: "Combien de temps faut-il pour développer une application mobile ?",
      answer: "Le délai de développement dépend également de la complexité du projet. En général, cela peut prendre de quelques semaines à plusieurs mois. Nous vous fournissons un calendrier détaillé dès le début pour que vous sachiez à quoi vous attendre.",
    },
    {
      question: "Quelles plateformes devrais-je cibler pour mon application ?",
      answer: `Cela dépend de votre public cible. Nous vous aidons à analyser les préférences de vos utilisateurs et à choisir entre iOS, Android ou une solution multiplateforme, afin d'atteindre le maximum d'utilisateurs.`,
    },
    {
      question: "Puis-je mettre à jour mon site moi-même après sa création ?",
      answer: "Oui, vous pouvez mettre à jour votre site vous-même après sa création. Nous utilisons des systèmes de gestion de contenu (CMS) faciles à utiliser, vous permettant de modifier du texte, des images et d’ajouter de nouveaux contenus sans compétences techniques.",
    },
    {
      question: "Comment assurez-vous la qualité et la sécurité de l'application ?",
      answer: `Nous suivons des processus rigoureux de test et de validation tout au long du développement. De plus, nous intégrons des mesures de sécurité robustes pour protéger les données de vos utilisateurs.`,
    },
    {
      question: "Puis-je apporter des modifications pendant le développement ?",
      answer: "Oui, nous comprenons que des ajustements peuvent être nécessaires. Nous travaillons en collaboration avec vous pour intégrer vos retours tout au long du processus de développement.",
    },
    {
      question: "Offrez-vous un support après le lancement de l'application ? ",
      answer: "Absolument ! Nous proposons des services de maintenance et de support continu pour garantir que votre application reste performante et à jour.",
    },
    {
      question: "Comment mon application sera-t-elle mise à jour ? ",
      answer: "Nous vous aidons à planifier des mises à jour régulières pour intégrer de nouvelles fonctionnalités, corriger des bugs ou répondre aux retours des utilisateurs.",
    },
    {
      question: "Puis-je intégrer des fonctionnalités spécifiques, comme des paiements ou des notifications push ? ",
      answer: "Oui, nous sommes en mesure d’intégrer une variété de fonctionnalités selon vos besoins, y compris les systèmes de paiement, les notifications push et bien d'autres options personnalisées.",
    },
  ];

  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])

  const cacheTitle = (result) => {
    const page = result.find(element =>
      decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1])
    );

    if (page) {
      const title = page.title.rendered.replace("&#8217;", "'");
      if (title !== titre) {
        setTitre(title);
      }
      setLastChecked(new Date().getTime()); 
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    const oneMonth = 30 * 24 * 60 * 60 * 1000;
    const lastCheckedTimestamp = lastChecked || 0;

    if (new Date().getTime() - lastCheckedTimestamp >= oneMonth) {
      fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
        .then(res => res.json())
        .then((result) => {
          setPages(result);
          cacheTitle(result);
        });
    }
  }, [lastChecked]);

  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description, element.date, element.excerpt.rendered)))



  return (<>
    <Helmet>
      <title>Applications Mobiles en 2024 : Augmentez Vos Ventes avec Oxton Digital </title>
      <meta name="description" content={page[0] !== undefined && page[0].description} />
      <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
    </Helmet>
    <div className="container" >
    <Breadcrumb>
        <p>
          <Link className="retour" to="/">{t('Home_link')}</Link> / <span>{titre}</span>
        </p>
      </Breadcrumb>
      <Title>
        <h1>{titre}</h1>
    
      </Title>
      
      <div className="cards-images-mobile-dev-page">
          <div className="rec-image-mobile rec-im-1">
            <img src={mobile3} className="img-fluid" alt="image-mobile1"/>
          </div>
          <div className="rec-image-mobile rec-im-2">
            <img src={mobile2} className="img-fluid" alt="image-mobile2"/>
          </div>
          <div className="rec-image-mobile rec-im-3">
            <img src={mobile1} className="img-fluid" alt="image-mobile3"/>
          </div>
        </div>
      <div className="importance-app-mobile-container">
        <div className="respect-container">
          <div className="respect-content">
            <h3>L'Importance des Applications Mobiles en 2024 : Investir pour l'Avenir </h3>
            <p className="title-p">En 2024, le paysage numérique continue d'évoluer à une vitesse fulgurante, et les applications mobiles occupent une place centrale dans cette transformation. Voici quelques statistiques marquantes qui soulignent l'importance d'investir dans une application mobile pour votre entreprise :</p>
          </div>
        </div>


        <div className="mobile-services">
          <div>
            <div className="d-flex mobile">
              <div>
                <div className="d-flex align-items-center gap-3 mobile-img-title">
                  <img src={use} className="" alt="Utilisation croissante" />
                  <h5>Utilisation croissante</h5>
                </div>
                <p className="title-p">Environ 80 % des utilisateurs de smartphones préfèrent interagir avec des marques via des applications plutôt que par des sites web. Cela démontre l'importance d'être accessible à travers des canaux qui répondent aux attentes de vos clients.</p>
              </div>
              <div>
                <div className="d-flex align-items-center gap-3 mobile-img-title">
                  <img src={engagement} className="" alt="Engagement renforcé" />
                  <h5>Engagement renforcé</h5>
                </div>
                <p className="title-p">Les applications mobiles génèrent 3 fois plus d'engagement que les sites web. Une application bien conçue peut fidéliser vos clients en leur offrant une expérience utilisateur fluide et intuitive.</p>
              </div>
            </div>
            <div className="d-flex mobile">
              <div>
                <div className="d-flex align-items-center gap-3 mobile-img-title">
                  <img src={augmentation} className="" alt="Augmentation des ventes" />
                  <h5>Augmentation des ventes</h5>
                </div>
                <p className="title-p">Les entreprises qui disposent d'une application mobile constatent une augmentation moyenne de 20 à 30 % de leurs ventes. Cela s'explique par la facilité d'accès et l'expérience personnalisée qu'une application peut offrir.</p>
              </div>
              <div>
                <div className="d-flex align-items-center gap-3 mobile-img-title">
                  <img src={notification} className="" alt="Notifications push" />
                  <h5>Notifications push</h5>
                </div>
                <p className="title-p">Les applications permettent d'envoyer des notifications push, qui augmentent l'engagement client de 88 %. Ces messages peuvent informer vos clients de promotions, de nouveautés ou de contenus pertinents, les incitant à revenir régulièrement.</p>
              </div>
            </div>
            <div className="mobile last-mobile">
              <div>
                <div className="d-flex align-items-center gap-3 mobile-img-title">
                  <img src={revolution} className="" alt="Révolution des secteurs" />
                  <h5>Révolution des secteurs</h5>
                </div>
                <p className="title-p">Les applications mobiles transforment divers secteurs, de l'e-commerce à la santé, en passant par l'éducation. Les entreprises qui ne s'adaptent pas à cette tendance risquent de perdre des parts de marché face à des concurrents plus innovants.</p>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="mobile-choisir-oxton">
      <div className="respect-container">
          <div className="respect-content">
            <h3>Choisir l’excellence pour le développement d’applications mobile : OXTON DIGITAL !</h3>
            <p className="title-p">Pour bien choisir votre partenaire pour la création d’une application mobile, voici les critères qui font de notre agence le choix idéal : </p>
          </div>
        </div>
        <div className="mobile-cards">
          <div className="mobile-card">
            <div className="mobile-card-icon">
              <img src={expertise} alt="Icon 1" />
            </div>
            <h3 className="mobile-card-title">Une équipe commerciale pour vous conseiller</h3>
            <p className="mobile-card-text">
              Notre équipe de consultants a une compréhension approfondie de la façon de créer de la valeur par la transformation numérique. Nous travaillons avec nos clients pour concevoir et mettre en œuvre des solutions commerciales personnalisées.
            </p>
          </div>

          <div className="mobile-card ">
            <div className="mobile-card-icon">
              <img src={reference} alt="Icon 2" />
            </div>
            <h3 className="mobile-card-title">Une équipe de chefs de projet pour le déploiement</h3>
            <p className="mobile-card-text">
              Nos chefs de projet expérimentés travaillent avec vous pour assurer le déploiement réussi de votre projet numérique. Nous vous aidons à définir la portée et les exigences, identifier et évaluer les risques.
            </p>
          </div>

          <div className="mobile-card">
            <div className="mobile-card-icon">
              <img src={budget} alt="Icon 3" />
            </div>
            <h3 className="mobile-card-title">Une équipe support pour vous assister</h3>
            <p className="mobile-card-text">
              Nous proposons également une gamme d'autres services de conseil, y compris des études de faisabilité, l'amélioration des processus, et la gestion du changement.
            </p>
          </div>
          <div className="mobile-card">
            <div className="mobile-card-icon">
              <img src={portfolio} alt="Icon 3" />
            </div>
            <h3 className="mobile-card-title">Une équipe support pour vous assister</h3>
            <p className="mobile-card-text">
              Nous proposons également une gamme d'autres services de conseil, y compris des études de faisabilité, l'amélioration des processus, et la gestion du changement.
            </p>
          </div>
          <div className="mobile-card">
            <div className="mobile-card-icon">
              <img src={comm} alt="Icon 3" />
            </div>
            <h3 className="mobile-card-title">Une équipe support pour vous assister</h3>
            <p className="mobile-card-text">
              Nous proposons également une gamme d'autres services de conseil, y compris des études de faisabilité, l'amélioration des processus, et la gestion du changement.
            </p>
          </div>
        </div>
     
      </div>
      <div className="questions-mobile">
      <div className="respect-container">
          <div className="respect-content ">
              <h3>FAQ sur le Développement d'Applications Mobiles </h3>
          </div>
        </div>
        <FrequentQuestions questions={questions} />
     
      </div>


    </div>
  </>
  )
}



export default withTranslation()(ServicesMobile)