import styled from "styled-components";
import React from "react";
import { Link } from 'react-router-dom';

const NotFound = () => {

  let titre =  "404 Not found"

  return (
    <div className="container-fluid" >
      <Error>
        <h1>Oops! That page can't be found</h1>
        <p>Il semble que nous ne trouvions pas vous cherchez.</p><br />
        <Link to={`/`} className="text-black text-decoration-none link">
          <button className="btn btn-light home">Retour à l'accueil</button>

        </Link>

      </Error>
      <Introuvable>
        <div>
          <ul>
            <li className="number">404</li>
            <li className="introuvable">PAGE INTROUVABLE</li>
            <li className="number">404 </li>
            <li className="introuvable">PAGE INTROUVABLE </li>
            <li className="number">404 </li>
            <li className="introuvable">PAGE INTROUVABLE </li>
            <li className="number">404 </li>
            <li className="introuvable">PAGE INTROUVABLE </li>
            <li className="number">404 </li>
            <li className="introuvable">PAGE INTROUVABLE </li>
            <li className="number">404 </li>
            <li className="introuvable">PAGE INTROUVABLE </li>
            <li className="number">404 </li>
            <li className="introuvable">PAGE INTROUVABLE </li>
            <li className="number">404 </li>
            <li className="introuvable">PAGE INTROUVABLE </li>
          </ul>
        </div>
      </Introuvable>
    </div>

  )
}

const Error = styled.div`
  padding: 150px 15% 30px 15%;
  text-align: center;
  @media (max-width:991px) {
    text-align:left;
  }
  >h1{
    color: rgb(32, 172, 227);
    font-family: 'RalewayRegular';
    font-weight: 600;
  }
  >p{
    padding-top: 30px;
    font-size: 20px;
    font-weight: 600;
  }
  >.link{
    >.home{
    background-color: #20ACE3;
    color:white;
    border-radius: 15px;
    width: 200px;
    }
  } 
`;

const Introuvable = styled.div`
  padding: 20px 90px 10px 40px;
  margin-top:50px;
  background-color:#17406F;
  color: white;
  z-index: 9;
  >div{
    overflow: hidden;
    >ul{
    display: flex;
    width: 120%;
    list-style-type: none;
    font-family: 'RalewayRegular';
    >li{
      padding-right: 20px;
    }
    letter-spacing: 1px;
    >li.number{
      color: #20ACE3;
    }
    >.introuvable{
      min-width: 290px;
    }
  }}


`;

export default NotFound
