import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FormControl, Input, InputLabel } from "@material-ui/core";
import { Title, WhatIs } from "../../../css/commun";
import { useForm } from "react-hook-form";
import { URL_Back, URL_Dev } from "../Variables";
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import Popup from "../../Popup";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";

const SubPostuler = (props) => {
  const title = props.offrePostuler.location.pathname;
  const splitState = title.split("/");
  const { t } = props;
  const [pages, setPages] = useState([]);
  const [existError, setExistError] = useState();
  const [buttonPopup, setButtonPopup] = useState(false);
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get("i18next") || "fr";

  useEffect(() => {
    setLangue(currentLanguage);
    document.body.dir = currentLanguage === "ar" ? "rtl" : "ltr";
  }, [currentLanguage]);

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then((res) => res.json())
      .then((result) => {
        setPages(result);
      });
  }, []);

  let page = [];
  pages.map(
    (element) =>
      decodeURI(element.slug) ===
        (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
      page.push(
        new Pages(
          element.id,
          element.title.rendered,
          element.content.rendered,
          element.parent,
          element.slug,
          element.yoast_head_json.og_description,
          element.date,
          element.excerpt.rendered
        )
      )
  );

  let titre =
    page[0] !== undefined
      ? page[0].title.replace("&#8217;", "'")
      : "...Loading";

  const list = props.offrePostuler.location.state;
  const handler = props.handler;
  const isloading = props.isloading;

  const initialValues = {
    yourname: "",
    yourlastname: "",
    youremail: "",
    yourphone: "",
    yourcv: [],
    yourlinkedin: "",
    yourmotivation: "",
  };

  const [formState, setFormState] = useState(initialValues);

  const letters = /^[A-Za-z]+$/;
  const validEmail = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,3}$/;
  const validLinkedin = new RegExp(
    "^https://www.linkedin.com/+[a-zA-Z0-9]+/[a-zA-Z0-9]"
  );
  const validNumber = /^[+]+[0-9]{10,16}$/;

  const {
    formState: { errors },
  } = useForm();
  const [fileName, setFileName] = useState("");


  const handleFieldChange = (field, e) => {
    !errors.yourname && (errors.yourname = {});
    !errors.yourlastname && (errors.yourlastname = {});
    !errors.youremail && (errors.youremail = {});
    !errors.yourphone && (errors.yourphone = {});
    !errors.yourcv && (errors.yourcv = {});
    !errors.yourlinkedin && (errors.yourlinkedin = {});
    !errors.yourmotivation && (errors.yourmotivation = {});

    setFormState({
      ...formState,
      [field]: e.target.value,
    });

    if (field === "yourname") {
      if (e.target.value.length < 2) {
        errors.yourname.message = "La longueur doit être de 2 ou plus";
        setExistError(false);
      } else if (!e.target.value.match(letters)) {
        errors.yourname.message = "Veuillez saisir uniquement des lettres";
        setExistError(false);
      } else {
        errors.yourname = {};
      }
    }

    if (field === "yourlastname") {
      if (e.target.value.length < 2) {
        errors.yourlastname.message = "La longueur doit être de 2 ou plus";
        setExistError(false);
      } else if (!e.target.value.match(letters)) {
        errors.yourlastname.message = "Veuillez saisir uniquement des lettres";
        setExistError(false);
      } else {
        errors.yourlastname = {};
      }
    }

    if (field === "youremail") {
      if (e.target.value.length < 5) {
        errors.youremail.message = "La longueur doit être de 5 ou plus";
        setExistError(false);
      } else if (!validEmail.test(e.target.value)) {
        errors.youremail.message = "L’adresse e-mail n’est pas valide.";
        setExistError(false);
      } else {
        errors.youremail = {};
      }
    }

    if (field === "yourphone") {
      if (e.target.value.length < 8) {
        errors.yourphone.message = "La longueur doit être de 8 ou plus";
        setExistError(false);
      } else if (!validNumber.test(e.target.value)) {
        errors.yourphone.message =
          "Veuillez saisir numéro de téléphone valide commençant par +";
        setExistError(false);
      } else {
        errors.yourphone = {};
      }
    }
    if (field === "yourcv") {
      const selectedFile = e.target.files[0];
      setFormState({ ...formState, [field]: selectedFile });
      setFileName(selectedFile.name); // Set the selected file name
    } else {
      setFormState({ ...formState, [field]: e.target.value });
    }

    if (field === "yourlinkedin") {
      if (e.target.value.length < 5) {
        errors.yourlinkedin.message = "La longueur doit être de 5 ou plus";
        setExistError(false);
      } else if (!validLinkedin.test(e.target.value)) {
        setExistError(false);
        errors.yourlinkedin.message = "Veuillez saisir un lien linkedin";
      } else {
        errors.yourlinkedin = {};
      }
    }

    if (field === "yourmotivation") {
      if (e.target.value.length < 50) {
        errors.yourmotivation.message = "La longueur doit être de 50 au moin";
        setExistError(false);
      } else {
        errors.yourmotivation = {};
      }
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    !errors.yourname && (errors.yourname = {});
    !errors.yourlastname && (errors.yourlastname = {});
    !errors.youremail && (errors.youremail = {});
    !errors.yourphone && (errors.yourphone = {});
    !errors.yourcv && (errors.yourcv = {});
    !errors.yourlinkedin && (errors.yourlinkedin = {});
    !errors.yourmotivation && (errors.yourmotivation = {});

    var verifError = true;

    if (!formState.yourname) {
      errors.yourname.message = "Ce champ est Obligatoire";
      verifError = false;
    } else if (formState.yourname.length < 2) {
      errors.yourname.message = "La longueur doit être de 2 ou plus";
      verifError = false;
    } else if (!formState.yourname.match(letters)) {
      errors.yourname.message = "Veuillez saisir uniquement des lettres";
      verifError = false;
    }

    if (!formState.yourlastname) {
      errors.yourlastname.message = "Ce champ est Obligatoire";
      verifError = false;
    } else if (formState.yourlastname.length < 2) {
      errors.yourlastname.message = "La longueur doit être de 2 ou plus";
      verifError = false;
    } else if (!formState.yourlastname.match(letters)) {
      errors.yourlastname.message = "Veuillez saisir uniquement des lettres";
      verifError = false;
    }

    if (!formState.youremail) {
      errors.youremail.message = "Ce champ est Obligatoire";
      verifError = false;
    } else if (formState.youremail.length < 5) {
      errors.youremail.message = "La longueur doit être de 5 ou plus";
      verifError = false;
    } else if (!validEmail.test(formState.youremail)) {
      errors.youremail.message = "L’adresse e-mail n’est pas valide.";
      verifError = false;
    }

    if (!formState.yourphone) {
      errors.yourphone.message = "Ce champ est Obligatoire";
      verifError = false;
    } else if (formState.yourphone.length < 8) {
      errors.yourphone.message = "La longueur doit être de 8 ou plus";
      verifError = false;
    } else if (!validNumber.test(formState.yourphone)) {
      errors.yourphone.message =
        "Veuillez saisir numéro de téléphone valide commençant par + ";
      verifError = false;
    }

    if (!formState.yourcv) {
      errors.yourcv.message = "Ce champ est Obligatoire";
    }
    if (!formState.yourlinkedin) {
      errors.yourlinkedin.message = "Ce champ est Obligatoire";
      verifError = false;
    } else if (formState.yourlinkedin.length < 5) {
      errors.yourlinkedin.message = "La longueur doit être de 5 ou plus";
      verifError = false;
    } else if (!validLinkedin.test(formState.yourlinkedin)) {
      verifError = false;
      errors.yourlinkedin.message = "Veuillez saisir un lien linkedin";
    }
    if (!formState.yourmotivation) {
      errors.yourmotivation.message = "Ce champ est Obligatoire";
      verifError = false;
    } else if (formState.yourmotivation.length < 49) {
      errors.yourmotivation.message = "La longueur doit être de 50 au moin";
      verifError = false;
    }

    console.log(errors);

    verifError && handler(e, formState);
    verifError && e.target.reset();
    setExistError(verifError);
  
    const formData = new FormData();
    formData.append('yourname', formState.yourname);
    formData.append('yourlastname', formState.yourlastname);
    formData.append('youremail', formState.youremail);
    formData.append('yourphone', formState.yourphone);
    formData.append('yourcv', formState.yourcv);
    formData.append('yourlinkedin', formState.yourlinkedin);
    formData.append('yourmotivation', formState.yourmotivation);
    
    try {
      const response = await fetch('/wp-json/custom/v1/upload', {
        method: 'POST',
        body: formData,
      });
    
      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          console.log('Form submitted successfully:', data.data.message);
          console.log('Submitted data:', data.data); // Afficher les données envoyées
          setButtonPopup(true); // Afficher la fenêtre contextuelle de succès
    
          // Vous pouvez également mettre à jour l'état de votre composant avec les données
          // pour les afficher dans l'interface utilisateur si nécessaire
          // setSubmittedData(data.data);
        } else {
          console.error('Form submission failed:', data.message);
        }
      } else {
        console.error('Error submitting form:', response.statusText);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }    

  return (
    <>
      <Helmet>
        <title>{titre}</title>
        <meta
          name="description"
          content={page[0] !== undefined && page[0].description}
        />
        <link
          rel="canonical"
          href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`}
        />
      </Helmet>

      <div className="container-fluid">
        <Title>
          <h1>{titre}</h1>
          <p>
            <img
              className={langue === "ar" && "img-accueil-ar"}
              src="/images/accueil.png"
              alt="accueil"
            />
            <a className="retour" href="/">
              {t("Home_link")}
            </a>{" "}
            / <span>{titre}</span>
          </p>
        </Title>

        {list ? (
          <div>
            <br />
            <br />
            <br />{" "}
          </div>
        ) : (
          <WhatIs className={langue === "ar" && "alignRightMobile"}>
            <h2 className={langue === "ar" && "alignRightMobile"}>
              {t("Postuler.Job_offers")}
            </h2>
            <p className="text">
              {t("Postuler.Job_offers_details")} <br />
              {t("Postuler.To_apply")}
            </p>
          </WhatIs>
        )}
        <TitlePostuler>
          {list !== undefined && <h2>{list.title}</h2>}
        </TitlePostuler>

        <Form>
          <h3 className={langue === "ar" && "alignRightMobile"}>
            {t("Postuler.Personal")}
          </h3>
          <form
            className="from"
            onSubmit={handleFormSubmit}
            method="POST"
            action="/recrutement"
            encType="mnultipart/form-data"
          >
            <div className="personelles">
              <FormControl
                className={
                  langue === "ar"
                    ? "formControleLeft formControleLeft-ar"
                    : "formControleLeft"
                }
              >
                <InputLabel
                  className={langue === "ar" ? "label label-ar" : "label"}
                >
                  {t("Postuler.Name")}
                </InputLabel>
                <Input
                  id="name"
                  name="name"
                  className="input"
                  disableUnderline
                  onChange={(e) => handleFieldChange("yourname", e)}
                />
                {errors.yourname && (
                  <small className="text-danger">
                    {errors.yourname.message}
                  </small>
                )}
              </FormControl>
              <FormControl
                className={
                  langue === "ar"
                    ? "formControleRight formControleRight-ar"
                    : "formControleRight"
                }
              >
                <InputLabel
                  className={langue === "ar" ? "label label-ar" : "label"}
                >
                  {t("Postuler.first_name")}
                </InputLabel>
                <Input
                  id="prenom"
                  className="input"
                  disableUnderline
                  onChange={(e) => handleFieldChange("yourlastname", e)}
                />
                {errors.yourlastname && (
                  <small className="text-danger">
                    {errors.yourlastname.message}
                  </small>
                )}
              </FormControl>
              <FormControl
                className={
                  langue === "ar"
                    ? "formControleLeft formControleLeft-ar"
                    : "formControleLeft"
                }
              >
                <InputLabel
                  className={langue === "ar" ? "label label-ar" : "label"}
                >
                  {t("Postuler.Email")}
                </InputLabel>
                <Input
                  id="email"
                  className="input"
                  disableUnderline
                  onChange={(e) => handleFieldChange("youremail", e)}
                />
                {errors.youremail && (
                  <small className="text-danger">
                    {errors.youremail.message}
                  </small>
                )}
              </FormControl>
              <FormControl
                className={
                  langue === "ar"
                    ? "formControleRight formControleRight-ar"
                    : "formControleRight"
                }
              >
                <InputLabel
                  className={langue === "ar" ? "label label-ar" : "label"}
                >
                  {t("Postuler.phone")}
                </InputLabel>
                <Input
                  type="text"
                  id="telephone"
                  className="input"
                  disableUnderline
                  onChange={(e) => handleFieldChange("yourphone", e)}
                />
                {errors.yourphone && (
                  <small className="text-danger">
                    {errors.yourphone.message}
                  </small>
                )}
              </FormControl>
            </div>
            <div className="project">
              <h3 className={langue === "ar" && "alignRightMobile"}>
                {t("Postuler.Professional")}
              </h3>
              <FormControl
                className={`formControleLeft ${
                  langue === "ar" ? "formControleLeft-ar" : ""
                }`}
              >
                <InputLabel
                  htmlFor="cv"
                  className={`label labelfile ${
                    langue === "ar" ? "label-ar" : ""
                  }`}
                >
                  {t("Postuler.CV")}
                </InputLabel>
                <div
                  className={`choose-file-container ${
                    langue === "ar" ? "choose-file-container-ar" : ""
                  }`}
                >
                  <label
                    htmlFor="fileInput"
                    className={`choose-file-text ${
                      langue === "ar" ? "choose-file-text-ar" : ""
                    }`}
                  >
                    <span className="chooseFile">{t("Postuler.ChooseFile")}</span> 
                    <input
                      id="fileInput"
                      type="file"
                      className={`input typefile ${
                        langue === "ar" ? "input-ar" : ""
                      }`}
                      disableUnderline
                      onChange={(e) => handleFieldChange("yourcv", e)}
                    />  {fileName && <p className="selected-file">{t("Postuler.SelectedFile")} : {fileName}</p>}
                  </label>
                </div>
                {errors.yourcv && (
                  <small className="text-danger">{errors.yourcv.message}</small>
                )}
              </FormControl>

              <FormControl
                className={
                  langue === "ar"
                    ? "formControleRight formControleRight-ar"
                    : "formControleRight"
                }
              >
                <InputLabel
                  className={langue === "ar" ? "label label-ar" : "label"}
                >
                  {t("Postuler.LinkedIn")}
                </InputLabel>
                <Input
                  id="profil"
                  className="input"
                  disableUnderline
                  onChange={(e) => handleFieldChange("yourlinkedin", e)}
                />
                {errors.yourlinkedin && (
                  <small className="text-danger">
                    {errors.yourlinkedin.message}
                  </small>
                )}
              </FormControl>
              <FormControl fullWidth>
                <InputLabel
                  className={langue === "ar" ? "label label-ar" : "label"}
                >
                  {t("Postuler.motivation")}
                </InputLabel>
                <Input
                  id="motivation"
                  className="input lettre"
                  disableUnderline
                  onChange={(e) => handleFieldChange("yourmotivation", e)}
                />
                {errors.yourmotivation && (
                  <small className="text-danger">
                    {errors.yourmotivation.message}
                  </small>
                )}
              </FormControl>
            </div>
            <button
              type="submit"
              className={
                langue === "ar"
                  ? "btn postuler button button-ar"
                  : "btn postuler button"
              }
              onClick={() => setButtonPopup(true)}
            >
              <span className="pseudo-text">{t("Send")}</span>
            </button>
            {existError && (
              <Popup
                trigger={buttonPopup}
                setTrigger={setButtonPopup}
                onBlur={() => setButtonPopup(false)}
              >
                <p>{t("Postuler.Thank")}</p>
              </Popup>
            )}
          </form>
        </Form>
      </div>
    </>
  );
};

const TitlePostuler = styled.div`
  > h2 {
    text-align: center;
    margin: 0 15% 80px 15%;
    @media (max-width: 991px) {
      margin: 0 10% 40px 10%;
      text-align: left;
    }
  }
`;

const Form = styled.div`
  padding: 0px 15% 0px 15%;
  font-size: 16px;
  @media (max-width: 991px) {
    margin-top: 10px;
    padding: 0px 10%;
  }
  @media (max-width: 500px) {
    margin-top: 30px;
  }
  text-align: center;
  color: white;
  background-image: linear-gradient(to right, #367ba7, #95cce5);
  h3 {
    padding-top: 70px;
    font-size: 32px !important;
    color: white;
    @media (max-width: 991px) {
      padding-top: 40px;
      font-size: 24px !important;
      text-align: left;
    }
  }
  .label {
    color: white;
    margin-top: 2px;
  }

  input:-webkit-autofill,
  .my-class:-webkit-autofill {
    transition-delay: 9999999s;
  }

  label.labelfile + div input[type="file"] {
    color: transparent !important;
  }
  label.labelfile.MuiFormLabel-filled + div input[type="file"] {
    color: white !important;
  }
  input[type="file"]::-webkit-file-upload-button {
    display: none;
    border: 0;
    color: #20ace3;
    padding: 4px 8px;
    border-radius: 10px;
    background-color: white;
    font-size: 14px;
    font-weight: 500;
    content: "AJOUTER" !important;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: -5px;
  }

  .typefile .MuiInputBase-input {
    width: calc(100% - 130px) !important;
  }

  .formControleRight {
    width: 44%;
    margin-left: 6%;
    padding-top: 30px;
    @media (max-width: 766px) {
      width: 100%;
      margin-left: 0;
    }
  }
  .formControleRight-ar {
    margin-left: 0;
    margin-right: 6%;
    @media (max-width: 766px) {
      margin-right: 0;
    }
  }
  .formControleLeft {
    width: 44%;
    margin-right: 6%;
    padding-top: 30px;
    @media (max-width: 766px) {
      width: 100%;
      margin-left: 0;
    }
  }
  .formControleLeft-ar {
    margin-left: 6%;
    margin-right: 0;
    @media (max-width: 766px) {
      margin-left: 0;
    }
  }

  .project {
    padding-top: 20px;
    > h2 {
      padding-top: 60px;
      @media (max-width: 991px) {
        padding-top: 40px;
      }
    }
  }

  .postuler {
    margin-top: 60px;
    margin-bottom: 60px;
    padding: 10px 40px;
    border-radius: 10px;
    color: #20ace3;
    background-color: white;
    font-family: PoppinsRegular;
  }
  .cv {
    position: absolute;
    width: 30px;
    height: 27px;
    right: 0;
    top: -18px;
    font-size: 12px;
    padding: 2px 40px;
  }
  .input {
    color: white;
    border-bottom: 2px solid white;
  }

  .lettre {
    padding-top: 30px;
  }
  .MuiFormLabel-root.Mui-focused {
    color: white;
  }

  .text-danger {
    position: absolute;
    height: 0px;
    top: 85px;
    font-size: 16px;
    font-weight: 600;
    color: #c51414b3 !important;
  }

  > form .merci {
    position: relative;
    bottom: 30px;
    color: #036203;
    margin-bottom: -30px;
  }
  /* Styles pour l'arabe */
  .choose-file-container-ar {
    aligncontent: space-evenly !important;
    justifycontent: space-around !important;
    alignitems: baseline !important;
    justifyitems: stretch !important;
    flexdirection: column !important;
  }

  .choose-file-text-ar {
    order: 1;
    margin-right: 8px; /* Marge à gauche du texte (il s'affiche à droite en arabe en raison du flex-direction: row-reverse) */
    font-size: 14px; /* Taille de police personnalisée */
    font-weight: 500; /* Poids de la police personnalisé */
    color: white; /* Couleur du texte personnalisée */
    cursor: pointer; /* Curseur de la souris indiquant un élément cliquable */
  }

  /* Styles par défaut pour l'autre langue (français ou anglais) */
  .choose-file-container {
    display: flex !important ;
    place-content: space-evenly space-around !important;
    place-items: baseline stretch !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    align-content: center !important;
    justify-content: flex-start !important;
    margin-top:16px;
  }

  .choose-file-text {
    order: 2;
    margin-left: 8px; /* Marge à droite du texte (il s'affiche à gauche par défaut) */
    display: flex;
    flex-direction: row-reverse; /* Couleur du texte personnalisée */
    cursor: pointer; /* Curseur de la souris indiquant un élément cliquable */
    
    
    
  }
  .chooseFile{
    background-color:white;
    padding: 2px;
    border-radius: 4px;
    color:#20ace3;
    white-space: nowrap; 
    font-size: 14px;
    padding: 4px 8px;
  }
  
  .label {
    display: flex;
    flex-direction: row-reverse;
  }
  .selected-file {
  position: absolute;
  bottom: -16px; /* Ajustez cette valeur pour le positionnement vertical */
  left: 0;
  right:0;
  font-size: 14px;
  color: #17406F;
}


  /* Styles personnalisés pour l'icône ou le texte "Choisir un fichier" */
  /* Vous pouvez ajouter des styles supplémentaires ici si nécessaire */

  /* Style pour masquer le texte par défaut "Aucun fichier choisi" dans l'input type="file" */
  .input[type="file"] {
    color: transparent;
  }
`;

export default withTranslation()(SubPostuler);
