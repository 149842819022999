import React, { useEffect } from 'react'
import NavBar from './Layout/NavBar';
import AllPages from './Layout/AllPages';
import Footer from './Layout/Footer';
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga"
import Routes from './routes';


const App = () => {

  useEffect(() => {
    const TRACKING_ID = "G-76VTJ6WD4Z"
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(location.pathname + window.location.search);
  }, [])

  return (
    <section id='Oxton'>
      {/* <AllPages /> */}
     
      <BrowserRouter>
        <NavBar />
        <Routes />
        <Footer />
      </BrowserRouter>
    </section>
  )
}
export default App 