import React, { useEffect, useState } from "react";
import styled from "styled-components"
import { Title } from "../../../css/commun";
import Offre from "../../../Model/Offre";
import { URL_Back, URL_Dev } from "../Variables";
import { Link } from 'react-router-dom';
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";

const DetailsOffres = (props) => {
  const { state } = props.location;
  const title = props.location.pathname
  const { t } = props
  const splitState = title.split('/')
  splitState[2] = splitState[2].replace(/[^a-zA-Z0-9.-]/g, "")
  const [offres, setOffres] = useState([])
  const [pages, setPages] = useState([]);
  const [langue, setLangue] = useState("fr");
  const [loading, setLoading] = useState('true')
  const currentLanguage = cookies.get('i18next') || 'fr'

  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])

  const scrollIntop = () => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);
  }

  useEffect(() => {

    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    fetch(`${URL_Back}/wp-json/wp/v2/offres?_embed&per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          var Offrelanguage = []
          currentLanguage === "fr"
            ? (result.map(element => { element.categories[0] === 68 && Offrelanguage.push(element) }))
            : currentLanguage === "en"
              ? (result.map(element => { element.categories[0] === 86 && Offrelanguage.push(element) }))
              : currentLanguage === "ar"
              && (result.map(element => { element.categories[0] === 84 && Offrelanguage.push(element) }))
          setOffres(Offrelanguage)
          setLoading(false)
        }
      )

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setPages(result)
        },
      )
  }, [])

  let offre = [];
  offres.map(element =>
    state !== undefined &&
      (splitState[3] !== element.slug && offre.push(new Offre(element.id, element.title.rendered, element.content.rendered, element.date, element.excerpt.rendered, element.slug))
  ))

  let offrepage = [];
  offres.map(element =>
    (splitState[1].length === 2 ? splitState[3] : splitState[2]) === element.slug &&
    offrepage.push(new Offre(element.id, element.title.rendered, element.content.rendered, element.date, element.excerpt.rendered))
  )

  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? decodeURI(splitState[2]) : decodeURI(splitState[1])) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description, element.date, element.excerpt.rendered)))
  let titre = offrepage[0] !== undefined ? offrepage[0].title.replaceAll("&#8217;", "'") : "...Loading"

  return (<>
    <Helmet>
      <title>{titre}</title>
      <meta name="description" content={page[0] !== undefined && page[0].description} />
      <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
    </Helmet>
    <div className="container-fluid" >
      <Title>
        <h1>{titre}</h1>
        <p>
          <img className={langue === "ar" && "img-accueil-ar"} src="/images/accueil.png" alt="accueil" />
          <Link className="retour" to="/">{t('Home_link')}</Link>/ <Link className="retour" to={langue === "fr" ? "/fr/nos-offres-demplois" : langue === "en" ? "/en/our-job-offers" : "/ar/تفاصيل-عروض-العمل"}>{t('Offers_link')}</Link> / <span>{titre}</span>
        </p>
      </Title>
      <TitleOfOffres>
        <h2>{state !== undefined ? state.title : offrepage[0] !== undefined && offre[0].title}</h2>
      </TitleOfOffres>
      <Descriptif>
        {state !== undefined ? <div className={langue === "ar" && "div-ar"} dangerouslySetInnerHTML={{ __html: state.content }} />
          : offrepage[0] !== undefined && <div dangerouslySetInnerHTML={{ __html: offrepage[0].content }} />
        }
        <div className="buttonPostuler">
          <Link className="link" to={{ pathname: `/recrutement`, state: state }} ><button className={langue === "ar"? "btn btn-primary button button-ar" : "btn btn-primary button"}><span className="pseudo-text">{t('DetailsOffres.Apply')}</span></button></Link>
        </div>
      </Descriptif>
      <LesOffres>
        <h2 className={langue === "ar" && "alignRightMobile"}>{t('offers')}</h2>
        <div className="row gy-2 offre">
          {offre.map((item) => (
            <div className="col-md-4" key={item.id}>
              <Link onClick={() => scrollIntop()} to={{ pathname: langue === "fr" ? `/${langue}/details-de-loffres-demplois/${item.slug}` : `/${langue}/details-of-the-job-offer/${item.slug})}`, state: item }} className="text-black text-decoration-none link">
                <div className="card">
                  <div className="cercleOffre" />
                  <h3 className="card-header">{item.title}</h3>
                  <div className="card-body">
                    <div dangerouslySetInnerHTML={{ __html: item.excerpt.slice(3, -5) }} />
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </LesOffres>
    </div >
  </>)
}

const TitleOfOffres = styled.div` 
  margin: 30px 15px 0px 15px;
  text-align:center;
  @media (max-width:991px) {
    margin: 30px 10%;
  }
  >ul {
    display: flex;
    list-style-type: none;
    justify-content: center;
    >.text{
    padding-left:5px;
    padding-right: 40px;
    }
  }
`;

const Descriptif = styled.div`
  margin: 30px 15% 0px 15%;
  @media (max-width:991px) and (min-width: 675px) {
    margin: 30px 10%;
  }
  @media (max-width:674px) {
    margin: 30px 0%;
  }
  >div {
    >div {
    margin-top: 60px;
    padding: 50px 50px 0px ;
    background-color: #ECF6FB;
    border-top-left-radius:15px;
    border-top-right-radius:15px;
    color: #17406F;
    >ul{
      list-style-image:url('/images/Offres/Group 38862.svg');
      line-height: 28px;
      margin-bottom: 26px;
      @media (min-width: 992px) {
        padding: 0 50px;
      }
      >li{
        padding-left: 10px;
        list-style-position : outside;
      }
    }
    >.lastOne{
        margin-bottom:0;
      }
  }
    >ul{
      display: flex;
      list-style-type: none !important;
      justify-content: center;
      padding-left: 0px;
        >label{
        padding-left:5px;
        padding-right: 40px;
        @media (max-width: 605px) and (min-width:490px){
          padding-right:30px;
        }
        @media (max-width:489px) {
          padding-right: 10px;
        }
        }
      }
      h3{
      font-size: 22px;
      margin-bottom: 16px;
      }
      >.lastOne{
        margin-bottom:0;
      }
      
    }
  >.div-ar{
    >div >ul{
      list-style-image:url('/images/icons/arrow left.svg');
    }

  }
  >.buttonPostuler{
    background-color:#ECF6FB;
    padding:15px 0 30px 0;
    border-bottom-left-radius:15px;
    border-bottom-right-radius:15px;
    text-align: center;
    >.link{
      text-decoration: none;
      >button{
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
 }
`;

const LesOffres = styled.div`
 padding: 0px 10% 40px 10%;
 @media (max-width: 640px) {
   >h2{
   text-align: left !important;
  }
 }
  >h2{
    text-align: center;
    padding: 70px 0 50px 0;
  }
  >.row{
    justify-content: center;
    padding: 0;
    font-weight: 600;
    >div{
      padding-bottom: 20px;
      @media (max-width:725px) {
        width: 100%;
      }
      @media (max-width:1091px) and (min-width:726px) {
        width: 50%;
      }
      >.link .card{
        padding:20px 20px 0 20px;
        border: 0;
        border-radius: 10px;
        box-shadow: 0px 0px 8px rgba(23, 64, 111, .20);
        overflow: hidden;
        height: 100%;
        >.cercleOffre{
        position: absolute;
        top: -14px;
        left:-15px;
        width: 90px;
        height: 90px;
        background-color: #ecf6fb;
        border-radius: 50%;
        z-index: 0;
        }
        >.card-header {
          height: 100%;
          background-color: transparent;
          border-bottom: 0;
          padding-left: 0;
        }
        > h3{
          z-index: 1;
          font-family:PoppinsBold;
        }
        >.card-body {
          padding: 0px 0 20px;
          margin: 0;
          word-spacing: 10px;
          color:#20ACE3;
          z-index: 1;
        }
      }
    }
  }
`;

export default withTranslation()(DetailsOffres)