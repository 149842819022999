import React from 'react'
import { withTranslation } from "react-i18next";

const Netlinking = (props) => {

  const { t } = props
  const starter = props.starter;
  const complet = props.complet;
  const expert = props.expert;
  const langue = props.langue;

  return (<div className="row gy-3">
    <div className="col-md-4">
      <div className="card">
        <ul className={langue === "ar" && "ul-ar"}>
          <div>
            <span className="euroextendedbold">{t("Pack_Starter")}</span><br />
            <span className="number"> {starter} <sup>€</sup></span> / {t("Month")}
          </div>
          <li><h3>1 {t("link")}</h3></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Netlinking.Netlinking_campaign')} Starter</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t("Netlinking.themed_spots")}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t("optimized_content")}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t("Netlinking.setting_up")}</span></li>
        </ul>
      </div>
    </div>

    <div className="col-md-4">
      <div className="card">
        <ul className={langue === "ar" && "ul-ar"}>
          <div>
            <span className="euroextendedbold">{t("Pack_Complet")}</span><br />
            <span className="number"> {complet} <sup>€</sup></span> / {t("Month")}
          </div>
          <li><h3>2 {t("links")}</h3></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Netlinking.Netlinking_campaign')} Complet</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t("Netlinking.themed_spots")}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t("optimized_content")}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t("Netlinking.setting_up")}</span></li>
        </ul>
      </div>
    </div>

    <div className="col-md-4">
      <div className="card">
        <ul className={langue === "ar" && "ul-ar"}>
          <div>
            <span className="euroextendedbold">{t("Pack_Expert")}</span><br />
            <span className="number"> {expert} <sup>€</sup></span> / {t("Month")}
          </div>
          <li><h3>4 {t("links")}</h3></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Netlinking.Netlinking_campaign')} Expert</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t("Netlinking.themed_spots")}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t("optimized_content")}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t("Netlinking.setting_up")}</span></li>
        </ul>
      </div>
    </div>
  </div>
  );
}

export default withTranslation()(Netlinking);

