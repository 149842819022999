import { useEffect, useState } from 'react'

export enum Phase {
    Typing,
    Pausing,
    Deleting,
}

const TYPING_INTERVAL = 250
const PAUSE_MS = 1000
const DELETING_INTERVAL = 250

export const useTypedCountry = (Countries) => {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [phase, setPhase] = useState(Phase.Typing)
    const [typedCountry, setTypedCountry] = useState('');
    useEffect(() => {
        switch (phase) {
            case (Phase.Typing):
                {
                    const nextTypedCountry = Countries[selectedIndex].slice(
                        0,
                        typedCountry.length + 1)

                    if (nextTypedCountry === typedCountry) {
                        setPhase(Phase.Pausing)
                        return
                    }

                    const timeout = setTimeout(() => {
                        setTypedCountry(nextTypedCountry)
                    }, TYPING_INTERVAL)

                    return () => clearTimeout(timeout)
                }
            case (Phase.Deleting):
                {
                    if (!typedCountry) {
                        const nextIndex = selectedIndex + 1
                        setSelectedIndex(Countries[nextIndex] ? nextIndex : 0)
                        setPhase(Phase.Typing)
                        return
                    }

                    const nextRemaining = Countries[selectedIndex].slice(
                        0,
                        typedCountry.length - 1)

                    const timeout = setTimeout(() => {
                        setTypedCountry(nextRemaining)
                    }, DELETING_INTERVAL)

                    return () => clearTimeout(timeout)
                }
            case (Phase.Pausing):
            default:
                const timeout = setTimeout(() => {
                    setPhase(Phase.Deleting)
                }, PAUSE_MS)
                return () => clearTimeout(timeout)

        }

    }, [Countries, typedCountry, selectedIndex, phase])

    return typedCountry
}


