import React, { useEffect, useState } from "react";
import styled from "styled-components"
import { FormControl, Input, InputLabel } from "@material-ui/core";
import { WhatIs, Title } from "../../../../css/commun";
import { NosServices, Background } from "../../Home";
import { Link } from 'react-router-dom';
import { URL_Back, URL_Dev } from "../../Variables";
import Pages from "../../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import Popup from "../../../Popup";
import Standard from "./List/Standard";
import Netlinking from "./List/Netlinking";
import Blogging from "./List/Blogging";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";


const FormulesSeo = (props) => {
    const title = props.location.pathname
    const splitState = title.split('/')
    const { t } = props
  
  let handler = props.handler;
  const [pages, setPages] = useState([]);
  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(false);
  const [existError, setExistError] = useState();
  const [buttonPopup, setButtonPopup] = useState(false);
  const [activePack, setActivePack] = useState(1)
  const [activePeriod, setActivePeriod] = useState(1)
  const [starter, setStarter] = useState(440);
  const [complet, setComplet] = useState(1140);
  const [expert, setExpert] = useState(1799)
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'

  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])


 
  useEffect(() => {

    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setPages(result)
        },
      )
  }, [])


  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.description, element.date, element.excerpt.rendered)))

  let titre = page[0] !== undefined ? page[0].title.replace("&#8217;", "'") : "...Loading"

  return (<>
    <Helmet>
      <title>{titre}</title>
      <meta name="description" content={page[0] !== undefined && page[0].description} />
      <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
    </Helmet>
    <div className="container" >
 
      <Title>

        <h1>{titre}</h1>
        {/* <p>
          <img className={langue === "ar" && "img-accueil-ar"} src="/images/accueil.png" alt="accueil" />
          <Link className="retour" to="/">{t('Home_link')}</Link> / <span>{titre}</span>
        </p> */}
        
        
      </Title>

      <Forfaits id="section">
        <div className="ListButtonPack">
          <button className={activePack === 1 ? "btn btn-light active" : "btn btn-light"} onClick={() => { setActivePack(1); setStarter(activePeriod === 1 ? 440 : 380); setComplet(activePeriod === 1 ? 1140 : 950); setExpert(activePeriod === 1 ? 1799 : 1499); }}>{t('Standard_title')}</button>
          <button className={activePack === 2 ? "btn btn-light active" : "btn btn-light"} onClick={() => { setActivePack(2); setStarter(activePeriod === 1 ? 139 : 120); setComplet(activePeriod === 1 ? 239 : 209); setExpert(activePeriod === 1 ? 449 : 420); }}>{t('Netlinking_title')}</button>
          <button className={activePack === 3 ? "btn btn-light active" : "btn btn-light"} onClick={() => { setActivePack(3); setStarter(activePeriod === 1 ? 129 : 109); setComplet(activePeriod === 1 ? 229 : 180); setExpert(activePeriod === 1 ? 360 : 320); }}>{t('Blogging_title')}</button>
        </div>
        <div className="ListButtonPeriod">
          <button className={activePeriod === 1 ? "btn btn-light active" : "btn btn-light"} onClick={() => { setActivePeriod(1); setStarter(activePack === 1 ? 440 : activePack === 2 ? 139 : 129); setComplet(activePack === 1 ? 1140 : activePack === 2 ? 239 : 209); setExpert(activePack === 1 ? 1799 : activePack === 2 ? 449 : 360); }}>{t('SUBSCRIPTION')} 6 {t('MONTH')}</button>
          <button className={activePeriod === 2 ? "btn btn-light active" : "btn btn-light"} onClick={() => { setActivePeriod(2); setStarter(activePack === 1 ? 380 : activePack === 2 ? 120 : 109); setComplet(activePack === 1 ? 950 : activePack === 2 ? 229 : 180); setExpert(activePack === 1 ? 1499 : activePack === 2 ? 420 : 320); }}>{t('SUBSCRIPTION')} 12 {t('MONTH')}</button>
        </div>
        {activePack === 1 && <Standard starter={starter} complet={complet} expert={expert} langue={langue} />}
        {activePack === 2 && <Netlinking starter={starter} complet={complet} expert={expert} langue={langue} />}
        {activePack === 3 && <Blogging starter={starter} complet={complet} expert={expert} langue={langue} />}

      </Forfaits>
    </div>
  </>)
}

export const AutreServices = styled.div`
  padding: 60px 15%;
  @media (max-width:991px) {
   padding: 60px 10%;
  }
  background-color: #EEF7FB;
  >h2{
    text-align:center;
    padding-bottom: 30px;
    margin-bottom: 0;
    @media (max-width: 991px) {
      padding-left: 30px;
    }
  }
  >.row{
    >.col-md-4{
      @media (max-width: 1250px) {
        width: 100%;
      }
      >.link{
        text-decoration: none;
        >a{
          color:red;
        }
        >.card{
          border-radius:15px;
          border: 0;
          background-color: transparent;
          padding:30px;
  
          >.card-header{
            background-color: transparent;
            border: 0;
            padding: 0;
            >hr{
              width:113px;
              color: #20ACE3;
              height: 2px;
            }
          }
          >.card-body{
            padding: 0;
            margin: 0;
            color: #17406F;;
          }
          :hover{
            background-color: white;
            box-shadow: 0px 0px 8px rgba(23, 64, 111, .20);
          }
        }
      }
    }
  }
`;

const Forfaits = styled.div`
  padding: 15px 0px;
  @media (max-width:991px) {
   padding: 15px 0px;
  }
   @media (max-width:575px) {
   padding: 0px 20px;
  }
  >h2{
    text-align: center;
    padding-bottom: 60px;
  }
  >.ListButtonPack{
    background-color: #20ACE3;
    margin-bottom: 40px ;
    border-radius: 6px;
    font-family: 'RalewayRegular';
    padding: 4px;
    @media (max-width: 577px) {
      display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 25px;
    }
    >.btn-light{
      width: 33.33%;
      color: white;
      background-color: transparent;
      border: 0;
      transition: none !important;
      @media (max-width: 577px) {
      width: 50%;
      height: 54px;
      line-height: 17px;
        font-size: 14px;  //loula
      }
    }
    >.active{
      background-color: white;
      color: #20ACE3;
      font-weight: 500;
      border: 4px solid #20ACE3;
      border-radius: 6px !important;
    }
    >.btn-light.active:focus{
      box-shadow: none;
    }
    >.btn-light:focus-visible{
      outline: none;
      border: transparent;
    }
    >.btn-light:focus{
      box-shadow: none;
      border: 4px solid #20ACE3;
    }
    .btn-light:active{
      border: 4px solid #20ACE3;
    }

  }
  >.ListButtonPeriod{
    margin-bottom: 40px ;
    text-align: center;
    font-family: 'RalewayRegular';
    font-size: 18px;
    @media (max-width: 577px) {
      display: flex;
      flex-direction: row;
      margin-bottom: 25px;
    }
    >.btn-light{
      color: #17406F;
      background-color: transparent;
      padding-right: 50px !important;
      padding-left: 50px !important;
      border: 0px solid transparent;
      transition: none !important;
      @media (max-width: 676px) {
        padding-right: 40px !important;
        padding-left: 40px !important;
      }
      @media (max-width: 626px) {
        padding-right: 30px !important;
        padding-left: 30px !important;
      }
      @media (max-width: 577px) {
        width: 50%;
        font-size: 14px;  //thenya
      .btn-light:last-of-type {
          width: 100%;
}
      }
    }
    >.active{
      background-color: white;
      color: #17406F;
      font-weight: 500;
      border: 1px solid #20ACE3;
      border-radius: 4px !important;
    }
    >.btn-light.active:focus{
      box-shadow: none;
    }
    >.btn-light:focus-visible{
      outline: none;
      border: transparent;
    }
    >.btn-light:focus{
      box-shadow: none;
      border: 1px solid #20ACE3;
    }
    .btn-light:active{
      border: 1px solid #20ACE3;
    }

  }
  >.row {
    justify-content: center;
    .col-md-4 {
      @media (max-width: 1051px) {
      width: 50%;
    }
    @media (max-width: 663px) {
      width: 100%;
    }
    justify-content: center;
    
    .card{
      box-shadow: 0px 0px 6px rgba(32, 172, 227, .16);
      padding: 30px;
      height: 100%;
      border-radius: 15px;
      @media(max-width: 767px){
        padding:25px;
      }
      >ul{
        list-style: none;
        padding-left: 0;
        >li{
          line-height: 28px;
          font-size: 14px;
          gap: 12px;
          display: flex;
          >h3{
            font-family: 'RalewayRegular';
            font-weight: 600;
            line-height: 28px !important;
            margin-bottom: 0;
            font-size: 14px !important;
          }
          >.blue{
            color :#20ACE3;
          }
        }
        >div{
          background-color: #EEF7FB;
          padding-top: 20px;
          margin-bottom: 20px;
          border-radius: 15px;
          font-weight: 600;
          font-family: 'RalewayRegular';
          color :#20ACE3;
          text-align: center;
          >.euroextendedbold{
            color: #17406F;
            font-weight: 700;
          }
          .number{
            font-size: 42px;
            >sup{
              font-size: 16px;
              position: relative;
              top: -25px;
              margin-left: -12px;
            }
          }
        }
      }
      >.ul-ar{
        padding-right: 0;
      }
      >div{
        display: flex;
        flex-direction: column;
        align-items: center;
        place-content: flex-end;
        height: 100%;

        >p{
          padding: 12px 19px;
          text-align: center;
          border-radius: 6px;
          color: #17406F;
          display:flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
              gap: 5px;
          background-color: #EEF7FB;
          font-weight: bold;
          font-family: 'RalewayRegular';
              margin-bottom: 0;
          >span{
        
          }
        } 
        >.button{
          padding: 10px 20px !important;
          color: white;
          width: 150px;
          border-radius:10px !important;
        }
      }
    }
  }
}
`;


export default withTranslation()(FormulesSeo)