import React, { useState, useEffect } from "react";
import Cf7FormWrapper from "./Cf7FormWrapper";
import SubDevis from "./SubDevis";

const Contact = (props) => {
  return (
    <Cf7FormWrapper id="1521">
      <SubDevis subprops={props}/>
    </Cf7FormWrapper>
  )
}

export default Contact;