import React from 'react'
import { withTranslation } from "react-i18next";

const Standard = (props) => {

  const { t } = props
  const starter = props.starter;
  const complet = props.complet;
  const expert = props.expert;
  const langue = props.langue;

  return (<div className="row gy-3">
    <div className="col-md-4">
      <div className="card">
        <ul className={langue === "ar" && "ul-ar"}>
          <div>
            <span className="euroextendedbold">{t("Pack_Starter")}</span><br />
            <span className="number"> {starter} <sup>€</sup></span> / {t("Month")}
          </div>
          <li><h3>{t('Starting')}</h3></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Kick-off_meeting')} </span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.objectives')} </span></li>
          <li><img src="/images/seo/Groupe de masques 786.svg" alt="X" /><span> {t('Standard.tools')} </span></li>
          <li><img src="/images/seo/Groupe de masques 786.svg" alt="X" /><span> {t('Standard.Search_Console')} </span></li>
          <hr />
          <li><h3>{t('Strategy')} {t('SEO')}</h3></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.Keyword')}</span></li>
          <li><img src="/images/seo/Groupe de masques 786.svg" alt="X" /><span> {t('Standard.Technical_audit')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.Competitive_intelligence')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.Status_of_netlinking')}</span></li>
          <hr />
          <li><h3>{t('Standard.On-site_optimization')} </h3> </li>
          <li><img src="/images/seo/Groupe de masques 786.svg" alt="X" /><span> {t('Standard.Page_structure')} </span></li>
          <li><img src="/images/seo/Groupe de masques 786.svg" alt="X" /><span> {t('Standard.Metadata')} </span></li>
          <li><img src="/images/seo/Groupe de masques 786.svg" alt="X" /><span> {t('Internal_meshing')} </span></li>
          <hr />
          <li><h3>{t('Standard.Off-site_optimization')} </h3> </li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.Starter_netlinking')} </span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.themed_spots')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('optimized_content')} </span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.setting_up')} </span></li>
          <hr />
          <li><h3>{t('Standard.Content_marketing')} </h3> </li>
          <li>0 {t('Standard.per_month')} </li>
          <li>1 {t('Standard.Blog_posts')} </li>
          <hr />
          <li><h3>{t('Standard.Balance_sheets')}</h3> </li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.Report')} </span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.Telephone_review')} </span></li>
        </ul>
        <div>
          <p> {t('Set_up')}<span> 300 €</span></p>
          {/* <button className="btn button"><span className="pseudo-text">En Profitez</span></button> */}
        </div>
      </div>
    </div>

    <div className="col-md-4">
      <div className="card">
        <ul className={langue === "ar" && "ul-ar"}>
          <div>
            <span className="euroextendedbold">{t("Pack_Complet")}</span><br />
            <span className="number"> {complet} <sup>€</sup></span> / {t("Month")}
          </div>
          <li><h3>{t('Starting')}</h3></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Kick-off_meeting')} </span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.objectives')} </span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.tools')} </span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.Search_Console')} </span></li>
          <hr />
          <li><h3>{t('Strategy')} {t('SEO')}</h3></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.Keyword')} </span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.Technical_audit')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.Competitive_intelligence')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.Status_of_netlinking')}</span></li>
          <hr />
          <li><h3>{t('Standard.On-site_optimization')} </h3> </li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.Page_structure')} </span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.Metadata')} </span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Internal_meshing')} </span></li>
          <hr />
          <li><h3>{t('Standard.Off-site_optimization')} </h3> </li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.Complet_netlinking')} </span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.themed_spots')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('optimized_content')} </span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.setting_up')} </span></li>
          <hr />
          <li><h3>{t('Standard.Content_marketing')} </h3> </li>
          <li>1 {t('Standard.per_month')} </li>
          <li>1 {t('Standard.Blog_posts')} </li>
          <hr />
          <li><h3>{t('Standard.Balance_sheets')}</h3> </li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.Report')} </span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.Telephone_review')} </span></li>
        </ul>
        <div>
          <p> {t('Set_up')}<span> 300 €</span></p>
          {/* <button className="btn button"><span className="pseudo-text">En Profitez</span></button> */}
        </div>
      </div>
    </div>

    <div className="col-md-4">
      <div className="card">
        <ul className={langue === "ar" && "ul-ar"}>
          <div>
            <span className="euroextendedbold">{t("Pack_Expert")}</span><br />
            <span className="number"> {expert} <sup>€</sup></span> / {t("Month")}
          </div>
          <li><h3>{t('Starting')}</h3></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Kick-off_meeting')} </span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.objectives')} </span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.tools')} </span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.Search_Console')} </span></li>
          <hr />
          <li><h3>{t('Strategy')} {t('SEO')}</h3></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.Keyword')} </span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.Technical_audit')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.Competitive_intelligence')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.Status_of_netlinking')}</span></li>
          <hr />
          <li><h3>{t('Standard.On-site_optimization')} </h3> </li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.Page_structure')} </span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.Metadata')} </span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Internal_meshing')} </span></li>
          <hr />
          <li><h3>{t('Standard.Off-site_optimization')} </h3> </li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.Expert_netlinking')} </span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.themed_spots')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('optimized_content')} </span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.setting_up')} </span></li>
          <hr />
          <li><h3>{t('Standard.Content_marketing')} </h3> </li>
          <li>2 {t('Standard.per_month')} </li>
          <li>2 {t('Standard.Blog_posts')} </li>
          <hr />
          <li><h3>{t('Standard.Balance_sheets')} </h3> </li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.Report')} </span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /><span> {t('Standard.Telephone_review')} </span></li>
        </ul>
        <div>
          <p> {t('Set_up')}<span> 300 €</span></p>
        </div>
      </div>
    </div>
  </div>
  );
}

export default withTranslation()(Standard);

