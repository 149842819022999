import React, { useState } from "react";
import './QuestionsHome.css'

const QuestionsHome = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleQuestion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    const PlusIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M18 34.2C26.947 34.2 34.2 26.9472 34.2 18C34.2 9.053 26.9472 1.79996 18 1.79996C9.053 1.79996 1.79996 9.053 1.79996 18C1.79996 26.947 9.053 34.2 18 34.2ZM18 36C27.941 36 36 27.941 36 18C36 8.0588 27.941 0 18 0C8.0588 0 0 8.0588 0 18C0 27.941 8.0588 36 18 36Z" fill="#20ACE3" />
            <path fillRule="evenodd" clipRule="evenodd" d="M25.197 17.9996C25.197 18.4965 24.7939 18.8996 24.297 18.8996H11.6969C11.1998 18.8996 10.7969 18.4965 10.7969 17.9996C10.7969 17.5027 11.1998 17.0996 11.6969 17.0996H24.2968C24.7939 17.0996 25.197 17.5027 25.197 17.9996Z" fill="#20ACE3" />
            <path fillRule="evenodd" clipRule="evenodd" d="M18.0094 25.1994C17.5124 25.1994 17.1094 24.7963 17.1094 24.2994V11.6993C17.1094 11.2022 17.5124 10.7993 18.0094 10.7993C18.5063 10.7993 18.9093 11.2022 18.9093 11.6993V24.2992C18.9093 24.7963 18.5063 25.1994 18.0094 25.1994Z" fill="#20ACE3" />
        </svg>
    );
    const MinusIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M18 34.2C26.947 34.2 34.2 26.9472 34.2 18C34.2 9.053 26.9472 1.79996 18 1.79996C9.053 1.79996 1.79996 9.053 1.79996 18C1.79996 26.947 9.053 34.2 18 34.2ZM18 36C27.941 36 36 27.941 36 18C36 8.0588 27.941 0 18 0C8.0588 0 0 8.0588 0 18C0 27.941 8.0588 36 18 36Z" fill="#20ACE3" />
            <path fillRule="evenodd" clipRule="evenodd" d="M25.197 17.9996C25.197 18.4965 24.7939 18.8996 24.297 18.8996H11.6969C11.1998 18.8996 10.7969 18.4965 10.7969 17.9996C10.7969 17.5027 11.1998 17.0996 11.6969 17.0996H24.2968C24.7939 17.0996 25.197 17.5027 25.197 17.9996Z" fill="#20ACE3" />
        </svg>

    );
    const questions = [
        {
          question: "Expertise Spécialisée ",
          answer: "Oxton Digital est une agence spécialisée qui comprend les enjeux uniques du marketing numérique. Avec une équipe d'experts en référencement, webmarketing, et développement web, nous disposons des compétences nécessaires pour concevoir des solutions adaptées à vos besoins spécifiques.",
        },
        {
          question: "Approche Personnalisée ",
          answer: "Nous croyons fermement que chaque client est unique. Notre approche commence par une compréhension approfondie de votre entreprise, de votre marché et de vos objectifs. Nous élaborons ensuite une stratégie digitale sur mesure qui vise à atteindre vos résultats de manière efficace et mesurable. ",
        },
        {
          question: "Réputation et Références ",
          answer: `Avec des témoignages de clients satisfaits et un portefeuille diversifié, Oxton Digital a prouvé sa capacité à délivrer des résultats concrets. Notre notoriété dans le secteur est renforcée par des avis positifs, ce qui témoigne de notre engagement envers la qualité et la satisfaction client. `,
        },
        {
          question: "Solutions Complètes",
          answer: "Nous offrons un large éventail de services, allant de la création de sites web à la gestion des réseaux sociaux, en passant par le SEO et le SEA. Cette diversité nous permet de vous accompagner à chaque étape de votre parcours numérique, en garantissant une intégration fluide de toutes vos initiatives digitales. ",
        },
        {
          question: " Suivi et Optimisation ",
          answer: `Notre engagement ne s'arrête pas à la mise en place d'une stratégie. Nous croyons en l'importance du suivi des résultats et de l'optimisation continue. Grâce à des outils d'analyse avancés, nous mesurons l'efficacité de chaque campagne et apportons des ajustements pour maximiser votre retour sur investissement. `,
        },
        {
          question: "Engagement Responsable ",
          answer: "Oxton Digital adopte une démarche responsable et durable dans toutes ses actions. Nous sommes convaincus que le succès numérique peut aller de pair avec un impact positif sur la société et l'environnement. En choisissant Oxton, vous optez pour une agence qui valorise l'éthique et la responsabilité sociale. ",
        },
        {
          question: "Proximité et Collaboration  ",
          answer: "Nous valorisons la collaboration et la proximité avec nos clients. En travaillant main dans la main, nous créons une relation de confiance qui favorise la réussite de vos projets. Notre équipe est toujours à l'écoute et réactive, prête à s'adapter à vos besoins changeants. ",
        },
        {
          question: "Technologie et Innovation ",
          answer: "Oxton Digital reste à la pointe de l'innovation. Nous intégrons les dernières technologies et tendances du secteur pour vous offrir des solutions modernes et efficaces. En 2024, nous exploitons des outils avancés tels que l’intelligence artificielle pour optimiser vos campagnes et améliorer l'expérience utilisateur. ",
        },
    
      ];
    return (
        <div className="faq-container-home">
            {questions.map((item, index) => (
                <div key={index} className="faq-item-home">
                    <div
                        className="faq-question-home"
                        onClick={() => toggleQuestion(index)}
                    >
                        {item.question}
                        <span className={`faq-icon ${activeIndex === index ? "open" : ""}`}>
                            {activeIndex === index ? <MinusIcon /> : <PlusIcon />}
                        </span>
                    </div>
                    {activeIndex === index && (
                        <div className="faq-answer-home title-p" dangerouslySetInnerHTML={{ __html: item.answer }}></div>

                    )}
                </div>
            ))}
        </div>
    );
};

export default QuestionsHome;