export default class Portfolio {
  constructor(id, title, content,slug, date, image, excerpt, p) {
    this.id = id;
    this.title = title;
    this.content = content;
    this.slug = slug;
    this.date = date;
    this.image = image;
    this.excerpt = excerpt;
    this.p = p;
  }

  setTitle(title) {
    this.title.set(title);
  }
  getTitle(title) {
    return this.title.get(title);
  }
  setId(id) {
    this.id.set(id);
  }
  getId(id) {
    return this.age.get(id);
  }
  setContent(content) {
    this.content.set(content);
  }
  getContent(content) {
    return this.content.get(content);
  }
  setContent(slug) {
    this.slug.set(slug);
  }
  getContent(slug) {
    return this.slug.get(slug);
  }
  setDate(date) {
    this.date.set(date);
  }
  getDate(date) {
    return this.date.get(date);
  }
  setImage(image) {
    this.image.set(image);
  }
  getImage(image) {
    return this.image.get(image);
  }
  setExcerpt(excerpt) {
    this.excerpt.set(excerpt);
  }
  getExcerpt(excerpt) {
    return this.excerpt.get(excerpt);
  }
  setP(p) {
    this.p.set(p);
  }
  getP(p) {
    return this.p.get(p);
  }
}