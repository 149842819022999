import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Import Swiper styles
import 'swiper/css/pagination'; // Import Swiper pagination styles
import './ServicesCarousel.css';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import design from '../../../photos/design.svg';
import dev from '../../../photos/dev.svg';
import seo from '../../../photos/seo.svg';
import mobile from '../../../photos/mobile.svg';

const services = [
  {
    title: "Design",
    icon: design,
    description: "Nos designers créent des visuels uniques et sur mesure pour vous démarquer de la concurrence. Un design percutant qui reflète vos valeurs et capte l'attention.",
  },
  {
    title: "Développement",
    icon: dev,
    description: "Nous développons des sites performants et optimisés, offrant une expérience utilisateur fluide. Confiez-nous votre projet, nous le ferons évoluer.",
  },
  {
    title: "Mobile",
    icon: mobile,
    description: "Nous concevons des solutions mobiles intuitives et performantes, adaptées à tous les appareils, pour maximiser l'engagement de vos utilisateurs.",
  },
  {
    title: "SEO",
    icon: seo,
    description: "Nos services de référencement vous permettront d'améliorer votre visibilité en ligne.",
  },
];

const ServicesCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    console.log("Current Active Index:", swiper.activeIndex); // Log current active index
    setActiveIndex(swiper.activeIndex);
  };



  return (
    <div className='carousel-services-container'>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={20}
        slidesPerView={4}
        loop={true}
        onSlideChange={handleSlideChange}
        autoplay={false} 
        // autoplay={{
        //   delay: 2500,
        //   disableOnInteraction: false,
        // }}
        breakpoints={{
          1440: { slidesPerView: 4 },
          1024: { slidesPerView: 3 },
          600: { slidesPerView: 2 },
          0: { slidesPerView: 1 },
        }}
        pagination={{ clickable: true }}
      >
        {services.map((service, index) => {
          // Check if this slide is the next one after the active index
          const isNextSlide = activeIndex === (index + 1) % services.length;
         // className={isNextSlide ? 'margin-top' : ''}
          return (
            <SwiperSlide key={index} >
              <div className="service-wrapper">
                <div className="service-card">
                  <div className="carousel-service-icon">
                    {typeof service.icon === 'string' ? (
                      <img src={service.icon} alt={service.title} />
                    ) : (
                      service.icon
                    )}
                  </div>
                  <div>
                  <h3>{service.title}</h3>
                  <p>{service.description}</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default ServicesCarousel;
