import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Breadcrumb, Title, WhatIs } from "../../../css/commun";
import { Link } from 'react-router-dom';
import { URL_Back, URL_Dev } from "../Variables";
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";
import support from '../../../photos/consulting/support.png'
import chefs from '../../../photos/consulting/chefs-projets.png'
import commerciale from '../../../photos/consulting/commerciale.png'
import img from '../../../photos/consulting/Group 38868.png'
import './Consulting.css'
import MyButton from "./MyButton";

const Consulting = (props) => {
  const title = props.location.pathname
  const splitState = title.split('/')
  const staticTitles = {
    en: "Consulting",
    fr: "Consulting"
  };
  const { t } = props
  const [pages, setPages] = useState([]);
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'
  const [titre, setTitre] = useState(staticTitles[currentLanguage]);
  const [lastChecked, setLastChecked] = useState(null);

 

  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])

  const cacheTitle = (result) => {
    const page = result.find(element =>
      decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1])
    );

    if (page) {
      const title = page.title.rendered.replace("&#8217;", "'");
      if (title !== titre) {
        setTitre(title);
      }
      setLastChecked(new Date().getTime()); 
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    const oneMonth = 30 * 24 * 60 * 60 * 1000;
    const lastCheckedTimestamp = lastChecked || 0;

    if (new Date().getTime() - lastCheckedTimestamp >= oneMonth) {
      fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
        .then(res => res.json())
        .then((result) => {
          setPages(result);
          cacheTitle(result);
        });
    }
  }, [lastChecked]);
 
  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description, element.date, element.excerpt.rendered)))


  return (
    <>
      <Helmet>
        <title>Oxton Digital : Experts en Stratégie Digitale et Consulting</title>
        <meta name="description" content={page[0] !== undefined && page[0].description} />
        <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
      </Helmet>
      <div className="container consulting-page">
        <Breadcrumb>
        <p>
          <Link className="retour" to="/">{t('Home_link')}</Link> / <span>{titre}</span>
        </p> 
        </Breadcrumb>
        <Title>
          <h1>{titre}</h1>
          <p className="title-p">
            Vous cherchez de l'aide pour votre stratégie numérique ? Oxton Digital peut vous accompagner ! Nous offrons des services de consultation pour vous aider à créer une stratégie digitale qui fonctionne pour votre entreprise. Que vous démarriez ou que vous soyez en affaires depuis des années, nous pouvons vous aider à élaborer un plan pour atteindre vos objectifs. Nous travaillerons avec vous pour comprendre votre entreprise et ce que vous voulez atteindre, puis nous élaborerons un plan personnalisé pour vous aider à y parvenir.
          </p>
        </Title>

        {/* Consulting Cards Section */}
        <div className="consulting-cards">
          <div className="consulting-card">
            <div className="consulting-card-icon">
              <img src={commerciale} alt="Icon 1" />
            </div>
            <h3 className="consulting-card-title">Une équipe commerciale pour vous conseiller</h3>
            <p className="consulting-card-text mb-0">
              Notre équipe de consultants a une compréhension approfondie de la façon de créer de la valeur par la transformation numérique. Nous travaillons avec nos clients pour concevoir et mettre en œuvre des solutions commerciales personnalisées.
            </p>
          </div>

          <div className="consulting-card cardc2">
            <div className="consulting-card-icon">
              <img src={chefs} alt="Icon 2" />
            </div>
            <h3 className="consulting-card-title">Une équipe de chefs de projet pour le déploiement</h3>
            <p className="consulting-card-text mb-0">
              Nos chefs de projet expérimentés travaillent avec vous pour assurer le déploiement réussi de votre projet numérique. Nous vous aidons à définir la portée et les exigences, identifier et évaluer les risques.
            </p>
          </div>

          <div className="consulting-card">
            <div className="consulting-card-icon">
              <img src={support} alt="Icon 3" />
            </div>
            <h3 className="consulting-card-title">Une équipe support pour vous assister</h3>
            <p className="consulting-card-text mb-0">
              Nous proposons également une gamme d'autres services de conseil, y compris des études de faisabilité, l'amélioration des processus, et la gestion du changement.
            </p>
          </div>
        </div>
        <div className="consulting-services">
          <div className="service-consulting">
            <div className="service-icon">
              <img src={img} alt="Réactivité" />
            </div>
            <div className="service-content">
              <h3>Une meilleure réactivité dans l'accomplissement des tâches</h3>
              <p>
                Lorsque vous faites appel à nous, vous pouvez être sûr que vos tâches seront
                accomplies en temps voulu et de manière efficace. Nous comprenons l'importance
                d'une livraison dans les délais, et nous ferons toujours de notre mieux pour
                respecter vos échéances.
              </p>
              <MyButton/>
            </div>
             </div>
            <div className="service-consulting">
              <div className="service-icon">
                <img src={img} alt="Agilité" />
              </div>
              <div className="service-content">
                <h3>Temps de travail : agilité garantie</h3>
                <p>
                  Nous travaillerons avec vous pour établir un processus de travail efficace et vous
                  aiderons à mettre en œuvre les derniers outils et technologies. Ainsi, vous serez en
                  mesure de travailler plus efficacement et de libérer du temps pour vous concentrer
                  sur d'autres domaines de votre activité.
                </p>
                <MyButton/>
              </div>
            </div>

            <div className="service-consulting">
              <div className="service-icon">
                <img src={img} alt="Disponibilité" />
              </div>
              <div className="service-content">
                <h3>Une disponibilité immédiate</h3>
                <p>
                  Lorsque vous nous contactez, vous êtes mis en relation avec un consultant qui peut
                  vous aider à résoudre votre problème rapidement. Cela est particulièrement utile
                  lorsque vous êtes confronté à un problème qui doit être résolu de toute urgence.
                </p>
               <MyButton/>
              </div>
            </div>
          </div>

        </div>
   
    </>
  );
}





export default withTranslation()(Consulting)
