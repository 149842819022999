import React, { useEffect, useState } from "react";
import styled from "styled-components"
import Teams from "../../../Model/Team";
import { Title, WhatIs } from "../../../css/commun";
import { URL_Back, URL_Dev } from "../Variables";
import { Link } from 'react-router-dom';
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";

const Equipes = (props) => {
  const title = props.location.pathname
  const splitState = title.split('/')
  const { t } = props
  const [items, setItems] = useState([]);
  const [pages, setPages] = useState([]);
  const [paragraphShow, setParagraphShow] = useState(1)
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'

  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'

    fetch(`${URL_Back}/wp-json/wp/v2/team?_embed&per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          var teamsLangue = []
          currentLanguage === "fr"
            ? (result.map(element => { element.excerpt.rendered === "<p>French</p>\n" && teamsLangue.push(element) }))
            : currentLanguage === "en"
              ? (result.map(element => { element.excerpt.rendered === "<p>English</p>\n" && teamsLangue.push(element) }))
              : currentLanguage === "ar"
              && (result.map(element => { element.excerpt.rendered === "<p>العربية</p>\n" && teamsLangue.push(element) }))
          setItems(teamsLangue)
        },
      )
  }, [currentLanguage])

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setPages(result)
        },
      )
  }, [])

  let team = [];
  items.map(element => {
    team.push(new Teams(element.id, element.title.rendered, element.content.rendered, element.excerpt, element._embedded['wp:featuredmedia'][0].source_url))
  });
  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description, element.date, element.excerpt.rendered)))

  let titre = page[0] !== undefined ? page[0].title.replace("&#8217;", "'") : "...Loading"

  return (
    <>
      <Helmet>
        <title>{titre}</title>
        <meta name="description" content={page[0] !== undefined && page[0].description} />
        <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
      </Helmet>
      <div className="container-fluid" >
        <Title>
          <h1>{titre}</h1>
          <p>
            <img className={langue === "ar" && "img-accueil-ar"} src="/images/accueil.png" alt="accueil" />
            <Link className="retour" to={`/${langue}`}>{t('Home_link')}</Link> / {page[0] !== undefined && <span>{titre}</span>}
          </p>
        </Title>
        <Expert>
          <h2 className={langue === "ar" && "alignRightMobile"}>{t('Teams.experts')}</h2>
          <div className={langue === "ar" ? "d-flex d-flex-ar" : "d-flex"}>
            <div className="left col-md-6 d-flex flex-column">
              <button className={paragraphShow == 1 ? langue === "ar" ? "btn button button-ar  active" : "btn button active" : langue === "ar" ? "btn button button-ar" : "btn button"} onClick={() => setParagraphShow(1)} ><span className="pseudo-text">{t('Digital Consultant')}</span></button>
              <button className={paragraphShow == 2 ? langue === "ar" ? "btn button button-ar  active" : "btn button active" : langue === "ar" ? "btn button button-ar" : "btn button"} onClick={() => setParagraphShow(2)} ><span className="pseudo-text">{t('The developers')}</span></button>
              <button className={paragraphShow == 3 ? langue === "ar" ? "btn button button-ar  active" : "btn button active" : langue === "ar" ? "btn button button-ar" : "btn button"} onClick={() => setParagraphShow(3)} ><span className="pseudo-text">{t('The designers')}</span></button>
              <button className={paragraphShow == 4 ? langue === "ar" ? "btn button button-ar  active" : "btn button active" : langue === "ar" ? "btn button button-ar" : "btn button"} onClick={() => setParagraphShow(4)} ><span className="pseudo-text">{t('Marketing specialists')}</span></button>
              <button className={paragraphShow == 5 ? langue === "ar" ? "btn button button-ar  active" : "btn button active" : langue === "ar" ? "btn button button-ar" : "btn button"} onClick={() => setParagraphShow(5)} ><span className="pseudo-text">{t('The editors')}</span></button>
            </div>
            <div className="right col-md-6">
              <p className={paragraphShow == 1 ? "" : "hide"}>{t('Teams.At Oxton')}</p>
              <p className={paragraphShow == 2 ? "" : "hide"}>{t('Teams.Our developers and integrators')}</p>
              <p className={paragraphShow == 3 ? "" : "hide"}>{t('Teams.The success of any website')}</p>
              <p className={paragraphShow == 4 ? "" : "hide"}>{t('Teams.Once your website is online')}</p>
              <p className={paragraphShow == 5 ? "" : "hide"}>{t('Teams.The content of your website')}</p>
            </div>
          </div>
        </Expert>
        <WhatIs className={langue === "ar" && "alignRightMobile"}>
          <h3 className={langue === "ar" && "alignRightMobile"}>{t('Teams.Working in synergy')}</h3>
          <p className={langue === "ar" ? "text alignRightMobile" : "text"}>
            {t('Teams.Our team is composed of different profiles')} <br />
            {t('Teams.We are passionate')}
          </p>
          <Link className="link" to={langue === "fr" ? "/fr/recrutement" : langue === "en" ? "/en/recrutement" : "/ar/توظيف"}>
            <button className={langue === "ar" ? "btn button button-ar joindre alignRightMobile" : "btn button joindre"}>
              <span className="pseudo-text">{t('Join us')} </span>
            </button>
          </Link>
        </WhatIs>
        {/* <Equipe>
          <div className="uk-card uk-card-muted col-12" >
            {team.map(item => (
              <div className="card-body card" key={item.id}>
                <img src={item.image} alt={item.name} className="img-fluid image" width={100} />
                <p id="category" className="name"> {item.name}  </p>
                <div className="status" dangerouslySetInnerHTML={{ __html: item.status }} />
              </div>
            ))}
          </div>
        </Equipe> */}
      </div>
    </>
  );
}

const Expert = styled.div`
  padding: 30px 15%;
  @media (max-width:991px) {
    padding: 30px 10%;
  }
  >h2{
    padding-bottom: 30px;
  }
  >.d-flex{
    @media (max-width:696px) {
      width: 100%;
      flex-direction: column;
    }
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
    @media (max-width:696px) {
      width: 100%;
    }
  }
  .left{
    padding: 0 20px;
    @media (max-width:696px) {
    padding: 0;
    }
  }
  .d-flex .button{
    background-color: transparent;
    border: 1px solid #707070;
    text-align: left;
    border-radius: 10px !important;
    margin-top: 15px !important;
    @media (max-width:1412px) {
      margin-top: 20px !important;
    }
    >.pseudo-text{
      font-family: EurostileExtended ;
      font-size: 18px;
      @media (max-width:991px) {
        font-size:16px;
      }
    }
    &:focus{
      box-shadow: none;
    }
    
    &:first-child{
      margin-top: 0 !important;
    }
   
    @media (max-width:991px) {
      margin-bottom: 0;
    }
  }
  .d-flex-ar .button{
    text-align: right;
  }

  .d-flex .button.active{
    background-color: #EEF7FB;
    border: 1px solid #EEF7FB;
    >.pseudo-text{
      color: #20ACE3;
      font-family: EurostileExtendedBold;
    }
    &:hover > .pseudo-text{
      color: white;
    }
    &:focus{
      box-shadow: none;
    }
    
  }

  .right{
    padding: 20px;
    background-color: #EEF7FB;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom: 2px solid #20ACE3;
    @media (max-width:696px) {
      margin-top: 30px;
    }
    >p{
      margin-bottom: 0;
      @media (max-width: 1188px) {
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: 313px;
      }

      @media (width: 991px) {
        max-height: 269px;
      }

      @media (max-width: 990px) {
        max-height: 293px;
      }
      
      @media (max-width: 839px) {
        max-height: 292px;
      }
      @media (max-width: 668px) {
        max-height: 263px;
      }
      @media (max-width: 752px) {
        max-height: 318px;
      }
      @media (max-width: 696px) {
        max-height: 111px;
      }
    }
  }

  .hide{
    display: none;
  }
`;


const Equipe = styled.div`
  padding:30px 17% ;
  .image{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 3px solid #20ACE3;
    padding: 4px;
  }
  >div{
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
    @media (max-width: 660px){
      justify-content: center;
    }
    >div{
      margin-bottom:0px;
      align-items: center;
      border:0px;
      font-size: 12px;
      padding: 0px 15px;
      .name{
        padding-top: 20px;
        font-family: EurostileExtendedBold;
        font-size: 16px;
        font-weight: bold;
        width: 100%;
        text-align: center;
      }
      .status{
        color: #20ACE3;
        font-weight: 600;
        position: relative;
        bottom: 15px;
      }
    }
  }
`;

export default withTranslation()(Equipes)