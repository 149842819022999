import React, { useState } from "react";
import './FrequentQuestions.css'

const FrequentQuestions = ({ questions }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleQuestion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const PlusIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5 35.15C27.6955 35.15 35.15 27.6957 35.15 18.5C35.15 9.30447 27.6957 1.84996 18.5 1.84996C9.30447 1.84996 1.84996 9.30447 1.84996 18.5C1.84996 27.6955 9.30447 35.15 18.5 35.15ZM18.5 37C28.7171 37 37 28.7171 37 18.5C37 8.28265 28.7171 0 18.5 0C8.28266 0 0 8.28265 0 18.5C0 28.7171 8.28266 37 18.5 37Z" fill="#17406F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.9016 18.4992C25.9016 19.0099 25.4874 19.4242 24.9767 19.4242H12.0265C11.5156 19.4242 11.1016 19.0099 11.1016 18.4992C11.1016 17.9885 11.5156 17.5742 12.0265 17.5742H24.9765C25.4874 17.5742 25.9016 17.9885 25.9016 18.4992Z" fill="#17406F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5109 25.8997C18.0002 25.8997 17.5859 25.4854 17.5859 24.9747V12.0246C17.5859 11.5137 18.0002 11.0996 18.5109 11.0996C19.0216 11.0996 19.4359 11.5137 19.4359 12.0246V24.9745C19.4359 25.4854 19.0216 25.8997 18.5109 25.8997Z" fill="#17406F"/>
    </svg>
  );
  const MinusIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5 35.15C27.6955 35.15 35.15 27.6957 35.15 18.5C35.15 9.30447 27.6957 1.84996 18.5 1.84996C9.30447 1.84996 1.84996 9.30447 1.84996 18.5C1.84996 27.6955 9.30447 35.15 18.5 35.15ZM18.5 37C28.7171 37 37 28.7171 37 18.5C37 8.28265 28.7171 0 18.5 0C8.28266 0 0 8.28265 0 18.5C0 28.7171 8.28266 37 18.5 37Z" fill="#17406F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.9016 18.4992C25.9016 19.0099 25.4874 19.4242 24.9767 19.4242H12.0265C11.5156 19.4242 11.1016 19.0099 11.1016 18.4992C11.1016 17.9885 11.5156 17.5742 12.0265 17.5742H24.9765C25.4874 17.5742 25.9016 17.9885 25.9016 18.4992Z" fill="#17406F"/>
    </svg>

  );

  return (
    <div className="faq-container">
      {questions.map((item, index) => (
        <div key={index} className="faq-item">
          <div
            className="faq-question"
            onClick={() => toggleQuestion(index)}
          >
            {item.question}
            <span className={`faq-icon ${activeIndex === index ? "open" : ""}`}>
              {activeIndex === index ? <MinusIcon /> : <PlusIcon />}
            </span>
          </div>
          {activeIndex === index && (
           <div className="faq-answer title-p" dangerouslySetInnerHTML={{ __html: item.answer }}></div>

          )}
        </div>
      ))}
    </div>
  );
};

export default FrequentQuestions;
