import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FormControl, Input, InputLabel } from "@material-ui/core";
import { Title } from "../../../css/commun";
import Cf7FormWrapper from "./Cf7FormWrapper";
import { useForm } from "react-hook-form";
import { URL_Back, URL_Dev } from "../Variables";
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import Popup from "../../Popup";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";

const SubDevis = (props) => {
  const title = props.subprops.location.pathname;
  const splitState = title.split("/");
  const { t } = props;
  let handler = props.handler;
  let isloading = props.isloading;
  const [items, setItems] = useState([]);
  const [pages, setPages] = useState([]);
  
  const [existError, setExistError] = useState(false);
  const [buttonPopup, setButtonPopup] = useState(false);
  const [voirPlus, setVoirPlus] = useState(true);
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get("i18next") || "fr";

  useEffect(() => {
    setLangue(currentLanguage);
    document.body.dir = currentLanguage === "ar" ? "rtl" : "ltr";
  }, [currentLanguage]);

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then((res) => res.json())
      .then((result) => {
        setPages(result);
      });
  }, []);

  let page = [];
  pages.map(
    (element) =>
      decodeURI(element.slug) ===
        (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
      page.push(
        new Pages(
          element.id,
          element.title.rendered,
          element.content.rendered,
          element.parent,
          element.slug,
          element.yoast_head_json.og_description,
          element.date,
          element.excerpt.rendered
        )
      )
  );

  let titre =
    page[0] !== undefined
      ? page[0].title.replace("&#8217;", "'")
      : "...Loading";

console.log(titre)


  const initialValues = { yourname: "", youremail: "",typeproject:"",numtel:"", yourmessage: "" };
  const [formState, setFormState] = useState(initialValues);
  const letters = /^[A-Za-z0-9._,.:;*$"'#?!^[{}()\]\\\-\+=@é àçèâêîû/ô._\s-]+$/;
  const validEmail = /^[a-zA-Z0-9._:$!%-à]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,3}$/;
  const validNumtel = /^[+]+[0-9]{10,16}$/;
  



  const {
    formState: { errors },
  } = useForm();

  const handleFieldChange = (field, e) => {
    !errors.yourname && (errors.yourname = {});
    !errors.yourmessage && (errors.yourmessage = {});
    !errors.youremail && (errors.youremail = {});
    !errors.numtel && (errors.numtel = {});
    !errors.typeproject &&(errors.typeproject={});
    
   
    setFormState({
      ...formState,
      [field]: e.target.value,
    });

    if (field === "yourname") {
      if (e.target.value.length < 2) {
        errors.yourname.message = "La longueur doit être de 2 ou plus";
        setExistError(false);
      } else if (!e.target.value.match(letters)) {
        errors.yourname.message = "Veuillez saisir uniquement des lettres";
        setExistError(false);
      } else {
        errors.yourname = {};
      }
    }

    if (field === "yourmessage") {
      if (e.target.value.length < 5) {
        errors.yourmessage.message = "La longueur doit être de 5 ou plus";
        setExistError(false);
      } else {
        errors.yourmessage = {};
      }
    }

    if (field === "youremail") {
      if (e.target.value.length < 5) {
        errors.youremail.message = "La longueur doit être de 5 ou plus";
        setExistError(false);
      } else if (!validEmail.test(e.target.value)) {
        errors.youremail.message = "L’adresse e-mail n’est pas valide.";
        setExistError(false);
      } else {
        errors.youremail = {};
      }
    }
    if (field === "numtel"){
      if (!validNumtel.test(e.target.value)) {
        errors.numtel.message = "Le numéro de téléphone n’est pas valide.";
        setExistError(false);
      }else {
        errors.numtel = {};
      }}

      
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    !errors.yourname && (errors.yourname = {});
    !errors.yourmessage && (errors.yourmessage = {});
    !errors.youremail && (errors.youremail = {});
    !errors.typeproject && (errors.typeproject = {});
    !errors.numtel && (errors.numtel = {});
    console.log("Form state before validation:", formState);
    var verifError = true;

    if (!formState.yourname) {
      errors.yourname.message = "Ce champ est Obligatoire";
      verifError = false;
    } else if (formState.yourname.length < 2) {
      errors.yourname.message = "La longueur doit être de 2 ou plus";
      verifError = false;
    }

    if (!formState.yourmessage) {
      errors.yourmessage.message = "Ce champ est Obligatoire";
      verifError = false;
    } else if (formState.yourmessage.length < 5) {
      errors.yourmessage.message = "La longueur doit être de 5 ou plus";
      verifError = false;
    } else if (!formState.yourmessage.match(letters)) {
      errors.yourmessage.message = "Veuillez saisir uniquement des lettres";
      verifError = false;
    }

    if (!formState.youremail) {
      errors.youremail.message = "Ce champ est Obligatoire";
      verifError = false;
    } else if (formState.youremail.length < 5) {
      errors.youremail.message = "La longueur doit être de 5 ou plus";
      verifError = false;
    } else if (!validEmail.test(formState.youremail)) {
      errors.youremail.message = "L’adresse e-mail n’est pas valide.";
      verifError = false;
    }
 
    if (!formState.numtel) {
      errors.numtel.message = "Veuillez saisir un numéro de téléphone"; 
      verifError = false;
    } else if (!validNumtel.test(formState.numtel)) {
      errors.numtel.message = "Le numéro de téléphone n’est pas valide."; 
      verifError = false;
    }
    if (formState.typeproject=="") {
      errors.typeproject.message = "Veuillez choisir le type de projet"; 
      verifError = false;
    }

    verifError && handler(e, formState);
    verifError && e.target.reset();
    setExistError(verifError);
    
    console.log("Errors after validation:", errors);
    if (verifError) {
      console.log("Form data:", formState);
      handler(e, formState);
      e.target.reset();
      setExistError(verifError);
      console.log("Formulaire soumis avec succès");
    } else {
      console.log("Erreurs:", errors);
    }
  };

  const handleSelectChange = (event) => {
  const { name, value } = event.target;
  var verifError = true;
  !errors.typeproject && (errors.typeproject = {});
  setFormState((prevState) => ({
    ...prevState,
    [name]: value,
  }));

  if (value === "") {
    errors.typeproject.message = "Veuillez choisir le type de projet"; 
    verifError = false;
  } 


};


  return (
    <>
      <Helmet>
        <title>{titre}</title>
        <meta
          name="description"
          content={page[0] !== undefined && page[0].description}
        />
        <link
          rel="canonical"
          href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`}
        />
      </Helmet>
      <div className="container-fluid ">
        <Title>
          <h1>{titre}</h1>
          <p>
            <img
              className={langue === "ar" && "img-accueil-ar"}
              src="/images/accueil.png"
              alt="accueil"
            />
            <a className="retour" href="/">
              {t("Home_link")}
            </a>{" "}
            / <span>{titre}</span>
          </p>
        </Title>
        <Form>
          
          <form className="from" onSubmit={handleFormSubmit} method="POST">
            <FormControl
              className={
                langue === "ar" ? "inputleft inputleft-ar" : "inputleft"
              }
            >
              <InputLabel
                className={langue === "ar" && "label-ar"}
                style={{ color: "white", marginTop: "-10px" }}
              >
                {t("Devis.name")}
              </InputLabel>
              <Input
                id="name"
                name="yourname"
                className="input"
                disableUnderline
                onChange={(e) => handleFieldChange("yourname", e)}
              />
              {errors.yourname && (
                <small className="text-danger">{errors.yourname.message}</small>
              )}
            </FormControl>
            <FormControl
              className={
                langue === "ar" ? "inputRight inputRight-ar" : "inputRight"
              }
            >
              <InputLabel
                className={langue === "ar" && "label-ar"}
                style={{ color: "#fff", marginTop: "-10px" }}
              >
                {t("Devis.Email")}
              </InputLabel>
              <Input
                id="email"
                name="youremail"
                className="input"
                disableUnderline
                onChange={(e) => handleFieldChange("youremail", e)}
                autoComplete="off"
              />
              {errors.youremail && (
                <small className="text-danger">
                  {errors.youremail.message}
                </small>
              )}
            </FormControl>

            <FormControl className={langue === "ar" ? "inputleft inputleft-ar" : "inputleft"}>
  <div className="typeProject">
    <InputLabel
      className={langue === "ar" && "label-ar"}
      style={{ color: "#fff", marginTop: "-10px" }}
    >
      {t("Devis.typeProject")}
    </InputLabel>
    <div className="p">
      <select
        id="typeproject"
        name="typeproject"
        className="form-select"
        aria-label="Default select example"
        onChange={handleSelectChange}
        value={formState.typeproject}
      >
        <option value="">{t("Devis.select")}</option>
        <option value="Application Mobile">{t("Devis.AppMobile")}</option>
        <option value="Application Web">{t("Devis.AppWeb")}</option>
      </select>
      {errors.typeproject && (
        <small className="text-danger-select">{errors.typeproject.message}</small>
      )}
    </div>
  </div>
</FormControl>

            <FormControl
              className={
                langue === "ar" ? "inputRight inputRight-ar" : "inputRight"
              }
            >
              <InputLabel
                className={langue === "ar" && "label-ar"}
                style={{ color: "#fff", marginTop: "-10px" }}
              >
                {t("Devis.NumTel")}
              </InputLabel>
              <Input
                id="phone"
                name="numtel"
                className="input"
                disableUnderline
                onChange={(e) => handleFieldChange("numtel", e)}
                autoComplete="off"
              />
              {errors.youremail && (
                <small className="text-danger">
                  {errors.numtel.message}
                </small>
              )}
            </FormControl>

            <div className="Project">
              <FormControl
                fullWidth
                className={
                  langue === "ar" ? "fullWidth fullWidth-ar" : "fullWidth"
                }
              >
                <InputLabel
                  className={langue === "ar" && "label-ar"}
                  style={{ color: "#fff", marginTop: "-10px" }}
                >
                  {t("Devis.Message")}
                </InputLabel>
                
                <Input
                  id="message"
                  name="yourmessage"
                  className="input"
                  disableUnderline
                  onChange={(e) => handleFieldChange("yourmessage", e)}
                  autoComplete="off"

                />
                       {errors.yourmessage && (
                  <small className="text-danger">
                    {errors.yourmessage.message}
                  </small>
                )}
         
              </FormControl>
            </div>

            <button  
              type="submit"
              className={
                langue === "ar"
                  ? "btn btn-primary button button-ar submit"
                  : "btn btn-primary button submit"
              }
              onClick={() => setButtonPopup(true)}
            >
              <span className="pseudo-text">{t("Devis.Send")}</span>
            </button>
            {existError && (
              <Popup
                trigger={buttonPopup}
                setTrigger={setButtonPopup}
                onBlur={() => setButtonPopup(false)}
              >
                <p>{t("Devis.Thank")}</p>
              </Popup>
            )}
          </form>
        </Form>
      
        
      </div>
    </>
  );
};



const Details = styled.div`
  padding: 10px 15% 60px 15%;
  display: flex;
  font-weight: 600;
  @media (max-width: 1150px) {
    padding: 10px 10% 60px 10%;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    justify-content: center;
  }
  > div > ul {
    list-style-type: none;
    display: inline-block;
    > li {
      text-align: initial !important;
    }
  }
  > div {
    width: 25%;
    text-align: center;
    line-height: 30px;
    @media (max-width: 1000px) {
      width: 100% !important;
    }
    &:nth-child(2) {
      width: 50%;
      > span {
        color: #20ace3;
      }
    }
    > img {
      padding: 30px;
    }
  }
`;

const Form = styled.div`
  padding: 100px 15% 50px 15%;
  color: white;
  height: auto; 
  background-image: linear-gradient(to right, #367ba7, #95cce5);
  @media (max-width: 991px) {
    padding: 30px 15%;
  }

  > h3 {
    padding: 35px 0 30px 0;
    text-align: center;
    font-size: 32px !important;
    color: white;
    @media (max-width: 991px) {
      text-align: left;
      font-size: 24px !important;
    }
  }

  > p {
    text-align: center;
    @media (max-width: 991px) {
      text-align: left;
    }
  }

  > p > span.pseudo-text {
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
  }

  .inputleft {
    width: 46%;
    margin-right: 4%;
    @media (max-width: 676px) {
      width: 100%;
      margin-right: 0;
    }
  }

  .inputleft-ar {
    margin-right: 0;
    margin-left: 4%;
  }

  .inputRight {
    width: 46%;
    margin-left: 4%;
    @media (max-width: 676px) {
      width: 100%;
      margin-left: 0;
    }
  }
  .inputRight {
    margin-right: 4%;
    margin-left: 0;
  }

  .fullWidth {
    margin: 0 2% 0 0;
  }
  .fullWidth-ar {
    margin: 0 0 0 2%;
  }
  .hide {
    display: none;
  }

  > .from {
    line-height: 100px;
  }
  .input {
    border: 0;
    border-bottom: 2px solid white;
    background-color: transparent;
    outline: none;
    color: white;
    padding: 6px ;
  }

  input:-webkit-autofill,
  .my-class:-webkit-autofill {
    transition-delay: 9999999s;
  }

  .submit {
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    color: #20ace3;
    border-radius: 15px;
    margin-bottom: 20px;
    padding: 10px 50px !important;
  }

  .text-danger {
    position: absolute;
    height: 0px;
    top: 20px;
    font-size: 16px;
    font-weight: 600;
    color: #c51414b3 !important;
  }

  > form span.merci {
    display: flex;
    place-content: center;
    color: #036203;
  }
  .typeProject {
    display: flex;
    flex-flow: row;
    place-content: center;
    -webkit-box-pack: center;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 10px;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: space-between;
  }
.p{
  width: 100%;
  margin-top: 35px;
}
.custom-select {
  width: 200px; /* Ajuster la largeur du champ de sélection */
  padding: 8px; /* Ajouter un peu de rembourrage pour un meilleur espacement */
  border: 1px solid #ccc; /* Ajouter une bordure */
  border-radius: 4px; /* Ajouter des coins arrondis */
  background-color: #fff; /* Couleur de fond */
  color: #333; /* Couleur du texte */
  font-size: 14px; /* Taille de police */
}
.text-danger-select {
    position: absolute;
    height: 0px;
    top: 45px;
    font-size: 16px;
    font-weight: 600;
    color: #c51414b3 !important;
  }




`;

export default withTranslation()(SubDevis)
