import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from "i18next-http-backend"

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi).init({
    supportedLngs: ['en', 'fr', 'ar'],
    fallbackLng: "fr",
    detection: {
      order: ['path', 'cookie', 'localStorage', 'htmlTag',  'subdomain'],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
      caches: ['cookie']
    },
    backend: { loadPath: '/assets/locales/{{lng}}/translation.json', },
    react: { useSuspense: false }
  })

export default i18next 