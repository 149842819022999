export default class Team{
   constructor(id, name, status ,excerpt, image )
   {
     this.id = id;
     this.name = name;
     this.status = status;
     this.excerpt = excerpt;
     this.image = image ;
   }

   setName(name)
   {
      this.name.set(name);
   }
  getName(name)
  {
      return this.name.get(name);
  }
  setId(id)
  {
      this.id.set(id);
  }
  getId(id)
  {
      return this.id.get(id);
  }
  setStatus(status)
  {
   this.status.set(status);
  }
  getStatus(status)
  {
    return this.status.get(status);
  }
  setExcerpt(excerpt) {
    this.excerpt.set(excerpt);
  }
  getExcerpt(excerpt) {
      return this.excerpt.get(excerpt);
  }
  setImage(image)
  {
   this.image.set(image);
  }
  getImage(image)
  {
    return this.image.get(image);
  }

  
 }