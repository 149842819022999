import React from 'react'
import Home from './Pages/Home';
import Contact from './Pages/Forms/Contact';
import Portfolios from './Pages/Portfolio/Portfolio';
import DetailPortfolio from './Pages/Portfolio/DetailPortfolio';
import Postuler from './Pages/Forms/Postuler';
import offres from './Pages/Offres/Offres';
import DetailsOffres from './Pages/Offres/DetailsOffres';
import Entreprise from './Pages/Apropos/Entreprise';
import Equipes from './Pages/Apropos/Equipes';
import Services from './Pages/Services/Services';
import ServicesMobile from './Pages/Services/ServicesMobile';
import Consulting from './Pages/Services/Consulting';
import Prestation from './Pages/Services/Prestation';
import SolutionBowling from './Pages/Bowling/SolutionBowling';
import PlanetBowling from './Pages/Bowling/PlanetBowling';
import ExtraLaserBowling from './Pages/Bowling/ExtraLaserBowling';
import LimogesBowling from './Pages/Bowling/LimogesBowling';
import SpaceBowling from './Pages/Bowling/SpaceBowling';
import AllPagesWP from './PagesWP/AllPagesWP';
import OnePagesWP from './PagesWP/OnePagesWP';
import Seo from './Pages/Services/Seo/Seo';
import FormulesSeo from './Pages/Services/Seo/FormulesSeo';
import Cm from './Pages/Services/Cm';
import { Switch, Route } from "react-router-dom";
import InvalidPath from './InvalidPath';
import NotFound from './Pages/NotFound';
import MontionsLegales from './Pages/Legales/MontionsLegales';
import Cgvps from './Pages/Legales/CguPages/Cgvps';
import Cgvhi from './Pages/Legales/CguPages/Cgvhi';
import Cgvmsi from './Pages/Legales/CguPages/Cgvmsi';
import Cgv from './Pages/Legales/Cgv';
import FilterPortfolio from './Pages/Portfolio/FilterPortfolio';
import Devis from './Pages/Forms/Devis'
import ServiceDesign from './Pages/Services/ServiceDesign';
import Soon from './Pages/Soon';
import StatutServices from './Pages/Services/StatutServices';
// import PopupForm from './Pages/Forms/PopupForm';


const Routes = () => {

  return (
    <Switch>
      <Route path="/" component={Home} exact />
      <Route path="/:lng(en|fr|ar)" component={Home} exact />
      <Route path="/notre-equipe" component={Equipes} exact />
      <Route path="/:lng(en|fr|ar)/notre-equipe" component={Equipes} exact />
      <Route path="/our-team" component={Equipes} exact />
      <Route path="/:lng(en|fr|ar)/our-team" component={Equipes} exact />
      <Route path="/فريقنا" component={Equipes} exact />
      <Route path="/:lng(en|fr|ar)/فريقنا" component={Equipes} exact />
      <Route path="/notre-agence" component={Entreprise} exact />
      <Route path="/:lng(en|fr|ar)/notre-agence" component={Entreprise} exact />
      <Route path="/our-agency" component={Entreprise} exact />
      <Route path="/:lng(en|fr|ar)/our-agency" component={Entreprise} exact />
      <Route path="/وكالتنا" component={Entreprise} exact />
      <Route path="/:lng(en|fr|ar)/وكالتنا" component={Entreprise} exact />
      <Route path="/nos-services" component={Services} exact />
      <Route path="/:lng(en|fr|ar)/nos-services" component={Services} exact />
      <Route path="/web-development" component={Services} exact />
      <Route path="/:lng(en|fr|ar)/web-development" component={Services} exact />
      <Route path="/تطوير-الويب" component={Services} exact />
      <Route path="/:lng(en|fr|ar)/تطوير-الويب" component={Services} exact />
      <Route path="/developpement-mobile" component={ServicesMobile} exact />
      <Route path="/:lng(en|fr|ar)/developpement-mobile" component={ServicesMobile} exact />
      <Route path="/mobile-development" component={ServicesMobile} exact />
      <Route path="/:lng(en|fr|ar)/mobile-development" component={ServicesMobile} exact />
      <Route path="/تطوير-المحمول" component={ServicesMobile} exact />
      <Route path="/:lng(en|fr|ar)/تطوير-المحمول" component={ServicesMobile} exact />
      <Route path="/community-management" component={Cm} exact />
      <Route path="/:lng(en|fr|ar)/community-management" component={Cm} exact />
      <Route path="/إدارة-المجتمع" component={Cm} exact />
      <Route path="/:lng(en|fr|ar)/إدارة-المجتمع" component={Cm} exact />
      <Route path="/search-engine-optimization" component={Seo} exact />
      <Route path="/:lng(en|fr|ar)/search-engine-optimization" component={Seo} exact />
      <Route path="/محرك-البحث-الأمثل" component={Seo} exact />
      <Route path="/:lng(en|fr|ar)/محرك-البحث-الأمثل" component={Seo} exact />

      <Route path="/formules-seo" component={FormulesSeo} exact />
      <Route path="/:lng(en|fr|ar)/formules-seo" component={FormulesSeo} exact />
      <Route path="/محرك-البحث" component={FormulesSeo} exact />
      <Route path="/:lng(en|fr|ar)/محرك-البحث" component={FormulesSeo} exact />

      <Route path="/ui-ux" component={ServiceDesign} exact />
      <Route path="/:lng(en|fr|ar)/ui-ux" component={ServiceDesign} exact />
      <Route path="/ui-ux" component={ServiceDesign} exact />
      <Route path="/:lng(en|fr|ar)/ui-ux" component={ServiceDesign} exact />
      

      <Route path="/soon" component={Soon} exact />
      <Route path="/:lng(en|fr|ar)/soon" component={Soon} exact />
      <Route path="/soon" component={Soon} exact />
      <Route path="/:lng(en|fr|ar)/soon" component={Soon} exact />
    
      <Route path="/statut-des-services" component={StatutServices} exact />
      <Route path="/:lng(en|fr|ar)/statut-des-services" component={StatutServices} exact />
      <Route path="/statut-des-services" component={StatutServices} exact />
      <Route path="/:lng(en|fr|ar)/statut-des-services" component={StatutServices} exact />

      <Route path="/service-de-consultation" component={Consulting} exact />
      <Route path="/:lng(en|fr|ar)/service-de-consultation" component={Consulting} exact />
      <Route path="/service-provision" component={Consulting} exact />
      <Route path="/:lng(en|fr|ar)/service-provision" component={Consulting} exact />
      <Route path="/خدمة-استشارية" component={Consulting} exact />
      <Route path="/:lng(en|fr|ar)/خدمة-استشارية" component={Consulting} exact />
      <Route path="/prestation-de-service" component={Prestation} exact />
      <Route path="/:lng(en|fr|ar)/prestation-de-service" component={Prestation} exact />
      <Route path="/service-delivery" component={Prestation} exact />
      <Route path="/:lng(en|fr|ar)/service-delivery" component={Prestation} exact />
      <Route path="/تقديم-الخدمة" component={Prestation} exact />
      <Route path="/:lng(en|fr|ar)/تقديم-الخدمة" component={Prestation} exact />
      <Route path="/nos-references" component={Portfolios} exact />
      <Route path="/:lng(en|fr|ar)/nos-references" component={Portfolios} exact />
      <Route path="/portfolio" component={Portfolios} exact />
      <Route path="/:lng(en|fr|ar)/portfolio" component={Portfolios} exact />
      <Route path="/مَلَفّ" component={Portfolios} exact />
      <Route path="/:lng(en|fr|ar)/مَلَفّ" component={Portfolios} exact />
      <Route path="/details-de-portfolio/:title" component={DetailPortfolio} />
      <Route path="/:lng(en|fr|ar)/details-de-portfolio/:title" component={DetailPortfolio} />
      <Route path="/details-of-portfolio/:title" component={DetailPortfolio} />
      <Route path="/:lng(en|fr|ar)/details-of-portfolio/:title" component={DetailPortfolio} />
      <Route path="/تفاصيل-الملف/:title" component={DetailPortfolio} />
      <Route path="/:lng(en|fr|ar)/تفاصيل-الملف/:title" component={DetailPortfolio} />
      <Route path="/filter-portfolio" component={FilterPortfolio} />
      <Route path="/:lng(en|fr|ar)/filter-portfolio" component={FilterPortfolio} />
      <Route path="/filter-portfolio" component={FilterPortfolio} />
      <Route path="/:lng(en|fr|ar)/filter-portfolio" component={FilterPortfolio} />
      <Route path="/تعليمات-الاستخدام" component={FilterPortfolio} />
      <Route path="/:lng(en|fr|ar)/تعليمات-الاستخدام" component={FilterPortfolio} />
      <Route path="/nos-offres-demplois" component={offres} exact />
      <Route path="/:lng(en|fr|ar)/nos-offres-demplois" component={offres} exact />
      <Route path="/our-job-offers" component={offres} exact />
      <Route path="/:lng(en|fr|ar)/our-job-offers" component={offres} exact />
      <Route path="/عروض-عملنا" component={offres} exact />
      <Route path="/:lng(en|fr|ar)/عروض-عملنا" component={offres} exact />
      <Route path="/details-de-loffres-demplois/:title" component={DetailsOffres} exact />
      <Route path="/:lng(en|fr|ar)/details-de-loffres-demplois/:title" component={DetailsOffres} exact />
      <Route path="/details-of-the-job-offer/:title" component={DetailsOffres} exact />
      <Route path="/:lng(en|fr|ar)/details-of-the-job-offer/:title" component={DetailsOffres} exact />
      <Route path="/تفاصيل-عروض-العمل/:title" component={DetailsOffres} exact />
      <Route path="/:lng(en|fr|ar)/تفاصيل-عروض-العمل/:title" component={DetailsOffres} exact />
      <Route path="/recrutement" component={Postuler} exact />
      <Route path="/:lng(en|fr|ar)/recrutement" component={Postuler} exact />
      <Route path="/recruiting" component={Postuler} exact />
      <Route path="/:lng(en|fr|ar)/recruiting" component={Postuler} exact />
      <Route path="/توظيف" component={Postuler} exact />
      <Route path="/:lng(en|fr|ar)/توظيف" component={Postuler} exact />
      <Route path="/contact" component={Contact} exact />
      <Route path="/:lng(en|fr|ar)/contact" component={Contact} exact />
      <Route path="/contact-us" component={Contact} exact />
      <Route path="/:lng(en|fr|ar)/contact-us" component={Contact} exact />
      <Route path="/اتصل-بنا" component={Contact} exact />
      <Route path="/:lng(en|fr|ar)/اتصل-بنا" component={Contact} exact />
      {/* <Route path="/:lng(en|fr|ar)/popup" component={PopupForm} exact /> */}
      <Route path="/devis" component={Devis} exact />
      <Route path="/:lng(en|fr|ar)/devis" component={Devis} exact />
      <Route path="/quotation" component={Devis} exact />
      <Route path="/:lng(en|fr|ar)/quotation" component={Devis} exact />
      <Route path="التسعير" component={Devis} exact />
      <Route path="/:lng(en|fr|ar)/التسعير" component={Devis} exact />
      <Route path="/notre-solution-bowling" component={SolutionBowling} exact />
      <Route path="/:lng(en|fr|ar)/notre-solution-bowling" component={SolutionBowling} exact />
      <Route path="/our-bowling-solution" component={SolutionBowling} exact />
      <Route path="/:lng(en|fr|ar)/our-bowling-solution" component={SolutionBowling} exact />
      <Route path="/حل-البولينج-لدينا" component={SolutionBowling} exact />
      <Route path="/:lng(en|fr|ar)/حل-البولينج-لدينا" component={SolutionBowling} exact />
      <Route path="/notre-solution-bowling/space-bowling" component={SpaceBowling} exact />
      <Route path="/:lng(en|fr|ar)/notre-solution-bowling/space-bowling" component={SpaceBowling} exact />
      <Route path="/our-bowling-solution/space-bowling" component={SpaceBowling} exact />
      <Route path="/:lng(en|fr|ar)/our-bowling-solution/space-bowling" component={SpaceBowling} exact />
      <Route path="/حل-البولينج-لدينا/space-bowling" component={SpaceBowling} exact />
      <Route path="/:lng(en|fr|ar)/حل-البولينج-لدينا/space-bowling" component={SpaceBowling} exact />
      <Route path="/notre-solution-bowling/extra-laser-bowling" component={ExtraLaserBowling} exact />
      <Route path="/:lng(en|fr|ar)/notre-solution-bowling/extra-laser-bowling" component={ExtraLaserBowling} exact />
      <Route path="/our-bowling-solution/extra-laser-bowling" component={ExtraLaserBowling} exact />
      <Route path="/:lng(en|fr|ar)/our-bowling-solution/extra-laser-bowling" component={ExtraLaserBowling} exact />
      <Route path="/حل-البولينج-لدينا/extra-laser-bowling" component={ExtraLaserBowling} exact />
      <Route path="/:lng(en|fr|ar)/حل-البولينج-لدينا/extra-laser-bowling" component={ExtraLaserBowling} exact />
      <Route path="/notre-solution-bowling/planet-bowling" component={PlanetBowling} exact />
      <Route path="/:lng(en|fr|ar)/notre-solution-bowling/planet-bowling" component={PlanetBowling} exact />
      <Route path="/our-bowling-solution/planet-bowling" component={PlanetBowling} exact />
      <Route path="/:lng(en|fr|ar)/our-bowling-solution/planet-bowling" component={PlanetBowling} exact />
      <Route path="/حل-البولينج-لدينا/planet-bowling" component={PlanetBowling} exact />
      <Route path="/:lng(en|fr|ar)/حل-البولينج-لدينا/planet-bowling" component={PlanetBowling} exact />
      <Route path="/notre-solution-bowling/bowling-de-limoges" component={LimogesBowling} exact />
      <Route path="/:lng(en|fr|ar)/notre-solution-bowling/bowling-de-limoges" component={LimogesBowling} exact />
      <Route path="/our-bowling-solution/bowling-de-limoges" component={LimogesBowling} exact />
      <Route path="/:lng(en|fr|ar)/our-bowling-solution/bowling-de-limoges" component={LimogesBowling} exact />
      <Route path="/حل-البولينج-لدينا/bowling-de-limoges" component={LimogesBowling} exact />
      <Route path="/:lng(en|fr|ar)/حل-البولينج-لدينا/bowling-de-limoges" component={LimogesBowling} exact />
      <Route path="/Cgv" component={Cgv} exact />
      <Route path="/:lng(en|fr|ar)/Cgv" component={Cgv} exact />
      <Route path="/conditions-generales-de-vente-de-prestations-de-services" component={Cgvps} exact />
      <Route path="/:lng(en|fr|ar)/conditions-generales-de-vente-de-prestations-de-services" component={Cgvps} exact />
      <Route path="/conditions-generales-de-vente-dhebergement-internet" component={Cgvhi} exact />
      <Route path="/:lng(en|fr|ar)/conditions-generales-de-vente-dhebergement-internet" component={Cgvhi} exact />
      <Route path="/conditions-generales-de-vente-maintenance-de-sites-internet" component={Cgvmsi} exact />
      <Route path="/:lng(en|fr|ar)/conditions-generales-de-vente-maintenance-de-sites-internet" component={Cgvmsi} exact />
      <Route path="/mentions-legales" component={MontionsLegales} exact />
      <Route path="/:lng(en|fr|ar)/mentions-legales" component={MontionsLegales} exact />
      <Route path="/terms-of-use" component={MontionsLegales} exact />
      <Route path="/:lng(en|fr|ar)/terms-of-use" component={MontionsLegales} exact />
      <Route path="/تعليمات-الاستخدام" component={MontionsLegales} exact />
      <Route path="/:lng(en|fr|ar)/تعليمات-الاستخدام" component={MontionsLegales} exact />

      <Route path="/allpageswp" component={AllPagesWP} exact />
      <Route path="/:lng(en|fr|ar)/allpageswp" component={AllPagesWP} exact />

      {/* <Route path="/404-not-found" component={NotFound} exact /> */}
      {/* <Route path="/:slug" component={OnePagesWP} exact/> */}
      <Route component={InvalidPath} />
    </Switch>
  )
}
export default Routes 