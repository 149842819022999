import React from "react";
import "./TextSlider.css";

const TextSlider = () => {
  const text = "Stratégie • Développement • Marketing digital •";

  return (
    <div className="slider-container">
      <div className="slider">
        <div className="slider-content">
          <span>Stratégie •</span>
          <span className="development">Développement •</span>
          <span>Marketing digital •</span>
          <span className="development">Stratégie •</span>
          <span >Développement •</span>
          <span className="development">Marketing digital •</span>
        </div>
        <div className="slider-content">
          <span className="development">Stratégie •</span>
          <span>Développement •</span>
          <span className="development">Marketing digital •</span>
          <span>Stratégie •</span>
          <span className="development">Développement •</span>
          <span>Marketing digital •</span>
        </div>
      </div>
    </div>
  );
};

export default TextSlider;
