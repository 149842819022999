import React, { useState, useEffect } from "react";
import Cf7FormWrapper from "./Cf7FormWrapper";
import SubContact from "./SubContact";

const Contact = (props) => {
  return (
    <Cf7FormWrapper id="202">
      <SubContact subprops={props} />
    </Cf7FormWrapper>
  )
}

export default Contact;
