import React, { useRef, useEffect } from 'react';
import Map from 'ol/Map';
import View from 'ol/View';
import Tile from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import 'ol/ol.css'; 
import './MapComponent.css';
import { Feature } from 'ol';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import { Style, Icon } from 'ol/style';
import marker from '../../../../photos/location.svg'

const MapComponent = ({ locations }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const viewRef = useRef(null);

  useEffect(() => {
    if (mapContainerRef.current) {
      const map = new Map({
        target: mapContainerRef.current,
        layers: [
          new Tile({
            source: new OSM(),
          }),
        ],
        view: new View({
          center: fromLonLat([12.612249712016634, 41.81006778130832]), 
          zoom: 5,
          scrollWheelZoom: 'off',
        }),
      });

      mapRef.current = map;
      viewRef.current = map.getView(); 

      return () => {
        map.setTarget(undefined);
      };
    }
  }, []);

  useEffect(() => {
    const map = mapRef.current;
    if (!map) return;

    const features = locations.map(location => {
      const feature = new Feature({
        geometry: new Point(fromLonLat([location.lng, location.lat])),
        name: location.name,
      });

      feature.setStyle(
        new Style({
          image: new Icon({
            anchor: [0.5, 1],
            src: marker,
          
            crossOrigin: 'anonymous',
          }),
        })
      );

      return feature;
    });

    const vectorLayer = new VectorLayer({
      source: new VectorSource({
        features: features,
      }),
    });

    const existingVectorLayer = map.getLayers().getArray().find(layer => layer instanceof VectorLayer);
    if (existingVectorLayer) {
      map.removeLayer(existingVectorLayer);
    }
    map.addLayer(vectorLayer);
  }, [locations]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 991) {
        viewRef.current.setCenter(fromLonLat([6.304079384155501, 43.782294299744116])); 
        viewRef.current.setZoom(4); 
      } else {
        viewRef.current.setCenter(fromLonLat([12.612249712016634, 41.81006778130832])); 
        viewRef.current.setZoom(5); 
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  
  useEffect(() => {
    const handleKeyDown = (event) => {
      const map = mapRef.current;
      if (!map) return;

      const view = viewRef.current;
      if (event.ctrlKey) {
        if (event.key === '+') {
          event.preventDefault(); 
          view.setZoom(view.getZoom() + 1); 
        } else if (event.key === '-') {
          event.preventDefault();
          view.setZoom(view.getZoom() - 1); 
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return <div className='mapcontact' ref={mapContainerRef} />;
};

export default MapComponent;
