import React, { useEffect, useState } from "react";
import styled from "styled-components"
import Client from "../../Model/Client";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Aos from "aos";
import "aos/dist/aos.css";
import { useTypedCountry } from './useTypedCountry.tsx';
import { URL_Back, URL_Dev } from "./Variables";
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Pages from "../../Model/Pages";
import { Helmet } from 'react-helmet-async'
import i18next from "../../i18next";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";
import Popup from "../Popup.js";
import { TailSpin } from 'react-loader-spinner';
import './Home.css'
import satisfait from "../../photos/satisfait.svg"
import projets from "../../photos/projets.svg"
import garantie from "../../photos/garantie.svg"
import cl from "../../photos/clients.svg"
import ServicesCarousel from "./Services/ServicesCarousel.js";
import TestimonialCarousel from "./TestimonialCarousel.js";
import TextSlider from "./TextSlider.js";
import CustomCarousel from "./Portfolio/CustomCarousel.js";
import QuestionsHome from "./Services/QuestionsHome.js";


const Home = (props) => {


  gsap.registerPlugin(ScrollTrigger);
  const [items, setItems] = useState([]);
  const [images, setImages] = useState([]);
  const [pages, setPages] = useState([]);
  const [langue, setLangue] = useState("fr");

  const [url, setUrl] = useState("notre-solution-bowling");
  const currentLanguage = cookies.get('i18next') || 'fr'
  const { t } = props
  let handler = props.handler;
  const Countries = [`${t('France')}`, `${t('Tunisia')}`]
  const Country = useTypedCountry(Countries)

  const responsive = {
    plasama: {
      breakpoint: { max: 3000, min: 2000 },
      items: 6
    },
    ldesktop: {
      breakpoint: { max: 2000, min: 1600 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 1600, min: 1312 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1312, min: 950 },
      items: 3
    },
    mini: {
      breakpoint: { max: 950, min: 671 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 671, min: 0 },
      items: 1
    }
  };

  useEffect(() => {
    setLangue(currentLanguage)
    currentLanguage === 'fr' ? setUrl("notre-solution-bowling")
      : currentLanguage === "en"
        ? setUrl("our-bowling-solution")
        : setUrl("حل-البولينج-لدينا")
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])

  useEffect(() => {

    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname)

    Aos.init({ duration: 2000 });

    gsap.registerPlugin(ScrollTrigger);
    gsap.config({
      autoSleep: 60,
      force3D: false,
      nullTargetWarn: false,
      trialWarn: false,
      units: { left: "%", top: "%", rotation: "rad" }
    });
    gsap.to(document.getElementsByClassName("down"), {
      scrollTrigger: {
        trigger: document.getElementsByClassName("images"),
        toggleActions: "restart pause reverse pause",
        start: "top center+=100px",
        end: "bottom center-=300px",
        scrub: true,
      },
      y: 100,
      x: -100,
      duration: 3,
    });

    gsap.to(document.getElementsByClassName("up"), {
      scrollTrigger: {
        trigger: document.getElementsByClassName("images"),
        toggleActions: "restart pause reverse pause",
        start: "top center+=100px",
        end: "bottom center-=300px",
        scrub: true,
      },
      y: -100,
      x: +100,
      duration: 3,
    });

    fetch(`${URL_Back}/wp-json/wp/v2/clients?_embed&per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setItems(result)
        },
      )

    fetch(`${URL_Back}/wp-json/wp/v2/images?_embed&per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setImages(result)
        },
      )

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setPages(result)
        },
      )
  }, [])

  let clients = [];
  let image = [];
  items.map(element =>
    clients.push(new Client(element.id, element.title.rendered, element.date, element._embedded['wp:featuredmedia'][0].source_url)));
  images.map(element =>
    image.push(new Client(element.id, element.title.rendered, element.date, element._embedded['wp:featuredmedia'][0]))
  )
  let page = []
  pages.map(element =>
    element.slug === "home" &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description, element.date, element.excerpt.rendered)))

  let titre = "Accueil de l'Oxton Digital pour développement web, mobile et borne"
  const [portfolios, setPortfolios] = useState([]);
 
  useEffect(() => {
    fetch('https://back-end.oxton-digital.com/wp-json/wp/v2/portfolio?_embed&per_page=100')
      .then(response => response.json())
      .then(data => {
        const frenchPortfolios = data.filter(item => item.excerpt.rendered === "<p>French</p>\n");
        setPortfolios(frenchPortfolios);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Oxton Digital : Votre Agence Digitale Spécialisée pour 2024</title>
        <meta name="description" content={page?.[0]?.description || 'Default description'} />
        <link rel="canonical" href={`${URL_Dev}`} />
      </Helmet>

      <div className="container-fluid home-container">
        <div className="container agence">
          <h1 className="mb-3">
            Votre agence spécialisée en <br /> <Creative>Développement & design depuis 2007</Creative>
          </h1>
          <p className="mb-4">
            Nous sommes dévoués à accompagner nos clients à chaque étape de leur croissance, en mettant en œuvre des stratégies personnalisées et en fournissant un soutien continu.
            Nous croyons fermement que leur succès est le reflet de notre propre réussite.
          </p>
          <div className="button-group mb-5">
            <button
              className="btn rendez-vous"  onClick={() => window.open('https://outlook.office365.com/book/OXTONDIGITAL@oxton-digital.com/', '_blank')}>
              Rendez-vous
            </button>
            <button className="btn nos-realisations" onClick={() => {
              if (currentLanguage === 'fr') {
                window.location.href = "/fr/nos-references";
              } else if (currentLanguage === 'en') {
                window.location.href = "/en/portfolio";
              } else if (currentLanguage === 'ar') {
                window.location.href = "/ar/مَلَفّ";
              }
            }}>Nos Réalisations</button>
          </div>
        </div>

        {/* Carousel Portfolio */}
        <div>
          {portfolios && portfolios.length > 0 ? (
            <div className="portfolio-container">
              <CustomCarousel items={portfolios} langue={currentLanguage} />
            </div>
          ) : (
            <div className="loading">
              <TailSpin color="#17406F" />
            </div>
          )}
        </div>

        {/* Vision Section */}
        <div className="container vision ">
          <h2>{t('Home.We transform')}</h2>
          <p className="title-p">
            Vous cherchez une agence web et marketing expérimentée ? Nous sommes là ! Experts en développement web et stratégie digitale, nous créons des sites efficaces et attirons votre audience cible pour booster votre business.
            Contactez-nous dès maintenant pour une consultation gratuite.
          </p>
          <div className="grid-container-vision">
            <div className="grid-item-vision d-flex align-items-center gap-3">
              <img src={cl} alt="Clients" className="img-fluid" />
              <div className="d-flex flex-column">
                <span className="main-text">CLIENTS</span>
                <span className="sub-text">400+</span>
              </div>
            </div>
            <div className="grid-item-vision d-flex align-items-center gap-3">
              <img src={projets} alt="Projets" className="img-fluid" />
              <div className="d-flex flex-column">
                <span className="main-text">PROJETS</span>
                <span className="sub-text">625+</span>
              </div>
            </div>
            <div className="grid-item-vision d-flex align-items-center gap-3">
              <img src={garantie} alt="Garantie" className="img-fluid" />
              <div className="d-flex flex-column">
                <span className="main-text">GARANTIE</span>
                <span className="sub-text">6 Mois</span>
              </div>
            </div>
            <div className="grid-item-vision d-flex align-items-center gap-3">
              <img src={satisfait} alt="Satisfait" className="img-fluid" />
              <div className="d-flex flex-column">
                <span className="main-text">CLIENT SATISFAIT</span>
                <span className="sub-text">100%</span>
              </div>
            </div>

          </div>

          <h2 className="nos-services">Nos services pour accroître vos chances de succès</h2>
          <p>Oxton Digital offre des services d'experts en matière de marketing digital aux petites et moyennes entreprises...</p>
        </div>

        {/* Services Carousel */}
        <ServicesCarousel />

        {/* Text Slider */}
        <TextSlider />

        {/* Testimonial Section */}
        {/* <CustomCarousel items={portfolios} /> */}
        <div className="container  voix">
          <h2>Voix de nos clients</h2>
          <p>Découvrez ce que les clients pensent de nous. Promis, ce sont des vrais clients contents,
            allez voir sur Google !</p>
          <TestimonialCarousel />
        </div>
        <div className="container  faq-home">
          <h2>Pourquoi choisir Oxton Digital en 2024 ? </h2>
          <p>Dans un environnement numérique en constante évolution, choisir la bonne agence digitale est crucial pour assurer la croissance et la pérennité de votre entreprise. 
            Voici pourquoi Oxton Digital se démarque en 2024 :</p>

            <QuestionsHome/>
         
        </div>
        {/* Clients Carousel */}
        {/* <NosClients data-aos="fade-up">
          <div className="listeOfClients">
            <Carousel
              responsive={responsive}
              autoPlay={true}
              autoPlaySpeed={3000}
              infinite={true}
              containerClass="carousel-container"
            >
              {clients.map((item) => (
                <img key={item.id} src={item.image} alt={item.title} className="img-fluid imgClients" />
              ))}
            </Carousel>
          </div>
        </NosClients> */}
      </div>
    </>
  );
}
const Creative = styled.span`
  color: #20ACE3;
  text-decoration: none !important;
`;

export const NosServices = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 30px 140px 80px 140px; 
  box-shadow: 0px 0px 8px rgba(23, 64, 111, .20);
  z-index: 0;
  @media (max-width:1000px) {
    margin: 30px 10% 80px;
  }
  @media (max-width: 830px){
    margin: 30px 70px 80px 70px;
  }
  @media (max-width: 674px){
    margin: 30px 40px 80px 40px;
  }
`;

export const Background = styled.div`
  overflow: hidden;
  width: 100%;
  margin-top: -340px;
  >img{
    display: flex;
    width: 100%;
    height: 334px;
  }
`;

export const Bowling = styled.div`
  padding: 30px 10% 60px 10%;
  @media (max-width:991px) {
    padding: 30px 10%;
  }
  
  >.row{
    >div {
      @media (max-width:624px) {
        width: 100%;
      }
      @media (max-width:1290px) and (min-width:625px){
        width: 50%;
      }
      padding-right: 20px;
      padding-left: 20px;
      >.link{
        &:hover .flip-card-inner { 
          transform: rotateY(180deg);
        }
        >.flip-card-inner {
          position: relative;
          height: 100%;
          width: 100%;
          transition: transform 0.8s;
          transform-style: preserve-3d;
          >.flip-card-front{
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            height: 100%;
            width: 100%;
            >img{
              position: relative;
              display: block;
              width: 100%;
              }
          }
          >.flip-card-back{
            position:absolute;
            inset: 0;
            height: 100%;
            width: 100%;
            text-align: center;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            transform: rotateY(180deg);
            >.content{
              text-decoration: none !important;
              color: #17406F;
              padding: 0 5%;
              background-color: white;
              border:1px solid #17406F;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              position: absolute;
              inset: 0;
              height: 100% !important;
              width: 100%;
              border-radius:8px;
              >img{
                width: 100%;
                padding-bottom:20px;
              }
            }  
          }
        }
      }
    }
  }
  
`;

const NosClients = styled.div`
  margin: 30px 60px 60px;
  @media (max-width:1100px) {
    margin: 30px 70px 60px;

  }
  @media (max-width:991px) {
    margin: 23px 70px 40px;
  }
  @media (max-width:425px) {
    margin: 23px 50px 40px;
  }
  >h2{
    text-align:center;
    padding-bottom: 40px;
  }
  >.listeOfClients >.carousel-container{
      margin-top:150px;
      padding: 0px !important;

      @media(max-width: 371px){
        padding-left: 45px ;
      }
      @media(max-width: 380px) and (min-width: 372px) {
        padding-left: 52px ;
      }
      @media(max-width: 384px) and (min-width: 381px) {
        padding-left: 55px ;
      }
      @media(max-width: 391px) and (min-width: 385px) {
        padding-left: 60px ;
      }
      @media(max-width: 400px) and (min-width: 391px) {
        padding-left: 65px ;
      }
      @media(max-width: 411px) and (min-width: 401px) {
        padding-left: 70px ;
      }
      @media(max-width: 420px) and (min-width: 411px) {
        padding-left: 75px ;
      }
      @media(max-width: 424px) and (min-width: 421px) {
        padding-left: 80px ;
      }
      @media(max-width: 440px) and (min-width: 425px) {
        padding-left: 60px ;
      }
      @media(max-width: 450px) and (min-width: 441px) {
        padding-left: 70px ;
      }
      @media(max-width: 457px) and (min-width: 451px){
        padding-left: 75px ;
      }
      @media(max-width: 471px) and (min-width: 458px){
        padding-left: 60px ;
      }
      @media(max-width: 480px) and (min-width: 472px){
        padding-left: 70px ;
      }
      @media(max-width: 500px) and (min-width: 481px){
        padding-left: 80px ;
      }
      @media(max-width: 520px) and (min-width: 501px){
        padding-left: 90px ;
      }
      @media(max-width: 550px) and (min-width: 521px){
        padding-left: 100px ;
      }
      @media(max-width: 600px) and (min-width: 551px){
        padding-left: 120px;
      }
      @media(max-width: 631px) and (min-width: 601px){
        padding-left: 140px;
      }
      @media(max-width: 671px) and (min-width: 632px){
        padding-left: 160px;
      }
      @media(max-width: 705px) and (min-width: 672px){
        padding-left: 35px;
      }
      @media(max-width: 720px) and (min-width: 706px){
        padding-left: 40px;
      }
      @media(max-width: 745px) and (min-width: 721px){
        padding-left: 45px;
      }
      @media(max-width: 760px) and (min-width: 746px){
        padding-left: 50px;
      }
      @media(max-width: 790px) and (min-width: 761px){
        padding-left: 55px;
      }
      @media(max-width: 820px) and (min-width: 791px){
        padding-left: 60px;
      }
      @media(max-width: 836px) and (min-width: 821px){
        padding-left: 70px;
      }
      @media(max-width: 890px) and (min-width: 837px){
        padding-left: 70px;
      }  
      @media(max-width: 926px) and (min-width: 891px){
        padding-left: 80px;
      }      
      @media(max-width: 950px) and (min-width: 927px){
        padding-left: 90px;
      }
      @media(max-width: 991px) and (min-width: 951px){
        padding-left: 40px;
      }
      @media(max-width: 1015px) and (min-width: 992px){
        padding-left: 35px;
      }
      @media(max-width: 1060px) and (min-width: 1016px){
        padding-left: 40px;
      }
      @media(max-width: 1080px) and (min-width: 1061px){
        padding-left: 45px;
      }
      @media(max-width: 1100px) and (min-width: 1081px){
        padding-left: 55px;
      }
      @media(max-width: 1140px) and (min-width: 1101px){
        padding-left: 60px;
      }
      @media(max-width: 1286px) and (min-width: 1141px){
        padding-left: 70px;
      }
      @media(max-width: 1312px) and (min-width: 1286px){
        padding-left: 90px;
      }
      @media(max-width: 1400px) and (min-width: 1312px){
        padding-left: 45px;
      }
      @media(max-width: 1500px) and (min-width: 1400px){
        padding-left: 60px;
      } 
      @media(max-width: 1600px) and (min-width: 1500px){
        padding-left: 70px;
      }  
      @media(max-width: 1910px) and (min-width: 1601px){
        padding-left: 40px;
      }  
      @media(max-width: 2271px) and (min-width: 1911px){
        padding-left: 60px;
      }
      @media(max-width: 2156px) and (min-width: 2271px){
        padding-left: 50px;
      }
      @media(max-width: 2297px) and (min-width: 2156px){
        padding-left: 60px;
      }
      @media(min-width: 2297px){
        padding-left: 80px;
    }
    
    .imgClients{
      max-width: 240px !important;
      max-height: 90px;
      @media (max-width:991px) {
        max-width: 200px !important;
      }
      @media (max-width:457px) {
        max-width: 170px !important;
      }

      @media (max-width:417px) and (min-width:375px) {
        max-width: 200px;
      }
    }  
  }

  .react-multi-carousel-track {
    align-items: center;
    text-align: center;
  }

  .react-multiple-carousel__arrow--left::before, .react-multiple-carousel__arrow--right::before {
    color: transparent;
  }
  .react-multiple-carousel__arrow{
    background: transparent;
  }
  .react-multiple-carousel__arrow--left::before {
    background: url('/images/home/Group 20204.svg') no-repeat;
    width: 32px;
    @media (max-width: 380px) {
      right: 25px;
    }
    @media (max-width: 671px)and (min-width: 381px){
      right: 15px;
    }
    @media (max-width: 837px)and (min-width: 672px){
      right: 48px;
    }
    @media (max-width: 1084px)and (min-width: 837px){
      right: 60px;
    }
    @media (max-width: 1244px)and (min-width: 1084px){
      right: 55px;
    }
    @media (max-width: 1582px) and (min-width: 1244px){
      right: 70px;
    }
    @media (max-width: 1832px) and (min-width: 1583px){
      right: 80px;
    }
    @media(min-width: 1833px){
      right:90px
    }
  }
  .react-multiple-carousel__arrow--right::before {
    background: url('/images/home/Group 20205.svg') no-repeat;
    left:30px;
    width: 30px;

    
    @media (max-width: 380px) {
      left: 25px;
    }
    @media (max-width: 671px)and (min-width: 381px){
      left: 15px;
    }
    @media (max-width: 692px) and (min-width: 672px){
      left: 55px;
    }
    @media (max-width: 953px) and (min-width: 693px){
      left: 45px;
    }
    @media (max-width: 1051px) and (min-width: 954px){
      left:68px;
    }
    @media (max-width: 1176px) and (min-width: 1051px){
      left: 65px;
    }
    @media (max-width: 1287px) and (min-width: 1177px){
      left: 75px;
    }
    @media (max-width: 1440px) and (min-width: 1287px){
      left: 80px;
    }
    @media(max-width: 2000px) and (min-width: 1441px){
      left: 87px;
    }
    @media(max-width: 2101px) and (min-width: 2001px){
      left: 107px;
    }
    @media(min-width: 2101px){
      left: 107px;
    }
  }
`;

export default withTranslation()(Home)
