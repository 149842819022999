import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Title } from "../../../css/commun";
import { Description, Client, Contacter } from "../../../css/Bowling";
import { Link } from 'react-router-dom';
import { URL_Back, URL_Dev } from "../Variables";
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";

const ExtraLaserBowling = (props) => {
  const title = props.location.pathname
  const splitState = title.split('/')
  const { t } = props
  const [pages, setPages] = useState([]);
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'

  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setPages(result)
        },
      )
  }, [])

  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[3] : splitState[2]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description, element.date, element.excerpt.rendered)))
  let titre = page[0] !== undefined ? page[0].title.replace("&#8217;", "'") : "...Loading"

  return (
    <>
      <Helmet>
        <title>{titre}</title>
        <meta name="description" content={page[0] !== undefined && page[0].description} />
        <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
      </Helmet>
      <div className="container-fluid" >
        <Title>
          <h1>{titre}</h1>
          <p>
            <img className={langue === "ar" && "img-accueil-ar"} src="/images/accueil.png" alt="accueil" />
            <a className="retour" href="/">{t('Home_link')}</a> /
            {langue === "fr"
              ? <a className="retour" href="/fr/notre-solution-bowling">Notre Solution Bowling</a>
              : langue === "en" ? <a className="retour" href="/en/our-bowling-solution">Our Bowling Solution</a>
                : <a className="retour" href="/ar/حل-البولينج-لدينا"> حل البولينج لدينا</a>
            } / <span>{titre}</span>
          </p>
        </Title>
        <Description>
          <img className="mainImage" src="/images/extraLaserBowling/Group 20192.png" alt="Group 20192" />
          <div className="eBowling">
            <h2 className={langue === "ar" ? "titre alignRightMobile" : "titre"}>{t('ExtraLaser.solution')}</h2>
            <p className={langue === "ar" && "alignRightMobile"}>
              {t('ExtraLaser.located')}
            </p>
            <div className="technologies">
              <bdi>
                <img src="/images/portfolio/html-5.svg" alt="HTML" /><span>HTML</span>
                <img src="/images/portfolio/css-3.svg" alt="css" /><span>CSS</span>
                <img src="/images/portfolio/js.svg" alt="js" /><span>JS</span>
              </bdi>
            </div>
          </div>
        </Description>
        <Details>
          <div>
            <div className="right">
              <div>
                <img src="/images/extraLaserBowling/mockup_extraweb.png" alt="mockup_extraweb" />
              </div>
            </div>
            <div className="left">
              <h3>{t('Solution.Web')}</h3>
              <span>{t('ExtraLaser.reservations')}</span><br />
              <a href="https://www.extra-laserbowl.fr" target="_blank"><button className={langue ? "btn btn-light button button-ar voir" : "btn btn-light button voir"}><span className="pseudo-text">{t('Solution.website')}</span></button></a>
            </div>
          </div>
          <div className="secondeDiv">
            <div className="left">
              <h3>{t('Solution.Mobile')}</h3>
              <span>{t('Solution.operations')}</span>
            </div>
            <div className="right">
              <div>
                <img src="/images/extraLaserBowling/mockup_extra-tab.png" alt="mockup_extra-tab" />
              </div>
            </div>
          </div>
          <div>
            <div className="right">
              <div>
                <img src="/images/extraLaserBowling/borne mockup-01.png" alt="borne mockup-01" />
              </div>
            </div>
            <div className="left">
              <h3>{t('Solution.Kiosk')}</h3>
              <span>{t('ExtraLaser.Payment')}</span>
            </div>
          </div>
        </Details>
        <Background>
          <Loisirs>
            <h2 className={langue === "ar" && "alignRightMobile"}>{t('Solution.helps')}</h2>
            <div className="row gy-3">
              <div className="col-md-4">
                <ul className={langue === "ar" && "ul-ar"}>
                  <li>{t('Solution.Email')}</li>
                  <li>{t('Solution.Slot')} </li>
                </ul>
              </div>
              <div className="col-md-4">
                <ul className={langue === "ar" && "ul-ar"}>
                  <li>{t('Solution.Offer')} </li>
                  <li>{t('Solution.export')} </li>
                </ul>
              </div>
              <div className="col-md-4">
                <ul className={langue === "ar" && "ul-ar"}>
                  <li>{t('Solution.Customer')}</li>
                  <li>{t('Solution.Track')} / {t('Bowling')}</li>
                </ul>
              </div>
            </div>
          </Loisirs>
          <Contacter>
            <img className="web" src="/images/extraLaserBowling/Group 20165.png" alt="Group 20165" /><br />
            <img className="tablet" src="/images/extraLaserBowling/Group 38848.svg" alt="Group 38848" /><br />
            <img className="mobile" src="/images/extraLaserBowling/Group 38849.svg" alt="Group 38849" /><br />
            <h2>{t('Solution.interested')}</h2>
            <a className="link" href="https://outlook.office365.com/owa/calendar/OXTONDIGITAL@oxton-digital.com/bookings/s/r2PU4vNFNEeV21nViyrqbA2" target="_blank"><button className={langue === "ar" ? "btn btn-light button button-ar contactez" : "btn btn-light button contactez"}><span className="pseudo-text">{t('Solution.Contact')}</span></button></a>
          </Contacter>
          <Avantages>
            <h2 className={langue === "ar" && "alignRightMobile"}>{t('Solution.Benefits')}</h2>
            <div className="row gy-4">
              <div className="col-md-3">
                <div className="card">
                  <img className="logos" src="/images/extraLaserBowling/24-heures.svg" alt="24-heures" />
                  <label>{t('Solution.support')}</label>
                  <p className="card-body">
                    {t('Solution.maintained')}</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card">
                  <img className="logos" src="/images/extraLaserBowling/assurance-sante (1).svg" alt="assurance-sante" />
                  <label>{t('Solution.Monitoring')} </label>
                  <p className="card-body">
                    {t('Solution.monitor')}</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card">
                  <img className="logos" src="/images/extraLaserBowling/assurance-sante (1).svg" alt="assurance-sante" />
                  <label>{t('Solution.Update')}</label>
                  <p className="card-body">
                    {t('Solution.transparent')}</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card">
                  <img className="logos" src="/images/extraLaserBowling/Group 19517.svg" alt="Group 19517" />
                  <label>{t('Solution.Partnerships')}</label>
                  <p className="card-body">
                    {t('Solution.benefit')}</p>
                </div>
              </div>
            </div>
          </Avantages>
        </Background>

        <Client>
          <div >
            <ul className={langue === "ar" && "ul-anim-ar"}>
              <li className="text">{t('Solution.DigBowl')}</li>
              <li>
                <Link to={`/notre-solution-bowling/space-bowling`}>
                  <img className="planet" src="/images/planetBowling/Mask Group 621.png" alt="Mask Group 621" />
                </Link>
              </li>
              <li className="text">{t('Solution.DigBowl')}</li>
              <li>
                <Link to={`/notre-solution-bowling/planet-bowling`}>
                  <img className="planet" src="/images/planetBowling/Mask Group 620.png" alt="Mask Group 620" />
                </Link>
              </li>
              <li className="text">{t('Solution.DigBowl')}</li>
              <li>
                <Link to={`/notre-solution-bowling/bowling-de-limoges`}>
                  <img className="planet" src="/images/extraLaserBowling/Mask Group 525.svg" alt="Mask Group 525" />
                </Link>
              </li>
              <li className="text">{t('Solution.DigBowl')}</li>
              <li>
                <Link to={`/notre-solution-bowling/extra-laser-bowling`}>
                  <img className="planet" src="/images/planetBowling/Mask Group 638.png" alt="Mask Group 638" />
                </Link>
              </li>
              <li className="text">{t('Solution.DigBowl')}</li>
              <li>
                <Link to={`/notre-solution-bowling/space-bowling`}>
                  <img className="planet" src="/images/planetBowling/Mask Group 621.png" alt="Mask Group 621" />
                </Link>
              </li>
              <li className="text">{t('Solution.DigBowl')}</li>
              <li>
                <Link to={`/notre-solution-bowling/planet-bowling`}>
                  <img className="planet" src="/images/planetBowling/Mask Group 620.png" alt="Mask Group 620" />
                </Link>
              </li>
              <li className="text">{t('Solution.DigBowl')}</li>
              <li>
                <Link to={`/notre-solution-bowling/bowling-de-limoges`}>
                  <img className="planet" src="/images/extraLaserBowling/Mask Group 525.svg" alt="Mask Group 525" />
                </Link>
              </li>
              <li className="text">{t('Solution.DigBowl')}</li>
              <li>
                <Link to={`/notre-solution-bowling/extra-laser-bowling`}>
                  <img className="planet" src="/images/planetBowling/Mask Group 638.png" alt="Mask Group 638" />
                </Link>
              </li>
              <li className="text">{t('Solution.DigBowl')}</li>
              <li>
                <Link to={`/notre-solution-bowling/space-bowling`}>
                  <img className="planet" src="/images/planetBowling/Mask Group 621.png" alt="Mask Group 621" />
                </Link>
              </li>
              <li className="text">{t('Solution.DigBowl')}</li>
              <li>
                <Link to={`/notre-solution-bowling/planet-bowling`}>
                  <img className="planet" src="/images/planetBowling/Mask Group 620.png" alt="Mask Group 620" />
                </Link>
              </li>
              <li className="text">{t('Solution.DigBowl')}</li>
              <li>
                <Link to={`/notre-solution-bowling/bowling-de-limoges`}>
                  <img className="planet" src="/images/extraLaserBowling/Mask Group 525.svg" alt="Mask Group 525" />
                </Link>
              </li>
              <li className="text">{t('Solution.DigBowl')}</li>
              <li>
                <Link to={`/notre-solution-bowling/extra-laser-bowling`}>
                  <img className="planet" src="/images/planetBowling/Mask Group 638.png" alt="Mask Group 638" />
                </Link>
              </li>
            </ul>
          </div>
        </Client>
      </div>
    </>)
}

const Details = styled.div`
  margin:0 15% 60px 15% ;
  @media (max-width:991px) {
    margin:0 10% 60px 10% ;
  }
  color:black;
  @media (max-width: 1324px){
    justify-content: center;
  }
  @media (max-width: 413px){
  margin:0 10% 30px 10% ; 
  }
  >div{
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    padding-bottom: 50px;
  }
  >.secondeDiv{
    >.left{
      padding:40px 40px 0px 0px;
      @media (max-width: 870px){
        padding:0px 0px 0px 0px;
      }
    }
    @media (max-width:870px) {
      flex-direction: column-reverse;
    }
  }
  p{
    font-size: 22px;
    font-family: EurostileExtendedBold;
  }
  >div .left{
    line-height: 28px;
    width: 45%;
    min-width: 13rem;
    padding:40px 40px 0px 40px;
    >h3{
      color:black;
    }
    @media (max-width: 870px){
      width:100% !important;
      padding:0px 0px 0px 0px;
    }
    >p{
        font-weight: bold;
    }
    >a >.voir{
      margin-top:20px;
      margin-left: 0px;
      padding:10px 50px !important;
      background-color: #B61111;
      color:white;
      border-radius: 15px;
      font-weight: 400;
    }
  }
  >div .right{
    padding-top: 30px ;
    width: 55%;
    min-width: 13rem;
    >div{
      border-radius: 15px;
      height: 100%;
      display: flex;
      align-items: center;
      >img{
        width: 100%;
      }
    }
    @media (max-width: 870px){
      width:100% !important;
    }
  }
`;

const Background = styled.div`
  margin-bottom: 30px;
  background-image: url('/images/extraLaserBowling/Mask Group 634.png');
  background-repeat: no-repeat;
  background-size: cover;
`
const Loisirs = styled.div`
  padding:0px 15% 90px 15%;
  @media (max-width: 991px) {
    padding: 0px 10%;
  }
  color:white;
  >h2{
    padding-top: 70px;
    text-align: center;
    padding-bottom: 40px;
  }
  >.row {
    --bs-gutter-y: -14px;
    >div {
      >ul {
        list-style-image:url('/images/extraLaserBowling/Path 4532.svg');
        li{ 
        padding-bottom: 20px; 
        padding-left: 20px;
        }
      }
      >.ul-ar{
        list-style-image: url('/images/icons/arrow left red.svg');
      }
    }
  }
  >.background{
    position: absolute;
    left:0;
    width: 100%;
    height: fit-content;
    z-index: -1;;
  }
`;

const Avantages = styled.div`
  padding:0px 15% 60px 15% ;
  color: white;
  @media (max-width: 991px) {
    padding:0px 10% 60px 10%;
  }
  >h2{
    text-align: center;
    padding-bottom: 60px; 
    @media (max-width: 500px) {
      padding-bottom: 40px; 
    }
  }
  >.row{
      font-size:14px;
      padding: 0;
      @media (max-width: 766px){
      }
      >div{
        flex-basis: 25%;
        @media (max-width: 946px) and (min-width: 480px){
          flex-basis: 50%;
        }
        @media (max-width: 480px){
          flex-basis: 100%;
          
        }
        >.card{
          padding:20px 20px 0 20px;
          align-items: flex-start;
          border: 0;
          border-radius: 15px;
          box-shadow: 0px 0px 8px rgba(23, 64, 111, .20);
          background-color: white;
          color: black;
          height:100%;
          >label{
            font-family: EurostileExtendedBold;
            font-size: 16px;
          }
          >.card-body{
            padding-left: 0;
          }
          >.logos{
            height: 32px;
            margin-bottom: 20px;
          }
        }
      }
    
  }
`;

export default withTranslation()(ExtraLaserBowling)
