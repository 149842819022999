import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Title } from "../../../css/commun";
import { Description, Client, Contacter } from "../../../css/Bowling";
import { Link } from 'react-router-dom';
import { URL_Back, URL_Dev } from "../Variables";
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";

const SpaceBowling = (props) => {
  const title = props.location.pathname
  const splitState = title.split('/')
  const { t } = props
  const [pages, setPages] = useState([]);
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'

  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setPages(result)
        },
      )
  }, [])

  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[3] : splitState[2]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description, element.date, element.excerpt.rendered)))

  let titre = page[0] !== undefined ? page[0].title.replace("&#8217;", "'") : "...Loading"

  return (
    <>
      <Helmet>
        <title>{titre}</title>
        <meta name="description" content={page[0] !== undefined && page[0].description} />
        <link rel="canonical" href="https://oxton-digital.com/notre-solution-bowling/space-bowling" />
      </Helmet>
      <div className="container-fluid" >
        <Title>
          <h1>
            {titre}
          </h1>
          <p>
            <img className={langue === "ar" && "img-accueil-ar"} src="/images/accueil.png" alt="accueil" />
            <a className="retour" href="/">{t('Home_link')}</a> /
            {langue === "fr"
              ? <a className="retour" href="/fr/notre-solution-bowling">Notre Solution Bowling</a>
              : langue === "en" ? <a className="retour" href="/en/our-bowling-solution">Our Bowling Solution</a>
                : <a className="retour" href="/ar/حل-البولينج-لدينا"> حل البولينج لدينا</a>
            } / <span>{titre}</span>
          </p>
        </Title>

        <Description>
          <img className="mainImage" src="/images/spaceBowling/mockup_space.png" alt="mockup" />
          <div className="sBowling">
            <h2 className={langue === "ar" && "alignRightMobile"}>{t('Solution.Solutions')}</h2>
            <p className={langue === "ar" && "alignRightMobile"}>
              {t('Space.located')}
            </p>
            <div className="technologies">
              <bdi>
                <img src="/images/portfolio/html-5.svg" alt="HTML" /><span>HTML</span>
                <img src="/images/portfolio/css-3.svg" alt="css" /><span>CSS</span>
                <img src="/images/portfolio/js.svg" alt="js" /><span>JS</span>
              </bdi>
            </div>
          </div>
        </Description>
        <Details>
          <div>
            <div className="right">
              <div>
                <img src="/images/spaceBowling/mockup_spacell.png" alt="mockup_spacell" />
              </div>
            </div>
            <div className="left">
              <h3>{t('Solution.Web')}</h3>
              <span>{t('Space.reservations')}</span> <br />
              <a href="https://spacebowling.fr/" target="_blank"><button className={langue ? "btn btn-light button button-ar voir" : "btn btn-light button voir"}><span className="pseudo-text">{t('Solution.website')}</span></button></a>
            </div>
          </div>
          <div className="secondeDiv">
            <div className="left">
              <h3>{t('Solution.Mobile')}</h3>
              <span>{t('Solution.operations')}</span><br />
            </div>
            <div className="right">
              <div>
                <img src="/images/spaceBowling/mockup_spacejj.png" alt="mockup_spacejj" />
              </div>
            </div>
          </div>
          <div>
            <div className="right">
              <div>
                <img src="/images/spaceBowling/ooo-01.png" alt="borne mockup-01" />
              </div>
            </div>
            <div className="left">
              <h3>{t('Solution.Kiosk')}</h3>
              <span>{t('Space.Payment')}</span>
            </div>
          </div>

        </Details>
        <Loisirs>
          <h2 className={langue === "ar" && "alignRightMobile"}>{t('Solution.helps')}</h2>
          <div className="row gy-3">
            <div className="col-md-4">
              <ul className={langue === "ar" && "ul-ar"}>
                <li>{t('Solution.Email')}</li>
                <li>{t('Solution.Slot')} </li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul className={langue === "ar" && "ul-ar"}>
                <li>{t('Solution.Offer')}</li>
                <li>{t('Solution.export')} </li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul className={langue === "ar" && "ul-ar"}>
                <li>{t('Solution.Customer')}</li>
                <li>{t('Solution.Track')} / {t('Bowling')}</li>
              </ul>
            </div>
          </div>
        </Loisirs>
        <Contacter>
          <img className="web" src="/images/spaceBowling/Group 38847.png" alt="Group 38847" /><br />
          <img className="tablet" src="/images/spaceBowling/Group 38852.svg" alt="Group 38852" /><br />
          <img className="mobile" src="/images/spaceBowling/Group 38944.svg" alt="Group 38944" /><br />
          <h2 className="bowlingH">{t('Solution.interested')}</h2>
          <a className="link" href="https://outlook.office365.com/owa/calendar/OXTONDIGITAL@oxton-digital.com/bookings/s/r2PU4vNFNEeV21nViyrqbA2" target="_blank"><button className={langue === "ar" ? "btn btn-light button button-ar contactez" : "btn btn-light button contactez"}><span className="pseudo-text">{t('Solution.Contact')}</span></button></a>
        </Contacter>
        <Avantages>
          <h2 className={langue === "ar" && "alignRightMobile"}>{t('Solution.Benefits')}</h2>
          <div className="row gy-4">
            <div className="col-md-3">
              <div className="card">
                <img className="logos" src="/images/spaceBowling/24-heures.svg" alt="24-heures" />
                <label>{t('Solution.support')}</label>
                <p className="card-body">
                  {t('Solution.maintained')}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card">
                <img className="logos" src="/images/spaceBowling/assurance-sante (1).svg" alt="assurance-sante" />
                <label>{t('Solution.Monitoring')} </label>
                <p className="card-body">
                  {t('Solution.monitor')}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card">
                <img className="logos" src="/images/spaceBowling/assurance-sante (1).svg" alt="assurance-sante" />
                <label>{t('Solution.Update')}</label>
                <p className="card-body">
                  {t('Solution.transparent')}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card">
                <img className="logos" src="/images/spaceBowling/Group 19637.svg" alt="Group 19637" />
                <label>{t('Solution.Partnerships')}</label>
                <p className="card-body">
                  {t('Solution.benefit')}</p>
              </div>
            </div>
          </div>
        </Avantages>
        <Client>
          <div >
            <ul className={langue === "ar" && "ul-anim-ar"}>
              <li className="text">{t('Solution.DigBowl')}</li>
              <li>
                <Link to={`/notre-solution-bowling/space-bowling`}>
                  <img className="planet" src="/images/planetBowling/Mask Group 621.png" alt="Mask Group 621" />
                </Link>
              </li>
              <li className="text">{t('Solution.DigBowl')}</li>
              <li>
                <Link to={`/notre-solution-bowling/planet-bowling`}>
                  <img className="planet" src="/images/planetBowling/Mask Group 620.png" alt="Mask Group 620" />
                </Link>
              </li>
              <li className="text">{t('Solution.DigBowl')}</li>
              <li>
                <Link to={`/notre-solution-bowling/bowling-de-limoges`}>
                  <img className="planet" src="/images/extraLaserBowling/Mask Group 525.svg" alt="Mask Group 525" />
                </Link>
              </li>
              <li className="text">{t('Solution.DigBowl')}</li>
              <li>
                <Link to={`/notre-solution-bowling/extra-laser-bowling`}>
                  <img className="planet" src="/images/planetBowling/Mask Group 638.png" alt="Mask Group 638" />
                </Link>
              </li>
              <li className="text">{t('Solution.DigBowl')}</li>
              <li>
                <Link to={`/notre-solution-bowling/space-bowling`}>
                  <img className="planet" src="/images/planetBowling/Mask Group 621.png" alt="Mask Group 621" />
                </Link>
              </li>
              <li className="text">{t('Solution.DigBowl')}</li>
              <li>
                <Link to={`/notre-solution-bowling/planet-bowling`}>
                  <img className="planet" src="/images/planetBowling/Mask Group 620.png" alt="Mask Group 620" />
                </Link>
              </li>
              <li className="text">{t('Solution.DigBowl')}</li>
              <li>
                <Link to={`/notre-solution-bowling/bowling-de-limoges`}>
                  <img className="planet" src="/images/extraLaserBowling/Mask Group 525.svg" alt="Mask Group 525" />
                </Link>
              </li>
              <li className="text">{t('Solution.DigBowl')}</li>
              <li>
                <Link to={`/notre-solution-bowling/extra-laser-bowling`}>
                  <img className="planet" src="/images/planetBowling/Mask Group 638.png" alt="Mask Group 638" />
                </Link>
              </li>
              <li className="text">{t('Solution.DigBowl')}</li>
              <li>
                <Link to={`/notre-solution-bowling/space-bowling`}>
                  <img className="planet" src="/images/planetBowling/Mask Group 621.png" alt="Mask Group 621" />
                </Link>
              </li>
              <li className="text">{t('Solution.DigBowl')}</li>
              <li>
                <Link to={`/notre-solution-bowling/planet-bowling`}>
                  <img className="planet" src="/images/planetBowling/Mask Group 620.png" alt="Mask Group 620" />
                </Link>
              </li>
              <li className="text">{t('Solution.DigBowl')}</li>
              <li>
                <Link to={`/notre-solution-bowling/bowling-de-limoges`}>
                  <img className="planet" src="/images/extraLaserBowling/Mask Group 525.svg" alt="Mask Group 525" />
                </Link>
              </li>
              <li className="text">{t('Solution.DigBowl')}</li>
              <li>
                <Link to={`/notre-solution-bowling/extra-laser-bowling`}>
                  <img className="planet" src="/images/planetBowling/Mask Group 638.png" alt="Mask Group 638" />
                </Link>
              </li>
            </ul>
          </div>
        </Client>
      </div>
    </>)
}


const Details = styled.div`
  margin:30px 15% 0px 15% ;
  @media (max-width:991px) {
    margin:30px 10% 0px 10% ;
  }
  >div{
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    padding-bottom: 10px;
    margin-bottom: 30px;
    background-color: #E9EEF7;
    border-radius: 15px;
  }
  >.secondeDiv{
    >.left{
      padding:40px 40px 40px 40px;
      @media (max-width: 870px){
        padding:0px 30px 40px 30px;
      }
    }
    @media (max-width:870px) {
      flex-direction: column-reverse;
    }
    >.right{
      padding:40px 40px 0px 0px;
      @media (max-width:1303px) and (min-width: 871px){
        padding:40px 0px 0px 0px;
        position: relative;
        right:40px;
      }  
      
    }
  }
  @media (max-width: 1324px){
    justify-content: center;
  }
  @media (max-width: 413px){
  margin:0 10% 30px 10% ; 
  }

    p{
      font-size: 22px;
      font-family: EurostileExtendedBold;
    }

    >div .left{
      line-height: 28px;
      width: 45%;
      min-width: 13rem;
      padding:40px 30px 30px 40px;
      @media (max-width: 870px){
        width:100% !important;
        padding:0px 30px 30px 30px;
      }
      >p{
          font-weight: bold;
      }
      >a >.voir{
        margin-top:20px;
        margin-left: 0;
        padding: 10px 40px !important;
        background-color: #F6202C;
        color: white;
        border-radius: 15px;
        font-weight: 400;
        padding: 10px 40px !important;
      }
    }

    >div .right{
      padding: 30px 0px 30px 40px;
      width: 55%;
      min-width: 13rem;
      >div{
        border-radius: 15px;
        height: 100%;
        display: flex;
        align-items: center;
        >img{
          width: 100%;
        }
      }
      @media (max-width: 870px){
        width:100% !important;
      }
    }
`;

const Loisirs = styled.div`
  padding:0px 15% 90px 15%;
  @media (max-width: 991px) {
    padding: 0px 10%;
  }
  >h2{
    padding-top: 30px;
    color: #2B2E71;
    text-align: center;
    padding-bottom: 40px;
  }
  >.row {
    --bs-gutter-y: -14px;
    >div {
      >ul {
        list-style-image:url('/images/spaceBowling/Path 4532.svg');
        li{ 
        padding-bottom: 20px; 
        padding-left: 20px;
        }
      }
      >.ul-ar{
        list-style-image: url('/images/icons/arrow left blue.svg');
      }
    }
  }
  >.background{
    position: absolute;
    left:0;
    width: 100%;
    height: fit-content;
    z-index: -1;;
  }
`;

const Avantages = styled.div`
  padding:0px 15% 60px 15% ;
  color: #2B2E71;
  @media (max-width: 991px) {
    padding:0px 10% 60px 10%;
  }
  >h2{
    text-align: center;
    padding-bottom: 60px;
    @media (max-width: 500px) {
      padding-bottom: 40px; 
    } 
  }
  >.row{
    font-size:14px;
    padding: 0;
    @media (max-width: 766px){
    }
    >div{
      flex-basis: 25%;
      @media (max-width: 946px) and (min-width: 480px){
        flex-basis: 50%;
      }
      @media (max-width: 480px){
        flex-basis: 100%;
        
      }
      >.card{
        padding:20px 20px 0 20px;
        align-items: flex-start;
        border: 0;
        height: 100%;
        border-radius: 15px;
        box-shadow: 0px 0px 8px rgba(23, 64, 111, .20);
        background-color: #E9EEF7;
        >label{
          font-family: EurostileExtendedBold;
          font-size: 14px;
        }
        >.card-body{
          padding-left: 0;
        }
        >.logos{
          height: 32px;
          margin-bottom: 20px;
        }
      }
    }
  
  }
`;

export default withTranslation()(SpaceBowling)
