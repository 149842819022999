import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Portfolio from "../../../Model/Portfolio";
import styled from "styled-components";
import { Title } from "../../../css/commun";
import { URL_Back, URL_Dev } from "../Variables";
import { ListPortfolio } from "./ListPortfolio";
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie"; import { TailSpin } from 'react-loader-spinner';

const Portfolios = (props) => {
  const title = props.location.pathname
  const splitState = title.split('/')

  const { t } = props

  const [items, setItems] = useState([]);
  const [itemsFiltred, setItemsFiltred] = useState([]);
  const [filterLabel, setFilterLabel] = useState('')
  const [number, setNumber] = useState(12)
  const [pages, setPages] = useState([]);
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'
  const [loading, setLoading] = useState('true')

  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])

  const scrollToEnd = () => {
    setNumber(number + 3)
  }

  window.onscroll = function () {
    if (window.innerHeight + document.documentElement.scrollTop > (document.documentElement.offsetHeight - 300)) {
      scrollToEnd()
    }
  }

  useEffect(() => {

    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    fetch(`${URL_Back}/wp-json/wp/v2/portfolio?_embed&per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setItems(result)
        },
      )

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setPages(result)
        },
      )

    const filterLabel = splitState[1].length === 2 ? splitState[3] : splitState[2]
    filterLabel === undefined && (filterLabel = localStorage.getItem('filterLabel'))
    var lng = currentLanguage
    lng === undefined && localStorage.getItem("lng")
    setFilterLabel(filterLabel)
    setLangue(currentLanguage)

    fetch(`https://back-end.oxton-digital.com//wp-json/wp/v2/portfolio?_embed&per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          for (var i = 0; i < result.length; i++) {
            if (result[i].excerpt.rendered === (lng === "fr" ? "<p>French</p>\n" : "<p>English</p>\n")) {
              ((result[i].content.rendered.toUpperCase()).indexOf(filterLabel.toUpperCase()) > -1)
                && setItemsFiltred(itemsFiltred => [...itemsFiltred, result[i]]);
            }
          }
          setLoading(false)
        }
      )
  }, [])

  let portfolio = [];
  itemsFiltred.map(element => element._embedded['wp:featuredmedia'] ?
    portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element._embedded['wp:featuredmedia'][0].source_url, element.excerpt.rendered))
    : portfolio.push(new Portfolio(element.id, element.title.rendered, element.content.rendered, element.slug, element.date, element.excerpt.rendered))
  )

  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description, element.date, element.excerpt.rendered)))

  let titre = page[0] !== undefined ? page[0].title.replace("&#8217;", "'") : "...Loading"

  return (
    <>
      <Helmet>
        <title>{titre}</title>
        <meta name="description" content={page[0] !== undefined && page[0].description} />
        <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
      </Helmet>
      <div className="container-fluid" >
        <Title>
          <h1>{filterLabel}</h1>
          <p> <img className={langue === "ar" && "img-accueil-ar"} src="/images/accueil.png" alt="accueil" />
            <Link className="retour" to="/">{t('Home_link')}</Link> / <span>{filterLabel}</span></p>
        </Title>

        <Table>
          {loading
            ? <div className="loading">
              <TailSpin color="#17406F" />
            </div>
            : <>
              <ListPortfolio portfolio={portfolio} number={number} langue={langue} />
              {(number < itemsFiltred.length) ? <img className="arrow" src="/images/portfolio/Group 20195.svg" alt="arrow" onClick={() => setNumber(number + 9)} />
                : number < portfolio.length && <img className="arrow" src="/images/portfolio/Group 20195.svg" alt="arrow" onClick={() => setNumber(number + 3)} />
              }
            </>
          }

        </Table>
      </div >
    </>)
}

const Table = styled.div`
  padding:32px 0px 60px 0px;
  text-align-last: center; 
  >.loading{
    display: flex;
    justify-content: center;
  } 
  >.table{
    padding: 0 15%;
    display:flex ;
    flex-direction:row ;
    justify-content:center ;
    @media (max-width: 991px){
      padding: 0 10% !important;
    }
    @media (max-width: 1018px){
      justify-content: center;
    }
    
    >.link{
      text-align: center;
      padding: 0 11px 22px 11px;
      border: 0;
      width: 25%;
      min-width:17rem;
      @media (max-width:1555px) {
        width: 33%;
      }
      @media (min-width: 815px) and (max-width: 1187px) {
        width: 50%
      }
      @media (max-width: 814px) {
        width: 100%
      }
      >.card{
        border-radius: 15px;
        overflow: hidden;
        border: 1px solid #ECF6FB;
        background-color: #ECF6FB;
        @media (min-width: 814px) {
          height: 100%;
        }
        >.image{
          padding: 0;
          height: 250px;
          @media (max-width:2100px) {
            height: 200px;
          }
          @media (max-width:814px) {
            height: 350px;
          }
          @media (max-width:550px) {
            height: 300px;
          }
          @media (max-width:440px) {
            height: 250px;
          }
        }
        >.image >img{
          padding:0;
          width:100%;
          height: 100%;
          object-fit: cover;
          border-top:20px;
        } 
        >.card-body{
          height: auto;
          background-color: #ECF6FB;

          >.card-header {
            background-color: transparent;
            border: 0;
            padding: 0.2rem 0;
            h3{
            font-size: 14px !important;
            line-height: 24px !important;
            color: #17406F;
            padding-top: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            font-weight: 600;
            margin-bottom: 0;
            }
          }
          >p{
            position: relative;
            >.url{
            font-weight: 600;
            }
            >span{
              color: #17406F;
            }
          }
        }
      }
    }
  }
  >img{
    padding-top: 30px;
  }

  >.arrow{
    cursor: pointer;
  }
`;

export default withTranslation()(Portfolios)
