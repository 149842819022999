import React from 'react'
import img1 from '../../photos/soon1.svg'
import img2 from '../../photos/soon2.svg'
import './Soon.css'

const  Soon=()=> {
  return (
    <div className='container '>
      <div className='soon-container'>
        <img  src={img1}/>
        <img src={img2}/>
      </div>
    </div>
  )
}

export default Soon