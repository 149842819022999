import React, { useState, useEffect } from "react";
import Cf7FormWrapper from "./Cf7FormWrapper";
import SubPostuler from "./SubPostuler";

const Postuler = (props) => {
  return (
    <Cf7FormWrapper id="216">
      <SubPostuler offrePostuler={props} />
    </Cf7FormWrapper>
  )
}

export default Postuler
