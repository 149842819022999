import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Pages from "../Model/Pages";
import { URL_Back, URL_Dev } from "./Pages/Variables";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";


const InvalidPath = (props) => {
  const { t } = props
  const [pages, setPages] = useState([]);
  const [langue, setLangue] = useState("fr");

  const currentLanguage = cookies.get('i18next') || 'fr'

  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);
    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setPages(result)
        },
      )
  }, [])

  let page = []
  pages.map(element =>
    element.slug === "404-not-found" &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description, element.date, element.excerpt.rendered)))

  let titre = page[0] !== undefined ? page[0].title.replace("&#8217;", "'") : "...Loading"

  return (<>
    <Helmet>
      <title>{titre}</title>
      <meta name="description" content={page[0] !== undefined && page[0].description} />
      <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
    </Helmet>
    <div className="container-fluid" >
      <Error>
        <h1>{t('404.Oops')}</h1>
        <p>{t('404.looking')}</p><br />
        <Link to={`/`} className="text-black text-decoration-none link">
          <button className="btn btn-light home">{t('404.Back')}</button>
        </Link>

      </Error>
      <Introuvable>
        <div>
          <ul>
            <li className="number">404</li>
            <li className="introuvable">{t('404.NOT_FOUND')}</li>
            <li className="number">404 </li>
            <li className="introuvable">{t('404.NOT_FOUND')}</li>
            <li className="number">404 </li>
            <li className="introuvable">{t('404.NOT_FOUND')}</li>
            <li className="number">404 </li>
            <li className="introuvable">{t('404.NOT_FOUND')}</li>
            <li className="number">404 </li>
            <li className="introuvable">{t('404.NOT_FOUND')}</li>
            <li className="number">404 </li>
            <li className="introuvable">{t('404.NOT_FOUND')}</li>
            <li className="number">404 </li>
            <li className="introuvable">{t('404.NOT_FOUND')}</li>
            <li className="number">404 </li>
            <li className="introuvable">{t('404.NOT_FOUND')}</li>
          </ul>
        </div>
      </Introuvable>
    </div>
  </>
  )
}

const Error = styled.div`
  padding: 150px 15% 30px 15%;
  text-align: center;
  @media (max-width:991px) {
    text-align:left;
  }
  >h1{
    color:#20ACE3;
    font-family: EurostileExtendedBold;
  }
  >p{
    padding-top: 30px;
    font-size: 20px;
    font-weight: 600;
  }
  >.link{
    >.home{
    background-color: #20ACE3;
    color:white;
    border-radius: 15px;
    width: 200px;
    }
  } 
`;

const Introuvable = styled.div`
  padding: 20px 90px 10px 40px;
  margin-top:50px;
  background-color:#17406F;
  color: white;
  z-index: 9;
  @media (max-width: 500px) {
    padding: 20px 10px 10px 10px;
    >div{
    overflow: hidden;
    >ul{
      padding-left: 0;
    }
    }
  }
  >div{
    overflow: hidden;
    >ul{
    display: flex;
    width: 120%;
    list-style-type: none;
    font-family: EurostileExtendedBold;
    >li{
      padding-right: 20px;
    }
    letter-spacing: 1px;
    >li.number{
      color: #20ACE3;
    }
    >.introuvable{
      min-width: 290px;
    }
  }
  }`;

export default withTranslation()(InvalidPath)
