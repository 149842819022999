import React, { useEffect, useState } from "react";
import styled from "styled-components"
import { Title } from "../../../css/commun";
import { URL_Back, URL_Dev } from "../Variables";
import { Link } from 'react-router-dom';
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";

const MontionsLegales = (props) => {
  const title = props.location.pathname
  const staticTitles = {
    en: "Mentions Légales",
    fr: "Mentions Légales"
  };
  const splitState = title.split('/')
  const [pages, setPages] = useState([]);
  const { t } = props
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'
  const [titre, setTitre] = useState(staticTitles[currentLanguage]);
  const [lastChecked, setLastChecked] = useState(null);


  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])

  const cacheTitle = (result) => {
    const page = result.find(element =>
      decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1])
    );

    if (page) {
      const title = page.title.rendered.replace("&#8217;", "'");
      if (title !== titre) {
        setTitre(title);
      }
      setLastChecked(new Date().getTime()); 
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    const oneMonth = 30 * 24 * 60 * 60 * 1000;
    const lastCheckedTimestamp = lastChecked || 0;

    if (new Date().getTime() - lastCheckedTimestamp >= oneMonth) {
      fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
        .then(res => res.json())
        .then((result) => {
          setPages(result);
          cacheTitle(result);
        });
    }
  }, [lastChecked]);

  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ? splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description, element.date, element.excerpt.rendered)))

  

  return (
    <>
      <Helmet>
        <title>{titre}</title>
        <meta name="description" content={page[0] !== undefined && page[0].description} />
        <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
      </Helmet>
      <div className="container" >
        {/* {splitState[1] === "fr" &&
          <> */}
            <Title>
              <h1>{titre}</h1>
              {/* <p>
                <img className={langue === "ar" ? "img-accueil-ar" : ""} src="/images/accueil.png" alt="accueil" />
                <Link className="retour" to="/">{t('Home_link')}</Link> / {page[0] !== undefined && <span>{titre}</span>}
              </p> */}
            </Title>
            <Body>
              <p>
                Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique, dite L.C.E.N., il est porté à la connaissance des utilisateurs et visiteurs, ci-après l""Utilisateur", du site oxton-digital.com , ci-après le "Site", les présentes mentions légales.
                La connexion et la navigation sur le Site par l’Utilisateur implique acceptation intégrale et sans réserve des présentes mentions légales.
                Ces dernières sont accessibles sur le Site à la rubrique « Mentions légales ».
              </p>
              <h2>ARTICLE 1 - L'EDITEUR</h2>
              <p>
                L'édition du Site est assurée par OXTON DIGITAL SAS au capital de 1000 euros, immatriculée au Registre du Commerce et des Sociétés de CERGY-PONTOISE
                ci-après l'"Editeur".
              </p>

              <h2>ARTICLE 2 - L'HEBERGEUR</h2>
              <p>
                L'hébergeur du Site est la société OXTON DIGITAL
              </p>

              <h2>ARTICLE 3 - ACCES AU SITE</h2>
              <p>
                Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de force majeure, interruption programmée ou non et pouvant découlant d’une nécessité de maintenance.
                En cas de modification, interruption ou suspension du Site, l'Editeur ne saurait être tenu responsable.
              </p>

              <h2>
                ARTICLE 4 - COLLECTE DES DONNEES
              </h2>

              <p>
                Le site est exempté de déclaration à la Commission Nationale Informatique et Libertés (CNIL) dans la mesure où il ne collecte aucune donnée concernant les utilisateurs.
                Toute utilisation, reproduction, diffusion, commercialisation, modification de toute ou partie du Site﻿, sans autorisation de l’Editeur est prohibée et pourra entraînée des actions et poursuites judiciaires telles que notamment prévues par le Code de la propriété intellectuelle et le Code civil.
              </p>

            </Body>
          {/* </>
        } */}
      </div>
    </>)
}

const Body = styled.div`
  // padding: 15px 15%;
  @media(max-width: 575px){
    padding:0 20px;
  }
  >h2{
    font-size: 20px !important;
  }
`


export default withTranslation()(MontionsLegales)