import { Link } from 'react-router-dom';


export const ListPortfolio = (props) => {

  const portfolio = props.portfolio;
  const number = props.number;
  const langue = props.langue;

  return (
    <div className="d-flex flex-wrap table">
      {portfolio.map((item, i) => (i < number) && (
        <Link to={
          langue === "fr"
            ? { pathname: `/fr/details-de-portfolio/${(item.slug.replace(/[^a-zA-Z0-9.-]/g, ""))}`, state: item }
            : langue === "en"
              ? { pathname: `/en/details-of-portfolio/${(item.slug.replace(/[^a-zA-Z0-9.-]/g, ""))}`, state: item }
              : { pathname: `/ar/تفاصيل-الملف/${(item.slug.replace(/[^a-zA-Z0-9.-]/g, ""))}`, state: item }
        }
          className="text-black text-decoration-none link" key={item.id} >
          <div className="card">
            <div className='image'>
              {item.image !== undefined && <img src={item.image} alt={item.title} />}
            </div>
            <div className="card-body">
              <div className='card-header d-flex align-items-center gap-4'>

                <h3 className="card-title" dangerouslySetInnerHTML={{ __html: item.title }} />
                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="14" viewBox="0 0 29 14" fill="none">
<path d="M28.6677 6.20081C28.6674 6.20048 28.6671 6.20008 28.6667 6.19974L22.7475 0.328771C22.3041 -0.111044 21.5869 -0.109407 21.1455 0.332609C20.7041 0.774569 20.7058 1.48942 21.1493 1.92929L25.1234 5.87097H1.13281C0.50716 5.87097 0 6.37644 0 7C0 7.62356 0.50716 8.12903 1.13281 8.12903H25.1233L21.1493 12.0707C20.7059 12.5106 20.7042 13.2254 21.1455 13.6674C21.5869 14.1095 22.3042 14.111 22.7476 13.6712L28.6668 7.80026C28.6671 7.79992 28.6674 7.79952 28.6678 7.79919C29.1115 7.35785 29.11 6.64069 28.6677 6.20081Z" fill="#17406F"/>
</svg>
              </div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  )
}