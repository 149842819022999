import React, { useEffect, useState } from "react";
import styled from "styled-components"
import { Title } from "../../../css/commun";
import Offre from "../../../Model/Offre";
import { URL_Back, URL_Dev } from "../Variables";
import { Link } from 'react-router-dom';
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";

const Offres = (props) => {
  const title = props.location.pathname
  const splitState = title.split('/')
  const { t } = props
  const [items, setItems] = useState([]);
  const [pages, setPages] = useState([]);
  const [langue, setLangue] = useState("fr");
  const [loading, setLoading] = useState('true')
  const currentLanguage = cookies.get('i18next') || 'fr'

  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    fetch(`${URL_Back}/wp-json/wp/v2/offres?_embed&per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          var Offrelanguage = []
          currentLanguage === "fr"
            ? (result.map(element => { element.categories[0] === 68 && Offrelanguage.push(element) }))
            : currentLanguage === "en"
              ? (result.map(element => { element.categories[0] === 86 && Offrelanguage.push(element) }))
              : currentLanguage === "ar"
              && (result.map(element => { element.categories[0] === 84 && Offrelanguage.push(element) }))
          setItems(Offrelanguage)
          setLoading(false)
        }
      )

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setPages(result)
        },
      )
  }, [])

  let offre = [];
  items.map(element => (
    (langue === "fr" || langue === null ) && (element.categories !== undefined  && element.categories[0] === 68)) 
    ? offre.push(new Offre(element.id, element.title.rendered, element.content.rendered, element.date, element.excerpt.rendered, element.slug))
    : (langue === "en" && (element.categories !== undefined  && element.categories[0] === 86)) 
    ? offre.push(new Offre(element.id, element.title.rendered, element.content.rendered, element.date, element.excerpt.rendered, element.slug))
    : (langue === "ar" && (element.categories !== undefined  && element.categories[0] === 84)) 
    && offre.push(new Offre(element.id, element.title.rendered, element.content.rendered, element.date, element.excerpt.rendered, element.slug))
  )

  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ?  splitState[2] : splitState[1]) &&
    page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.description, element.date, element.excerpt.rendered)))
  let titre = page[0] !== undefined ? page[0].title.replace("&#8217;", "'") : "...Loading"

  return (
    <>
      <Helmet>
        <title>{titre}</title>
        <meta name="description" content={page[0] !== undefined && page[0].description} />
        <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
      </Helmet>
      <div className="container-fluid" >
        <Title>
          <h1>{titre}</h1>
          <p>
            <img className={langue === "ar" && "img-accueil-ar"} src="/images/accueil.png" alt="accueil" />
            <Link className="retour" to={`/${langue}`}>{t('Home_link')}</Link> / <span>{page[0] !== undefined && <span>{titre}</span>}</span>
          </p>
        </Title>
        <Details className={langue === "ar" && "details-ar"} >
          <div className="left">
            <img className="image1" src="/images/nosOffresD'emplois/Mask Group 669.png" alt="Mask Group 669" />
            <img className="image2" src="/images/nosOffresD'emplois/Mask Group 781.png" alt="Mask Group 781" />
          </div>
          <div className="right">
            <Discription>
              <h2 className={langue === "ar" && "alignRightMobile"}>{t('Offres.recruiting')}</h2>
              <p>{t('Offres.growing')}
              </p>
              <h3>{t('Offres.Available')}</h3>
              <p>{t('Offres.looking')}</p>
            <Link className="link" to={langue === "fr"? "/fr/recrutement" : langue === "en" ? "/en/recrutement" : "/ar/توظيف"}>
              <button className={langue === "ar" ? "btn btn-primary button button-ar joindre" : "btn btn-primary button joindre"}><span className="pseudo-text">{t('Offres.Join')}</span></button>
            </Link>
            </Discription>
          </div>
        </Details>
        <LesOffres>
          <h2  className={langue === "ar" && "alignRightMobile"}>{t('offers')}</h2>
          <div className="row gy-2 offre">
            {offre.map((item) => (
              <div className="col-md-4" key={item.id}>
                <Link to={{ pathname: langue === "fr" ? `/${langue}/details-de-loffres-demplois/${item.slug}` : langue === "an" ? `/${langue}/details-of-the-job-offer/${item.slug}` : `/${langue}/تفاصيل-عروض-العمل/${item.slug}` , state: item }} className="text-black text-decoration-none link">
                  <div className="card">
                    <div className="cercleOffre" />
                    <h3 className="card-header">{item.title}</h3>
                    <div className="card-body">
                      <div dangerouslySetInnerHTML={{ __html: item.excerpt.slice(3, -5) }} />
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </LesOffres>
      </div >
    </>)
}


const Details = styled.div.attrs(props => ({
  className: props.className,
}))`
  &.details-ar{
    flex-direction: row-reverse;
  }
  padding: 0 10%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 50px;
  @media (max-width:991px) {
    padding: 0px 6.5%;
    padding-top: 50px;
  }
  @media (max-width:600px) {
    padding: 50px 5% 0;
  }
  @media (max-width:600px) {
    padding: 50px 4% 0;
  }
  @media (max-width: 1334px){
    justify-content: center;
  }
  .left{
    width: 50%;
    min-width: 15rem;
    overflow: hidden;
    border-radius: 18px;
    >img{
      height: 100%;
    }
    .image2{
      display: none;
    }
    @media (max-width: 1025px){
      .image1{
        display: none;
      }
      .image2{
        display: block;
        width: 100%;
      }
    }
  }
    @media (max-width: 1025px){
      width: 100% !important;
      .left{
        width: 96%;
        margin-left: 25px;
      }
    }
    .right{
      width: 50%;
      @media (max-width: 1025px){
          padding-top: 30px;
          width: 100%;
          }
    }
`;

const Discription = styled.div`
  padding-left: 30px;
  line-height: 26px;
  >h2{
    padding-bottom: 16px;
   }
  >.joindre{
    margin-top:10px;
    margin-left: 0;
  }
  `;

const LesOffres = styled.div`
  padding: 0px 10% 40px 10%;
  >h2{
    text-align: center;
    padding: 100px 0 50px 0;
    @media (max-width:991px) {
      padding: 50px 0 20px 0;
    }
    @media (max-width: 640px) {
      text-align: left;
    }
      @media (max-width:500px) {
        padding: 40px 0 20px 0;
      }
    }
    >.row{
      justify-content: center;
      padding: 0;
      font-weight: 600;
      >div{
        padding-bottom: 20px;
        @media (max-width:725px) {
          width: 100%;
        }
        @media (max-width:1091px) and (min-width:726px) {
          width: 50%;
        }
        >.link .card{
          padding:20px 20px 30px 20px;
          border: 0;
          border-radius: 10px;
          box-shadow: 0px 0px 8px rgba(23, 64, 111, .20);
          overflow: hidden;
          height: 100%;
          height: 100%;
          >.cercleOffre{
            position: absolute;
            top: -14px;
            left:-15px;
            width: 90px;
            height: 90px;
            background-color: #ecf6fb;
            border-radius: 50%;
            z-index: 0;
          }
          >h3{
            z-index: 1;
            font-family:PoppinsBold;
          }
          >.card-header{
            display: flex;
            height: 100%;
            padding: 0;
            background-color: transparent;
            border-bottom: 0;
          }
          >.card-body{
            padding: 10px 0 0px;
            margin: 0;
            word-spacing: 10px;
            color:#20ACE3;
            font-weight:bold;
            z-index: 1;
          }
        }
      }
    }
`;

export default withTranslation()(Offres)