import React from 'react'
import { withTranslation } from "react-i18next";

const Blogging = (props) => {
  const { t } = props
  const starter = props.starter;
  const complet = props.complet;
  const expert = props.expert;
  const langue = props.langue;

  return (<div className="row gy-3">
    <div className="col-md-4">
      <div className="card">
        <ul className={langue === "ar" && "ul-ar"}>
          <div>
            <span className="euroextendedbold">{t("Pack_Starter")}</span><br />
            <span className="number"> {starter} <sup>€</sup></span> {t('Month')}
          </div>
          <li><h3>{t('Starting')}</h3></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Kick-off_meeting')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Blogging.Back_office')} </span></li>
          <hr />
          <li><h3>{t('Strategy')}</h3></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t("Blogging.Shared_working")}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Blogging.Quarterly_strategic')}</span></li>
          <hr />
          <li><h3>{t('Blogging.Blogging_campaign')}</h3> </li>
          <li><span>1 {t('article')} / {t('Month')} </span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Blogging.Professional_writing')} 700 {t('mots')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Blogging.Writing_of_metals')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Blogging.Photo_with_rights')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Blogging.CMS')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Internal_meshing')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Blogging.Relecture_corrections')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Blogging.Programming_publishing')}</span></li>
          <hr />
          <li><h3>{t('Options')} </h3> </li>
          <li><img src="/images/seo/Groupe de masques 786.svg" alt="X" /> <span>{t('Writing')} 1 00{t('Blogging.Programming_publishing')}</span></li>
          <li><img src="/images/seo/Groupe de masques 786.svg" alt="X" /> <span>{t('Blogging.Second_photo')}</span></li>
          <li><img src="/images/seo/Groupe de masques 786.svg" alt="X" /> <span>{t('Blogging.Additional_article')}</span></li>
          <li><img src="/images/seo/Groupe de masques 786.svg" alt="X" /> <span>{t('Blogging.Multilingual_campaign')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Blogging.strategic_topics')}</span></li>
          <hr />
        </ul>
        <div>
          <p> {t('Set_up')}<span> 100 €</span></p>
          {/* <button className="btn button"><span className="pseudo-text">En Profitez</span></button> */}
        </div>
      </div>
    </div>
    <div className="col-md-4">
      <div className="card">
        <ul className={langue === "ar" && "ul-ar"}>
          <div>
            <span className="euroextendedbold">{t("Pack_Complet")}</span><br />
            <span className="number"> {complet} <sup>€</sup></span> {t('Month')}
          </div>
          <li><h3>{t('Starting')}</h3></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Kick-off_meeting')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Blogging.Back_office')} </span></li>
          <hr />
          <li><h3>{t('Strategy')}</h3></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t("Blogging.Shared_working")}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Blogging.Quarterly_strategic')}</span></li>
          <hr />
          <li><h3>{t('Blogging.Blogging_campaign')}</h3> </li>
          <li><span>2 {t('articles')} / {t('Month')} </span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Blogging.Professional_writing')} 700 {t('mots')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Blogging.Writing_of_metals')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Blogging.Photo_with_rights')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Blogging.CMS')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Internal_meshing')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Blogging.Relecture_corrections')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Blogging.Programming_publishing')}</span></li>
          <hr />
          <li><h3>{t('Options')} </h3> </li>
          <li><img src="/images/seo/Groupe de masques 786.svg" alt="X" /> <span>{t('Writing')} 1 000 {t('mots')}</span></li>
          <li><img src="/images/seo/Groupe de masques 786.svg" alt="X" /> <span>{t('Blogging.Second_photo')}</span></li>
          <li><img src="/images/seo/Groupe de masques 786.svg" alt="X" /> <span>{t('Blogging.Additional_article')}</span></li>
          <li><img src="/images/seo/Groupe de masques 786.svg" alt="X" /> <span>{t('Blogging.Multilingual_campaign')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Blogging.strategic_topics')}</span></li>
          <hr />
        </ul>
        <div>
          <p> {t('Set_up')}<span> 100 €</span></p>
          {/* <button className="btn button"><span className="pseudo-text">En Profitez</span></button> */}
        </div>
      </div>
    </div>

    <div className="col-md-4">
      <div className="card">
        <ul className={langue === "ar" && "ul-ar"}>
          <div>
            <span className="euroextendedbold">{t("Pack_Expert")}</span><br />
            <span className="number"> {expert} <sup>€</sup></span> {t('Month')}
          </div>
          <li><h3>{t('Starting')}</h3></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Kick-off_meeting')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Blogging.Back_office')} </span></li>
          <hr />
          <li><h3>{t('Strategy')}</h3></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t("Blogging.Shared_working")}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Blogging.Quarterly_strategic')}</span></li>
          <hr />
          <li><h3>{t('Blogging.Blogging_campaign')}</h3> </li>
          <li><span>4 {t('articles')} / {t('Month')} </span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Blogging.Professional_writing')} 700 {t('mots')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Blogging.Writing_of_metals')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Blogging.Photo_with_rights')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Blogging.CMS')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Internal_meshing')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Blogging.Relecture_corrections')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Blogging.Programming_publishing')}</span></li>
          <hr />

          <li><h3>{t('Options')} </h3> </li>
          <li><img src="/images/seo/Groupe de masques 786.svg" alt="X" /> <span>{t('Writing')} 1 000 {t('mots')}</span></li>
          <li><img src="/images/seo/Groupe de masques 786.svg" alt="X" /> <span>{t('Blogging.Second_photo')}</span></li>
          <li><img src="/images/seo/Groupe de masques 786.svg" alt="X" /> <span>{t('Blogging.Additional_article')}</span></li>
          <li><img src="/images/seo/Groupe de masques 786.svg" alt="X" /> <span>{t('Blogging.Multilingual_campaign')}</span></li>
          <li><img src="/images/seo/7-Check.svg" alt="Check" /> <span>{t('Blogging.strategic_topics')}</span></li>
          <hr />
        </ul>
        <div>
          <p> {t('Set_up')}<span> 100 €</span></p>
        </div>
      </div>
    </div>
  </div>
  );
}

export default withTranslation()(Blogging);

