import React, { useState } from "react";
import { FormControl, Input, InputLabel } from "@material-ui/core";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Popup from "../../Popup";
import "./contact.css";

const ContactForm = (props ) => {
  const initialValues = { yourname: "", youremail: "", yoursubject: "" };
  const { t } = props;
  let handler = props.handler;
  let hasError = props.haserror;
  let isLoading= props.isloading;
  const [formState, setFormState] = useState(initialValues);
  const [existError, setExistError] = useState(false);
  const [buttonPopup, setButtonPopup] = useState(false);
  const letters = /^[A-Za-z0-9._,.:;*$"'#?!^[{}()\]\\\-\+=@é àçèâêîû/ô._\s-]+$/;
  const validEmail = /^[a-zA-Z0-9._:$!%-à]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,3}$/;
  const { setError, formState: { errors } } = useForm();

  const handleFieldChange = (field, e) => {
    setFormState({
      ...formState,
      [field]: e.target.value,
    });
    setError(field, {});
    if (field === "yourname") {
      if (e.target.value.length < 2) {
        setError("yourname", { type: "manual", message: "La longueur doit être de 2 ou plus" });
      } else if (!e.target.value.match(letters)) {
        setError("yourname", { type: "manual", message: "Veuillez saisir uniquement des lettres" });
      }
    }

    if (field === "yoursubject") {
      if (e.target.value.length < 5) {
        setError("yoursubject", { type: "manual", message: "La longueur doit être de 5 ou plus" });
      }
    }

    if (field === "youremail") {
      if (e.target.value.length < 5) {
        setError("youremail", { type: "manual", message: "La longueur doit être de 5 ou plus" });
      } else if (!validEmail.test(e.target.value)) {
        setError("youremail", { type: "manual", message: "L’adresse e-mail n’est pas valide." });
      }
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    let verifError = true;
    if (!formState.yourname || formState.yourname.length < 2) {
      setError("yourname", { type: "manual", message: "La longueur doit être de 2 ou plus" });
      verifError = false;
    }

    if (!formState.yoursubject || formState.yoursubject.length < 5) {
      setError("yoursubject", { type: "manual", message: "La longueur doit être de 5 ou plus" });
      verifError = false;
    }

    if (!formState.youremail || !validEmail.test(formState.youremail)) {
      setError("youremail", { type: "manual", message: "L’adresse e-mail n’est pas valide." });
      verifError = false;
    }

    if (verifError) {
      handler(e, formState);
      e.target.reset();
    }

    setExistError(verifError);
  };

  return (
    <Form>
      <h3 className={t === "ar" && "alignRightMobile"}>Vous avez un projet en tête ?</h3>
      <p className="title-p text-start">Contactez-nous dès aujourd’hui pour une consultation gratuite et découvrez comment nous pouvons vous aider à atteindre vos objectifs !</p>
      <form className="from" onSubmit={handleFormSubmit} method="POST">
        <FormControl className={t === "ar" ? "inputleft inputleft-ar" : "inputleft"}>
          <InputLabel className={t === "ar" && "label-ar"} style={{ color: '#000', marginTop: "-10px" }}>{t('Contact.name')}</InputLabel>
          <Input id="message" className="input" disableUnderline onChange={(e) => handleFieldChange("yourname", e)} />
          {errors.yourname && (<small className="text-danger">{errors.yourname.message}</small>)}
        </FormControl>
        <FormControl className={t === "ar" ? "inputRight inputRight-ar" : "inputRight"}>
          <InputLabel className={t === "ar" && "label-ar"} style={{ color: '#000', marginTop: "-10px" }}>{t('Contact.Email')}</InputLabel>
          <Input id="message" className="input" disableUnderline onChange={(e) => handleFieldChange("youremail", e)} autoComplete="off" />
          {errors.youremail && (<small className="text-danger">{errors.youremail.message}</small>)}
        </FormControl>
        <div className="Project">
          <FormControl fullWidth className={t === "ar" ? "fullWidth fullWidth-ar" : "fullWidth"}>
            <InputLabel className={t === "ar" && "label-ar"} style={{ color: '#000', marginTop: "-10px" }}>{t('Contact.project')}</InputLabel>
            <Input id="message" className="input" disableUnderline onChange={(e) => handleFieldChange("yoursubject", e)} autoComplete="off" />
            {errors.yoursubject && (<small className="text-danger">{errors.yoursubject.message}</small>)}
          </FormControl>
        </div>
        <button type="submit" className={t === "ar" ? "btn btn-primary button button-ar submit" : "btn btn-primary button submit"} onClick={() => setButtonPopup(true)}><span className="pseudo-textf">{t('Contact.Send')}</span></button>
        {existError && !isLoading &&(
              <Popup trigger={buttonPopup} setTrigger={setButtonPopup} onBlur={() => setButtonPopup(false)}>
                <p>{hasError ? "Réessayez plus tard" : t('Contact.Thank')}</p>
              </Popup>
            )}
      </form>
    </Form>
  );
};

const Form = styled.div`
  margin-top: 65px;

  @media (max-width: 991px){
    padding:0px 0%;
    margin-top: 58px;
  }
  @media (max-width: 575px){
    padding: 0px 20px ;
    margin-top: 37px;
  }

  h3{
  font-size: 32px !important;
    line-height: 50px !important;
     @media (max-width: 991px){
      font-size: 24px !important;
     }
     @media (max-width: 575px) {
      font-size: 20px !important;
      margin-bottom: 0;
     }
  }
  >p {
    text-align: center;
    @media (max-width: 991px) {
      text-align: left;
    }
  }

  >p >span.pseudo-text{
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline;
  }
   .pseudo-textf {
    display: inline;
    position: relative;
    font-weight: 500;
    transition: .25s ease-in;
    transition-delay: .1s;
}
  .inputleft{
    width: 47%;
    margin-right: 3%;
    @media (max-width:676px) {
      width: 100%;
      margin-right: 0;
    }
  }

  .inputleft-ar{
    margin-right: 0;
    margin-left: 0%;
  }

  .inputRight{
    width: 50%;
    margin-left: 4%;
    @media (max-width:676px) {
      width: 100%;
      margin-left: 0;
    }
  }
  .inputRight{
    margin-right: 0%;
    margin-left: 0;
  }
    .fullWidth{
    margin: 0 2% 0 0;
  }
  .fullWidth-ar{
    margin: 0 0 0 2%;
  }
  .hide{
    display: none;
  }

  >.from{
    line-height: 100px;
    margin-top: 40px;
    @media (max-width: 575px){
      margin-top: 20px;
      line-height: 90px;
     }
  }
  .input{
    border: 0;
    border-bottom:1px solid #17406F;
    background-color: transparent;
    outline: none;
    color: #17406F;
  }
    label{
    font-family: InterRegular !important;
    font-size: 18px !important;
    font-weight: 500;
    @media (max-width: 991px){
      font-size: 16px !important;
     }
      @media (max-width: 575px){
      font-size: 14px !important;
     }
    }

  input:-webkit-autofill,
  .my-class:-webkit-autofill {
    transition-delay: 9999999s;
  }

  .submit{
    display: block;
    margin-left: auto;
    background-color: #17406F;
    color: #fff;
    border-radius: 15px;
    margin-bottom: 20px;
    padding: 10px 62px !important;
    font-size: 16px;
    font-weight: 500;
    @media (max-width: 575px){
      font-size: 14px !important;
    }
    
  }

  .text-danger{
    position: absolute;
    height: 0px;
    top:15px;
    font-size: 16px;
    font-weight: 600;
    color: #c51414b3 !important;
  }

  >form span.merci{
    display: flex;
    place-content: center;
    color: #036203;
    z-index:9999;
  }
`;

export default withTranslation()(ContactForm);
