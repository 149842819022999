import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Title } from "../../../css/commun";
import { Link } from 'react-router-dom';
import { Bowling } from '../Home';
import { URL_Back, URL_Dev } from "../Variables";
import Pages from "../../../Model/Pages";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import cookies from "js-cookie";

const SolutionBowling = (props) => {
  const title = props.location.pathname
  const splitState = title.split('/')
  const { t } = props
  const [pages, setPages] = useState([]);
  const [solution, setSolution] = useState("Web");
  const [url, setUrl] = useState("notre-solution-bowling");
  const [langue, setLangue] = useState("fr");
  const currentLanguage = cookies.get('i18next') || 'fr'

  useEffect(() => {
    setLangue(currentLanguage)
    document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  }, [currentLanguage])

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);

    const lng = cookies.get('i18next') || 'fr'
    lng === "en" 
      ? setUrl("our-bowling-solution") 
      : lng === "ar" && setUrl("حل-البولينج-لدينا") 

    fetch(`${URL_Back}/wp-json/wp/v2/Pages?per_page=100`)
      .then(res => res.json())
      .then(
        (result) => {
          setPages(result)
        },
      )
  }, [])

  let page = []
  pages.map(element =>
    decodeURI(element.slug) === (splitState[1].length === 2 ?  decodeURI(splitState[2]) : decodeURI(splitState[1])) 
    && page.push(new Pages(element.id, element.title.rendered, element.content.rendered, element.parent, element.slug, element.yoast_head_json.og_description, element.date, element.excerpt.rendered)))
  let titre = page[0] !== undefined ? page[0].title.replace("&#8217;", "'") : "...Loading"

  const Solution = [
    {
      name: 'Web',
      tabs: [{
        title: `${t('SolutionBowling.Birthday')}`, image: "/images/solutionBowling/Groupe 20283.svg", alt: "Groupe 20283.svg",
        tab: [`${t('SolutionBowling.Online_booking')}`, `${t('SolutionBowling.Options_Management')}`, `${t('SolutionBowling.Online_payment')}`]
      },
      {
        title: `${t('Bowling')}`, image: "/images/solutionBowling/bowling (4).svg", alt: "bowling (4)",
        tab: [`${t('SolutionBowling.Online_booking')}`, `${t('SolutionBowling.Price_adjustment')}`, `${t('SolutionBowling.Online_payment')}`]
      },]
    },
    {
      name: 'Mobile',
      tabs: [{
        title: `${t('SolutionBowling.Birthday')}`, image: "/images/solutionBowling/Groupe 20283.svg", alt: "Groupe 20283.svg",
        tab: [`${t('SolutionBowling.Online_booking')}`, `${t('SolutionBowling.Options_Management')}`, `${t('SolutionBowling.Online_payment')}`]
      },
      {
        title: `${t('Bowling')}`, image: "/images/solutionBowling/bowling (4).svg", alt: "bowling (4)",
        tab: [`${t('SolutionBowling.Online_booking')}`, `${t('SolutionBowling.Price_adjustment')}`, `${t('SolutionBowling.Online_payment')}`]
      },
      {
        title: `${t('Payments')}`, image: "/images/solutionBowling/Groupe 38350.svg", alt: "Groupe 38350",
        tab: ["here are many variations", "Simply dummy text", "There are many variations"]
      },]
    },
    {
      name: 'Kiosk',
      tabs: [{
        title: `${t('SolutionBowling.Birthday')}`, image: "/images/solutionBowling/Groupe 20283.svg", alt: "Groupe 20283.svg",
        tab: [`${t('SolutionBowling.Online_booking')}`, `${t('SolutionBowling.Options_Management')}`, `${t('SolutionBowling.Payment')}`]
      },
      {
        title: `${t('Bowling')}`, image: "/images/solutionBowling/bowling (4).svg", alt: "bowling (4)",
        tab: [`${t('SolutionBowling.spot')}`, `${t('SolutionBowling.Selling')}`, `${t('SolutionBowling.Payment')}`]
      },
      {
        title: `${t('Bar')}`, image: "/images/solutionBowling/snack.svg", alt: "snack",
        tab: [`${t('SolutionBowling.control')}`, `${t('SolutionBowling.Up')}`, `${t('SolutionBowling.Payment')}`]
      },]
    },
  ]

  return (<>
    <Helmet>
      <title>{titre}</title>
      <meta name="description" content={page[0] !== undefined && page[0].description} />
      <link rel="canonical" href={page[0] !== undefined && `${URL_Dev}/${page[0].slug}`} />
    </Helmet>
    <div className="container-fluid" >
      <Title>
        <h1 className="prestation">
          {titre}
        </h1>
        <p>
          <img className={langue === "ar" && "img-accueil-ar"} src="/images/accueil.png" alt="accueil" />
          <Link className="retour" to={`/${langue}`}>{t('Home_link')}</Link> / <span>{titre}</span>
        </p>
      </Title>
      <Table>
        <div className="listButton">
          <button type="button" className={solution === "Web" ? "btn btn-light active" : "btn btn-light"} onClick={() => setSolution("Web")}>{t('Web')}</button>
          <button type="button" className={solution === "Mobile" ? "btn btn-light active" : "btn btn-light"} onClick={() => setSolution("Mobile")}>{t('Mobile')}</button>
          <button type="button" className={solution === "Borne" ? "btn btn-light active" : "btn btn-light"} onClick={() => setSolution("Kiosk")}>{t('Kiosks')}</button>
        </div>
        <p>{t('SolutionBowling.Our_solution')}</p>
        <div className="row">
          {(Solution).map((item, key) => (item.name === solution) && (
            <div key={key}>
              {(item.tabs).map((i, key) => (
                <div className={solution === "Borne" ? "card borne" : "card"} key={key}>
                  <img src={i.image} alt={i.alt} />
                  <span>{i.title}</span>
                  <div className="card-body">
                    <ul>
                      {(i.tab).map((y, key) => (
                        <li key={key}>{y}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </Table>
      <Demande>
        <h2 className={langue === "ar" && "alignRightMobile"}>
          {t('SolutionBowling.appointment')}
        </h2>
        <p className={langue === "ar" && "alignRightMobile"}>{t('SolutionBowling.propose')}</p>
        <a className="link" href="https://outlook.office365.com/owa/calendar/OXTONDIGITAL@oxton-digital.com/bookings/s/r2PU4vNFNEeV21nViyrqbA2" target="_blank">
          <button className={langue === "ar" ? "btn btn-light button button-ar demande" : "btn btn-light button demande"}><span className="pseudo-text">{t('SolutionBowling.Make_appointment')}</span></button>
        </a>
      </Demande>
      <Avantage>
        <h2 className={langue === "ar" && "alignRightMobile"}>{t('SolutionBowling.advantages')}</h2>
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <span>01</span>
              <h3>{t('SolutionBowling.attention')}</h3>
              <div className="card-body">
                <p>
                  {t('SolutionBowling.interactive')}<br />
                  {t('SolutionBowling.Increase')}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <span>02</span>
              <h3>{t('SolutionBowling.Experience')}</h3>
              <div className="card-body">
                <p>
                  {t('SolutionBowling.customer')}<br />
                  {t('SolutionBowling.incessant')}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <span>03</span>
              <h3>{t('SolutionBowling.Increase_sales')}</h3>
              <div className="card-body">
                <p>
                {t('SolutionBowling.easily')}<br/>
                {t('SolutionBowling.efficient')}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <span>04</span>
              <h3>{t('SolutionBowling.promotion')}</h3>
              <div className="card-body">
                <p>{t('SolutionBowling.advertise')}</p>
              </div>
            </div>
          </div>
        </div>
      </Avantage>

      <Bowling>
        <div className="row gy-4">
          <div className="col-md-3">
            <Link className="link" to={`/${langue}/${url}/space-bowling`}>
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img src="/images/home/cards/Group 38813.jpg" alt="Group 38813" />
                </div>
                <div className="flip-card-back">
                  <div className="content">
                    <img src="/images/home/cards/Mask Group 665.png" alt="Mask Group 665" />
                    <h4>{t('Space_Bowling')}</h4>
                    <p>{t('SolutionBowling.discover')}</p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-3">
            <Link className="link" to={`/${langue}/${url}/extra-laser-bowling`}>
              <div className="flip-card-inner" >
                <div className="flip-card-front">
                  <img src="/images/home/cards/Group 38836.jpg" alt="Groupe 38836" />
                </div>
                <div className="flip-card-back">
                  <div className="content">
                    <img src="/images/home/cards/Mask Group 662.png" alt="Mask Group 662" />
                    <h4>{t('SolutionBowling.Extra_Laserbowl')}</h4>
                    <p>{t('SolutionBowling.located')}</p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-3">
            <Link className="link" to={`/${langue}/${url}/planet-bowling`}>
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img src="/images/home/cards/Group 38815.jpg" alt="Groupe 38815" />
                </div>
                <div className="flip-card-back">
                  <div className="content">
                    <img src="/images/home/cards/Mask Group 661.png" alt="Mask Group 661" />
                    <h4>{t('Planet_bowling')}</h4>
                    <p>{t('SolutionBowling.largest')}</p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-3">
            <Link className="link" to={`/${langue}/${url}/bowling-de-limoges`}>
              <div className="flip-card-inner" >
                <div className="flip-card-front">
                  <img src="/images/home/cards/Group 38814.jpg" alt="Group 38814" />
                </div>
                <div className="flip-card-back">
                  <div className="content">
                    <img src="/images/home/cards/Mask Group 664.png" alt="Mask Group 664" />
                    <h4>{t('Bowling_de_Limoges')}</h4>
                    <p>{t('SolutionBowling.Limoges')}</p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </Bowling>
    </div>
  </>)
}

const Table = styled.div`
  padding:60px 14% 60px 14%;
  @media (max-width:991px) {
    padding:60px 10% 60px 10%;
  }
  background-image: linear-gradient(to bottom right, #367ba7, #94cce5);
  >.listButton {
    text-align:center;
    z-index:10;
    button{
      font-size: 18px;
      font-family: PoppinsRegular;
      margin:0px 20px 20px 20px;
      color:white;
      background-color:#ffffff29;
      border: 2px solid white;
    &:hover{
      color: #17406F;
      background-color:white;
      box-shadow: 0 0 0 0.25rem rgb(211 212 213 / 50%);
    }
    &:focus{
      color: #17406F;
      background-color: white;
    }
    }
    .active{
      border:2px solid white;
      background-color: white;
      color: #17406F;
      box-shadow: 0 0 0 0.25rem rgb(211 212 213 / 50%);
    }
  }
  >p{
    text-align:center;
    color:white;
    padding:20px 0 40px 0;
    margin-bottom:0;
  }
  >.row div {
    display:flex;
    justify-content: center;
    flex-wrap:wrap;
    .borne{
      @media (min-width: 1375px) and (max-width:1833px) {
      flex:0 32% !important;
      }
    }
    .card {
    width:300px;
    border-radius:15px;
    padding:30px 30px 0px 30px;
    margin: 0px 15px 30px 15px;
    >span{
      font-size: 22px;
      padding:20px 0;
      font-family: EurostileExtendedBold;
    }
    .card-body{
      padding:0;
      margin-bottom:0;
      >ul {
        padding:0;
        list-style-type: none;
        li:before{
        content:'- ';
      }
      >li{
        line-height: 25px;
      }
    }}
    img{
    width:50px;
    }
  }}
`;

const Demande = styled.div`
  padding:55px 15% 60px 15%;
  background-color: #ecf6fb;
  text-align: center;
  >p{
      margin-top: 16px;
      margin-bottom: 0;
    }
  >.link .demande{
    margin-top:20px;
    padding:10px 50px !important;
    background-color: #20ACE3;
    color:white;
    border-radius: 15px;
    font-weight: 600;
    font-size: 16px;
  }
  @media (max-width: 991px) {
    padding:30px 10% 40px 10% ;
    >p{
      text-align: left;
    }
  }

`;

const Avantage = styled.div`
padding:70px 15% 20px 15%;
@media (max-width: 991px) {
      padding:50px 10% 0px 10% ;
    }
  >h2{
    text-align: center;
    @media (max-width: 991px) {
      text-align: left;
    }
  }
  >.row{
  margin-top: 30px;
  display: flex;
  font-size:16px;
  list-style-type: none;
  padding: 0;
  font-weight: bold;
  >div{
    @media (max-width: 983px){
          width: 100%;
      }
    >.card{
      padding:30px 0 0;
      border: 0;
      border-radius: 10px;
      @media (max-width:991px) {
        padding:0px 0px 0 0px;
      }
      >span{
        color:#20ACE3;
        font-family: EurostileExtendedBold;
        font-size:32px;
        padding-bottom: 10px;
      }
      >h3{
        font-size: 18px !important;
        font-weight: bold;
        margin-bottom: 0;
      }
      >.card-body{
          padding:0px 0 30px 0;
          font-weight: normal;
          line-height: 28px;
        }
      }
    }
  }
`;

export default withTranslation()(SolutionBowling)
