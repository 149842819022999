import styled from "styled-components";

export const Container = styled.div`
  font-family: 'InterRegular';
  color: rgb(23,64,111);
  `;
export const Breadcrumb = styled.div`
@media(max-width: 575px){
     padding: 0 20px;
}
 >p{
    font-size: 14px;
    font-family: InterRegular;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 0;
    margin-top: 15px;
    padding-left: 2px;
    @media(max-width: 991px){
     
    }
    >.retour{
      color: #000;
      text-decoration: none;
      &:hover{
        text-decoration: underline !important;
      }
    }
    >span{
      color:#000;
    }
    >img{
          width: 28px;
    padding: 0px 10px 0px 2px;
    margin-bottom: 5px;
    }
    .img-accueil-ar{
      padding: 7px 2px 0 10px;
    }
`

export const Title = styled.div`
  padding:0px 0px 0px 0px;
  margin-top: 60px ;
  
  @media (max-width: 991px){
  padding: 0px 0% 20px !important;
  margin-top: 30px;

  }
  @media (max-width: 577px){
    padding: 0px 20px 20px !important;

  }
  >h1{
    position: relative;
    font-family:'RalewayRegular' !important ;
   color: #20ACE3;
  font-size: 42px !important;
  font-family: RalewayRegular !important;
  line-height: 45px;
  font-weight: 600;
  margin-bottom: 20px !important;
    @media (max-width: 991px){
      font-size: 32px !important;
    }
      @media (max-width: 576px){
      font-size: 24px !important;
      line-height: 36px !important;
       margin-bottom: 8px !important;
    }
  }
 
  }

  >.prestation{
    @media (max-width: 583px) and (min-width: 425px){
      padding-top: 25px;
      @media (max-width:553px) {
        font-size: 34px !important;
      }
      margin-left: -30px;
    }
  }
`;

export const WhatIs = styled.div`
  padding:30px 15% 50px 15% ;
  line-height: 28px;
  text-align: center;
  @media (max-width: 991px){
    padding: 60px 10% 10px !important;
    text-align: left;
  }
  @media (max-width: 500px){
    padding: 60px 10% 0px 10% !important;
  }
  >h2{
    text-align: center;
    padding-bottom: 10px;
    @media (min-width:991px) {
      padding: 30px 0 10px 0;
    }
    @media (max-width:991px) {
      text-align: left;
    }
  }
  >p{
    padding-bottom: 0px;
    @media (max-width: 500px){
      margin-bottom: 0;
    }
  }
  >.link .joindre{
    text-align: center;
    border-radius: 15px;
    color: white !important;
    background-color: #20ACE3;
    font-family:'InterRegular';
    @media (max-width: 991px) {
      margin-left: 0;
    }
    @media (max-width:500px) {
      margin-top: 27px;
    }
  }
  >.joindre{
    text-align: center;
    border-radius: 15px;
    color: white !important;
    background-color: #20ACE3;
    font-family:'InterRegular';
  }
`;


