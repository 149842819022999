import React from "react";
import Cf7FormWrapper from "../../Forms/Cf7FormWrapper";
import SubSeo from "./SubSeo";

const Seo = (props) => {
  return (
    <Cf7FormWrapper id="537">
      <SubSeo subprops={props} />
    </Cf7FormWrapper>
  )
}

export default Seo;